import React, { useState, useEffect, useRef } from 'react';
import { Bar } from '@visx/shape';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { LegendOrdinal, LegendItem, LegendLabel } from '@visx/legend';
import { timeFormat } from 'd3-time-format';

const WeeklyDistributionChart = ({ inboundData, outboundData }) => {

    const [containerWidth, setContainerWidth] = useState(750);
    const containerRef = useRef(null);

    useEffect(() => {
        const updateWidth = () => {
            if (containerRef.current) {
                setContainerWidth(containerRef.current.offsetWidth);
            }
        };
        updateWidth();
        window.addEventListener('resize', updateWidth);
        return () => window.removeEventListener('resize', updateWidth);
    }, []);

    const data = inboundData || outboundData;

    if (!data || Object.keys(data).length === 0) {
        return <div>No data available</div>;
    }

    // const dateFormat = timeFormat('%b %d, %Y');
    const dateFormat = (date) => {
        const day = timeFormat('%a')(date);
        const datePart = timeFormat('%b %d')(date);
        return `${day}, ${datePart}`;
    };

    const dayEmailCounts = Object.entries(data).map(([date, fields]) => {
        return {
            date,
            ...fields,
        };
    }).sort((a, b) => new Date(a.date) - new Date(b.date));

    const inboundEmailTypes = ['archived', 'read', 'unread', 'trashed'];
    const outboundEmailTypes = ['reply', 'new', 'draft', 'sent'];
    const allEmailTypes = inboundData ? inboundEmailTypes : outboundEmailTypes;

    const emailTypes = new Set();
    dayEmailCounts.forEach(d => Object.keys(d).forEach(type => {
        if (allEmailTypes.includes(type)) {
            emailTypes.add(type);
        }
    }));
    emailTypes.delete('date');

    const emailTypesArray = Array.from(emailTypes).filter(type => type !== 'null');

    const height = 250;
    const margin = { top: 30, right: 40, bottom: 40, left: 50 };

    const fixedEmailTypeCount = 4;

    const xMax = containerWidth - margin.left - margin.right;
    const yMax = height - margin.top - margin.bottom;

    const xScale0 = scaleBand({
        domain: dayEmailCounts.map(d => dateFormat(new Date(d.date))),
        range: [0, xMax],
        padding: 0.2,
    });

    const xScale1 = scaleBand({
        domain: Array.from({ length: fixedEmailTypeCount }, (_, i) => i),
        range: [0, xScale0.bandwidth()],
        padding: 0.1,
    });

    const yScale = scaleLinear({
        domain: [0, Math.max(...dayEmailCounts.flatMap(d => emailTypesArray.map(type => d[type] || 0)))],
        range: [yMax, 0],
        nice: true,
    });

    const colorScale = scaleOrdinal({
    domain: allEmailTypes,
    range: inboundData 
        ? ['#F8B195', '#F67280', '#C06C84', '#6C567B'] // Color for Received Emails
        : ['#B983FF', '#94B3FD', '#94DAFF', '#99FEFF'] // Color for Sent Emails
});


    const displayedEmailTypes = allEmailTypes.filter(type => emailTypes.has(type));

    const formatLabel = (label) => {
        return label.replace('_', ' ');
    };

    return (
        <div ref={containerRef} style={{ position: 'relative', width: '100%' }}> {/* Added container ref */}
            <svg width="100%" height={height} viewBox={`0 0 ${containerWidth} ${height}`}> {/* Updated SVG */}
                <Group top={margin.top} left={margin.left}>
                    {dayEmailCounts.map((d, dayIndex) => {
                        const activeEmailTypes = emailTypesArray.filter(type => d[type]);
                        const totalEmailTypes = activeEmailTypes.length;
                        const offset = (fixedEmailTypeCount - totalEmailTypes) * xScale1.bandwidth() / 2;
                        return (
                            <Group key={`bar-group-${d.date}`} left={xScale0(dateFormat(new Date(d.date))) + offset}>
                                {activeEmailTypes.map((key, index) => (
                                    <Bar
                                        key={`bar-${key}-${d.date}`}
                                        x={xScale1(index)}
                                        y={yScale(d[key] || 0)}
                                        width={xScale1.bandwidth()}
                                        height={yMax - yScale(d[key] || 0)}
                                        fill={colorScale(key)}
                                    />
                                ))}
                            </Group>
                        );
                    })}
                    <AxisBottom
                        top={yMax}
                        scale={xScale0}
                        tickFormat={d => d}
                        tickLabelProps={() => ({
                            angle: 0,
                            textAnchor: 'middle',
                            fontSize: 11,
                            dy: '0.25em',
                        })}
                    />
                    <AxisLeft 
                        scale={yScale} 
                        tickFormat={d => Number.isInteger(d) ? d : ''} 
                        tickValues={yScale.ticks().filter(Number.isInteger)}
                    />
                </Group>
            </svg>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '-15px'}}>
                <LegendOrdinal scale={colorScale}>
                    {labels => labels.filter(label => displayedEmailTypes.includes(label.datum)).map((label, i) => (
                        <LegendItem key={`legend-${i}`} margin="15px 15px" direction="row">
                            <svg width={10} height={10}>
                                <rect fill={label.value} width={10} height={10} />
                            </svg>
                            <LegendLabel
                                style={{
                                        color: '#6B7280',
                                        align:'left',
                                        margin:'0 0 1px 4px',
                                        fontSize: '0.875rem'
                                    }}
                            >
                                {formatLabel(label.text)}
                            </LegendLabel>
                        </LegendItem>
                    ))}
                </LegendOrdinal>
            </div>
        </div>
    );
};

export default WeeklyDistributionChart;
