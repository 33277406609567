import React, { useEffect, useState, useContext } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { doc, updateDoc } from "firebase/firestore";
import { auth, db } from '../../config/firebaseConfig.js';
import { useNavigate } from 'react-router-dom';
import logo_v2 from '../../assets/logo-v2.png';
import report_img from '../../assets/report-1.png';

import { ConnectEmailView } from '../Email/ConnectEmailView.js';

import { collection, query, where, getDocs,getDoc } from "firebase/firestore";
import { getAnalytics, logEvent } from '@firebase/analytics';
import { useLocation } from 'react-router-dom';

import { UserContext } from '../../UserContext.js';
import { EmailAnalyticsView } from '../Email/EmailAnalyticsView.js';

function Waitlist() {
  const analytics = getAnalytics();
  const location = useLocation();

  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true)
  const [customerEmail, setCustomerEmail] = useState('');
  const [userObject, setUserObject] = useState(false);
  const { userId, setUserId } = useContext(UserContext);

  const [isEmailAnalyticsLoaded, setIsEmailAnalyticsLoaded] = useState(false);
  const [isConnectEmailOpen, setIsConnectEmailOpen] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    logEvent(analytics, 'waitlist_view', {
        firebase_screen: location.pathname,
        firebase_screen_class: 'firebase-routes-analytics', 
    });
  }, [location]);

  useEffect(() => {
    const getData = async (retryCount = 0) => {
      setIsLoading(true);
      if (user) {
        try {
          const q = query(collection(db, "users"), where("uid", "==", user?.uid));
          const querySnapshot = await getDocs(q);
          let fetchedUserObject = null;
          querySnapshot.forEach((doc) => {
            const userData = doc.data();
            setUserId(doc.id);
            if (userData.subscription_active) {
              navigate("/email");
              return;
            }
            setCustomerEmail(userData.email);
            fetchedUserObject = userData;
          });
  
          if (fetchedUserObject && fetchedUserObject.waitlist_position !== undefined) {
            setUserObject(fetchedUserObject);
            setIsLoading(false);
          } else {
            if (retryCount < 5) {
              setTimeout(() => getData(retryCount + 1), 5000);
            } else {
              console.warn("Failed to fetch waitlist_position after 5 attempts");
              setIsLoading(false);
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    }
    getData();
  }, [user]);

  const handleEmailConnectionAction = (email_type) => {
    initiateConnection(email_type)
  }

  const initiateConnection = (connectionType) => {

    logEvent(analytics, `Email View: Connect ${connectionType} Clicked`);

    if (user && userId) {
        setUserId(userId);
        localStorage.setItem('userId', userId);
        localStorage.setItem('authType', connectionType);
        localStorage.setItem('fromPath', location.pathname);
        initiateAuth(connectionType);
    } else {
        console.error('User is not authenticated');
    }
  };

  const updateWaitlistPosition = async (condition) => {
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      const userData = userDoc.data();
      let newWaitlistPosition = userData.waitlist_position;
  
      if (condition === 'analytics' && !localStorage.getItem('analyticsSubmitted')) {
        newWaitlistPosition = Math.ceil(userData.waitlist_position / 2);
        await updateDoc(userDocRef, { waitlist_position: newWaitlistPosition });
        setUserObject(prevState => ({ ...prevState, waitlist_position: newWaitlistPosition }));
        localStorage.setItem('analyticsSubmitted', 'true');
      } else if (condition === 'form' && !localStorage.getItem('formSubmitted')) {
        newWaitlistPosition = Math.ceil(userData.waitlist_position / 2);
        await updateDoc(userDocRef, { waitlist_position: newWaitlistPosition });
        setUserObject(prevState => ({ ...prevState, waitlist_position: newWaitlistPosition }));
        localStorage.setItem('formSubmitted', 'true');
      }
    }
  };

  useEffect(() => {
    if(!userId){return}
    const params = new URLSearchParams(location.search);
    const isFormSubmitted = localStorage.getItem('formSubmitted');
    
    if (params.get('formSubmitted') === 'true' && (!isFormSubmitted || isFormSubmitted === 'false')) {
      if (userId) {
        updateWaitlistPosition('form');
      }
      setFormSubmitted(true);
    }
  }, [location, userId]);

  useEffect(() => {
    const isFormSubmitted = localStorage.getItem('formSubmitted');
    if (isFormSubmitted === 'true') {
      setFormSubmitted(true);
    }
  }, []);
  

  const initiateAuth = async (type) => {
    try {
        // Construct the URL with the type parameter
        const requestUrl = `${process.env.REACT_APP_BACKEND_URL}/initiate_auth?type=${encodeURIComponent(type)}`;

        const authResponse = await fetch(requestUrl);
        const authData = await authResponse.json();
        const authUrl = authData.authUrl;

        // Redirect to the authUrl
        window.location.href = authUrl;
    } catch (error) {
        console.error(`Error initiating ${type} authentication:`, error);
    }
  };

  const openTallyForm = () => {
    const email = userObject.email;
    const tallyFormHTML = `
      <html>
        <head>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0">
          <title>Climb Up the Waitlist</title>
          <script async src="https://tally.so/widgets/embed.js"></script>
          <style type="text/css">
            html { margin: 0; height: 100%; overflow: hidden; }
            iframe { position: absolute; top: 0; right: 0; bottom: 0; left: 0; border: 0; }
          </style>
        </head>
        <body>
          <iframe data-tally-src="https://tally.so/r/w2kMqM?email=${encodeURIComponent(email)}" width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0" title="Climb Up the Waitlist"></iframe>
        </body>
      </html>
    `;
  
    const newWindow = window.open("", "_blank");
    newWindow.document.write(tallyFormHTML);
    newWindow.document.close();
  };

  return (
    <>
      <div className="pt-12 md:pt-20">
        <div className="welcome-container">
          <img src={logo_v2} alt="Logo" className="welcome_logo" />
        </div>
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          <div className="relative max-w-3xl mx-auto text-center pb-12">
            <h2 className="font-inter-tight text-3xl md:text-4xl font-bold text-zinc-900 mt-10">You've Secured a Spot! 👌</h2>
            {isLoading ? (
              <div className="flex items-center justify-center">
                <button
                  className="inline-flex px-2 py-2 text-xs font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 mt-6"
                  disabled={true}
                >
                  <svg className="w-4 h-4 me-2 animate-spin" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.7 7.7A7.1 7.1 0 0 0 5 10.8M18 4v4h-4m-7.7 8.3A7.1 7.1 0 0 0 19 13.2M6 20v-4h4" />
                  </svg>
                  Loading your position
                </button>
              </div>
            ) : (
              <>
                <p className="text-lg text-zinc-500 mt-8">Your current position in the waitlist is</p>
                {userObject && userObject.waitlist_position && (
                  <p className="text-6xl font-bold text-zinc-900 mt-2">#{userObject.waitlist_position}</p>
                )}
                <div className="w-full lg:w-6/7 mx-auto mt-4">
                  {userObject.tokens && (
                    <>
                      <EmailAnalyticsView
                        userData={{
                          userId: userId,
                          accessToken: user?.accessToken,
                          userObject: userObject,
                        }}
                        choosePlanOrigin={true}
                        onLoaded={() => {
                          setIsEmailAnalyticsLoaded(true);
                          updateWaitlistPosition('analytics');
                        }}
                      />
                      {isEmailAnalyticsLoaded && !formSubmitted && (
                        <div className="mt-10">
                          <div className="relative max-w-3xl mx-auto text-center pb-12">
                            <div className="text-2xl font-bold text-center my-4">
                              Wanna Reach The Top? 🗻
                            </div>
                            <p className="text-lg text-zinc-500">
                              Participate in our survey and share your valuable inputs to climb more steps in the waitlist to position
                              {userObject && userObject.waitlist_position && (
                                <p className="text-4xl font-bold text-zinc-900">#{Math.ceil(userObject.waitlist_position / 2)}</p>
                              )}
                            </p>
                            <div className="flex justify-center mt-4">
                              <button
                                onClick={openTallyForm}
                                className="connect-btn connect-btn-gradient-border connect-btn-glow"
                              >
                                Complete the Survey
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                      {formSubmitted && (
                        <div className="mt-10">
                          <div className="relative max-w-3xl mx-auto text-center pb-12">
                            <div className="text-2xl font-bold text-center my-4">
                              Thank You for Your Input! 🙌
                            </div>
                            <p className="text-lg text-zinc-500">
                              We appreciate your interest and will contact you soon. Stay tuned!
                            </p>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  {!userObject.tokens && (
                    <>
                      <div className="text-2xl font-bold text-center mt-10">
                        Wanna Climb Up The Waitlist? 🧗
                      </div>
                      <div className="text-center px-10 mt-2">
                        Click below, connect your email, and get a free SkimAI⚡️ Inbox Insights report. You will climb to position
                        {userObject && userObject.waitlist_position && (
                          <p className="text-4xl font-bold text-zinc-900 mt-2">#{Math.ceil(userObject.waitlist_position / 2)}</p>
                        )}
                      </div>
                      <img className="mt-4" src={report_img} />
                      <div className="flex justify-center mt-4">
                        <button
                          onClick={() => {
                            setIsConnectEmailOpen(true);
                          }}
                          className="connect-btn connect-btn-gradient-border connect-btn-glow"
                        >
                          Get Free Inbox Insights
                        </button>
                      </div>
                    </>
                  )}
                  {isConnectEmailOpen && (
                    <ConnectEmailView
                      isOpen={isConnectEmailOpen}
                      choosePlanOrigin={true}
                      onEmailConnectionAction={handleEmailConnectionAction}
                      onClose={() => setIsConnectEmailOpen(false)}
                      userData={{
                        userId: userId,
                        accessToken: user?.accessToken,
                      }}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
  
}

export default Waitlist;