import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, signInWithGoogle, db, sendPasswordReset, registerWithEmailAndPassword, logInWithEmailAndPassword } from '../../config/firebaseConfig';
import { Input } from 'baseui/input';
import { Button, SIZE } from 'baseui/button';
import { collection, query, where, getDocs } from "firebase/firestore";
import { UserContext } from '../../UserContext';
import logo from '../../assets/logo-blue.png';

import GoogleButton from './GoogleButton.tsx';
import heroImage from '../../assets/hero-5.png';
import hero_6 from '../../assets/hero-6.png';
import { getAnalytics, logEvent } from '@firebase/analytics';

import './Auth.css';

function Auth() {
  const [isRegistering, setIsRegistering] = useState(true);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const { setUserId } = useContext(UserContext);
  const [isChecked, setIsChecked] = useState(true);
  const [userData, setUserData] = useState(null);

  // Function to handle checkbox change
  const handleCheckboxChange = () => {
    // Toggle the checkbox state
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    console.log("Auth State Changed:", { user, loading, error });
    if (loading || error) {
      console.log("Loading or Error:", { loading, error });
      return;
    }
    if (user) {
      console.log("User logged in, resetting userData");
      setUserData(null); // Reset userData when user changes
    }
  }, [user, loading, error]);

  const fetchUserData = async (retryCount = 0) => {
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    try {
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        console.log("User data found in Firestore");
        setUserData(querySnapshot.docs[0].data());
      } else if (retryCount < 3) { // Retry up to 3 times
        console.log("Retrying to fetch user data...");
        setTimeout(() => fetchUserData(retryCount + 1), 2000); // Wait 2 seconds before retrying
      } else {
        console.log("User data not found in Firestore after retries");
        setUserData(null);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    if (!user) {
      setUserData(null);
      return;
    }
    console.log("Fetching user data from Firestore");
    fetchUserData(); // Call the function with retry mechanism
  }, [user]);

  useEffect(() => {
    console.log("Checking userData for navigation:", userData);
    if (userData) {
      navigate("email");
      // if(userData.subscription_active == true){
      //   // console.log("active! send user the email ")
      //   navigate("email")
      // } else {
      //   // console.log("not active, send to choose plan")
      //   navigate("chooseplan")
      //   // navigate("waitlist")
      // }
    }
  }, [userData, navigate]);

  const register = async () => {
    if (!name) {
      alert('Please enter your name');
      return;
    }
    await registerWithEmailAndPassword(name, email.toLowerCase(), password);
  };

  const login = async () => {
    await logInWithEmailAndPassword(email.toLowerCase(), password);
  };

  const resetPassword = () => {
    sendPasswordReset(email);
    alert('Password reset email sent!');
    setIsResettingPassword(false);
  };

  const toggleResetPassword = () => {
    setIsResettingPassword(!isResettingPassword);
  };

  const toggleIsRegistering = () => {
    setIsRegistering(!isRegistering);
  };

  if (isResettingPassword) {
    return (
      <div className="auth">
        <div className="auth__container">

        <div className="welcome-container">
          {/* <span className="welcome-text">Welcome to</span> */}
          <img src={logo} alt="Logo" className="welcome_logo"/>
        </div>

          <Input
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="E-mail Address"
          />

            <br></br>

          <Button onClick={resetPassword}
            size={SIZE.large}
            overrides={{
                BaseButton: {
                style:({
                    marginBottom: '14px',
                }),
                },
            }}
            >
            Send password reset email
          </Button>
          <button className="toggle-button" onClick={toggleResetPassword}>
            Back to Login
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-screen">
    {/* Left side - Login Form */}
        <div className="flex-1 flex items-center justify-center">
          {/* <div className="w-4/5 sm:w-2/3 md:w-2/3 xl:w-1/2"> */}
          <div className="w-11/12 sm:w-2/3 md:w-2/3 xl:w-1/2">

          <div className="mb-4 text-sm">
            <div className='justify-center'>
              {/* <div className='text-2xl text-center font-bold'>
                Join the waitlist for early access to SkimAI.
              </div> */}

              {/* <div className='text-md text-center'>
              We'll reach out to you as more spots become available.
              </div> */}
              <div className='text-sm text-slate-400 text-center'>We currently support Gmail, iCloud mail and Outlook. Others email providers are coming soon.</div>
            </div>
          </div>
            <div className="auth__container">

              <div className="welcome-container">
                {/* <span className="welcome-text">Welcome to</span> */}
                <img src={logo} alt="Logo" className="welcome_logo"/>
              </div>

              {isRegistering && (
                <Input
                    value={name}
                    onChange={e => setName(e.target.value)}
                    placeholder="Full Name"
                />

              )}
                        <br></br>
              <Input
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  placeholder="E-mail Address"
              />
              <br></br>
              <Input
                  value={password}
                  type="password"
                  onChange={e => setPassword(e.target.value)}
                  placeholder="Password"
              />

              <br></br>
              {isRegistering ? (
                <Button
                    disabled={!isChecked}
                    onClick={register}
                    size={SIZE.large}
                    overrides={{
                        BaseButton: {
                        style:({
                            marginBottom: '14px',
                        }),
                        },
                    }}
                    >
                    Sign up
                </Button>
                ) : (
                <Button
                    disabled={!isChecked}
                    onClick={login}
                    size={SIZE.large}
                    overrides={{
                        BaseButton: {
                        style:({
                            marginBottom: '14px',
                        }),
                        },
                    }}
                    >
                    Log in
                </Button>
              )}

              {!isRegistering && (
                  <button className="mt-2 text-sm text-blue-600 hover:underline" onClick={toggleResetPassword}>
                  Forgot Password?
                  </button>
              )}

              <div className="divider">
                  <hr className="divider__line" />
                  <span className="divider__text">or</span>
                  <hr className="divider__line" />
              </div>

              {/* <GoogleButton onClick={signInWithGoogle} disabled={!isChecked} /> */}
              
              <Button
                  disabled={!isChecked}
                  onClick={signInWithGoogle}
                  size={SIZE.large}
                  overrides={{
                      BaseButton: {
                      style: ({ $theme }) => ({
                          marginBottom: '14px',
                          color: 'rgb(72, 72, 72)',
                          backgroundColor: 'rgb(242, 242, 242)',
                          ':hover': {
                            backgroundColor: 'rgb(232, 232, 232)'
                          },
                      }),
                      },
                  }}
                  >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-6 h-6 me-4">
                    <title>{isRegistering ? "Sign up with Google" : "Sign in with Google"}</title>
                    <desc>Google G Logo</desc>
                    <path
                      d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                      className="fill-google-logo-blue"
                    ></path>
                    <path
                      d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                      className="fill-google-logo-green"
                    ></path>
                    <path
                      d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                      className="fill-google-logo-yellow"
                    ></path>
                    <path
                      d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                      className="fill-google-logo-red"
                    ></path>
                  </svg>
                  {isRegistering ? "Sign up with Google" : "Sign in with Google"}
              </Button>

                 <div className="flex">
                  <div className="flex items-center h-5">
                    <input
                      aria-describedby="helper-checkbox-text"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      defaultChecked={isChecked} // Set the defaultChecked attribute
                      onChange={handleCheckboxChange} // Handle checkbox change
                    />
                  </div>
                  <div className="ms-2 text-sm text-left">
                    <label className="text-gray-400 dark:text-gray-400">
                      I have read and accept the{' '}
                      <a className='text-blue-300 hover:underline' href='https://skim.page/pp/Privacy_Policy.pdf' target="_blank">
                        Privacy Policy
                      </a>
                      {' '}and the{' '}
                      <a className="text-blue-300 hover:underline" href="https://skim.page/terms/T_C.pdf" target="_blank">
                        Terms and Conditions
                      </a>
                      .
                    </label>
                  </div>
                </div>

              <button className="mt-4 text-sm text-blue-600 hover:underline" onClick={toggleIsRegistering}>
                {isRegistering ? 'Already have an account? Log In' : "Don't have an account? Register"}
              </button>

            </div>

            <div className="text-xs p-2 mt-1 text-slate-400">
                Skim.page use and transfer of information received from Google APIs to any other app will adhere to <a className='text-blue-300 hover:underline' href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="_blank">Google API Services User Data Policy</a>, including the Limited Use requirements.
            </div>
        </div>
    </div>

    {/* Right side - Image */}
    <div className="hidden lg:block flex-1 bg-contain bg-center bg-no-repeat relative">

      <div className="font-inter-tight max-w-xl mx-auto md:max-w-none flex flex-col md:flex-row md:space-x-8 lg:space-x-16 xl:space-x-20 space-y-8 space-y-reverse md:space-y-0 mb-10">
          
          <div className="flex flex-col justify-center items-center h-screen lg:w-10/12 xl:w-3/4 order-1 md:order-none">
              <h3 className="text-5xl mb-3 font-bold text-left">Auto-Magically Organize Your Emails 🪄</h3>
              <p className="text-lg text-gray-500 mb-8">Get some rest and let SkimAI manage everything behind the scenes. Allow SkimAI to automate your inbox so you can concentrate on what truly matters.</p>
              <ul className="inline-flex flex-col space-y-6">
                  <li className="flex items-start">
                    <div className='mr-3 text-3xl'>
                      ✍🏻
                    </div>
                    
                      <div>
                          <div className="font-cabinet-grotesk font-bold text-lg mb-1">AI-Crafted Responses</div>
                          <div className="text-gray-500">
                              SkimAI learns from your previous emails to automatically draft replies that fit your conversations, saving them to your inbox. 
                          </div>
                      </div>
                  </li>
                  <li className="flex items-start">
                      <div className='mr-3 text-3xl'>
                        🏷️
                      </div>
                      <div>
                          <div className="font-cabinet-grotesk font-bold text-lg mb-1">Categorize incoming emails</div>
                          <div className="text-gray-500">
                              Sorting through your inbox has never been easier with priority-based categorization for organizing your emails.
                          </div>
                      </div>
                  </li>
                  <li className="flex items-start">
                      <div className='mr-3 text-3xl'>
                        🤖
                      </div>
                      <div>
                          <div className="font-cabinet-grotesk font-bold text-lg mb-1">Automate Your Imagination</div>
                          <div className="text-gray-500">
                              Tailor SkimAI to suit your specific needs, allowing it to work for you just the way you want.
                          </div>
                      </div>
                  </li>
                  <li className="flex items-start">
                      <div className='mr-3 text-3xl'>
                        🔄
                      </div>
                      <div>
                          <div className="font-cabinet-grotesk font-bold text-lg mb-1">Seamless Synchronization</div>
                          <div className="text-gray-500">
                              Synchronize with your Gmail labels, iCloud and Outlook folders. Automatically create and update them according to your preferences.
                          </div>
                      </div>
                  </li>
              </ul>
          </div>

      </div>

      {/* <div className="absolute top-0 left-0 right-0 bottom-0 bg-black opacity-50"></div> */}

      {/* <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center">
        <ul>
          <li className="mb-4">Take back control of your inbox</li>
          <li className="mb-4">Automatically draft smart replies and save them to your draft mailbox</li>
          <li>Read summaries of emails instead of the entire content</li>
        </ul>
      </div> */}
    </div>
  </div>
  );
}

export default Auth;
