import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { db, auth } from '../../config/firebaseConfig.js';
import { collection, query, where, getDocs } from "firebase/firestore";
import { doc, setDoc, updateDoc, getDoc, deleteDoc, onSnapshot,Timestamp } from 'firebase/firestore'
import { v4 as uuidv4 } from 'uuid';
import './AutomationView.css';

import { useAutoAnimate } from '@formkit/auto-animate/react'
import ContentEditable from 'react-contenteditable'
import { ChromePicker } from 'react-color'; // Import ChromePicker from react-color
import { TwitterPicker } from 'react-color';
import gmail_icon from "../../assets/icons/gmail-icon-2.png"
import outlook_icon from "../../assets/icons/outlook-icon.png"
import icloud_icon from "../../assets/icons/icloud-icon.jpg"
import generic_email_icon from "../../assets/icons/generic-email-icon.png"
import { getAnalytics, logEvent } from '@firebase/analytics';


export const AutomationView = ({ userData}) => {
    const analytics = getAnalytics();
    const [automations, setAutomations] = useState([]);
    const [selectedAutomation, setSelectedAutomation] = useState(null);
    const [isConfirmDialogOpened, setIsConfirmDialogOpened] = useState(false)

    const selectAutomation = (automation) => {
      console.log(userData.userObject.tokens.calendarTokens)
      setSelectedAutomation(automation);
      setIsEmailInvalid(false)
      // console.log(selectedAutomation)
      // console.log(automation)
    };
  
    const addNewAutomation = async () => {
      logEvent(analytics, 'Automation View: Automation Created');

      //TODO: sanity check here pls
      let allEmails = userData.userObject.tokens.emailTokens
      var emailsArray = []
      allEmails.forEach((object) => {
        emailsArray.push(object.email)
      });
      
      var uuid = uuidv4()
      const newAutomation = {
        enabled: false,
        name: `New Automation`,
        owner: userData.user_uid,
        uuid: uuid,
        emails:emailsArray,
        createdAt: Timestamp.now(),
        catchall:true,
        conditions: [
          {
            type: "if",
            action: "",
          },
          {
            type: "then",
            action:"draft",
            content: {
              body: "",
              to:[]
            }
          }
        ]
      };
      setAutomations([...automations, newAutomation]);
      await setDoc(doc(db, "automations",uuid), newAutomation);
    };

    // init
    useEffect(() => {
      // console.log(userData.userObject.tokens.emailTokens)
      // Fetch initial data from Firebase
      const fetchData = () => {
        const q = query(collection(db, "automations"), where("owner", "==", userData.user_uid));
        // const hasSeenOnboardEmailConnected = localStorage.getItem('hasSeenOnboardEmailConnected')

        return onSnapshot(q, (snapshot) => {
          const tempAutomationData = [];
          console.log(snapshot.docs)

          // if(snapshot.docs.length === 0){
          //   //empty 
          //   console.log("new user!")
          // }

          snapshot.forEach((doc) => {
            tempAutomationData.push(doc.data());
          });
  
          tempAutomationData.sort((a, b) => {
            return b.createdAt - a.createdAt;
          });
  
          setAutomations(tempAutomationData);
          // setSelectedAutomation(tempAutomationData[0]); // Update the selected automation if needed
          // selectAutomation(automations[0])
        });
      };

      // Subscribe to updates
      const unsubscribe = fetchData();
  
      // Unsubscribe when component unmounts or when you no longer need updates
      // For example, in a cleanup function or in a useEffect cleanup
      return () => unsubscribe();
    }, [userData.user_uid]); 

    useEffect(() => {
      // react is shit 
      if (automations.length > 0 && selectedAutomation === null) {
        selectAutomation(automations[0]); 
      }
    }, [automations]); 

    const handleNameChange = async (id, newName) => {
      try {
        await updateDoc(doc(db, "automations", id), { name: newName });
      } catch (error) {
        console.error("Error updating document: ", error);
      }
    };

    const handleActionChange = async (index, value) => {
      selectedAutomation.conditions[index].action = value
      setSelectedAutomation(prevState => ({
        ...prevState,
        conditions: selectedAutomation.conditions
      }));
    }

    const handleEnableAutomationChange = (checked) =>{

      if(checked){
        logEvent(analytics, 'Automation View: Automation Enabled');
      } else {
        logEvent(analytics, 'Automation View: Automation Disabled');
      }

      setSelectedAutomation(prevState => ({
        ...prevState,
        enabled: checked
      }));

      const docRef = doc(db, "automations", selectedAutomation.uuid);
      updateDoc(docRef, {
          enabled: checked
        }).then(() => {});
    }

    const handleIncludeAllEmailsCheckboxChanges = (checked) => {
      setSelectedAutomation(prevState => ({
        ...prevState,
        catchall: checked
      }));
      const docRef = doc(db, "automations", selectedAutomation.uuid);
      updateDoc(docRef, {
          catchall: checked
        }).then(() => {});
    }

    const handleIncludeCCBCCCheckboxChanges = (checked) => {
      setSelectedAutomation(prevState => ({
        ...prevState,
        catch_cc_bcc: checked
      }));
      const docRef = doc(db, "automations", selectedAutomation.uuid);
      updateDoc(docRef, {
        catch_cc_bcc: checked
      }).then(() => {});
    }

    const saveAction = async (index, value) => {
      selectedAutomation.conditions[index].action = value

      const docRef = doc(db, "automations", selectedAutomation.uuid);
      updateDoc(docRef, {
          conditions: selectedAutomation.conditions
        }).then(() => {});
    }

    const [emailInput, setEmailInput] = useState('');
    const [isEmailInvalid, setIsEmailInvalid] = useState(false)
    
    const handleKeyDown = (index,e) => {
      if (e.key === 'Enter' || e.key === ',') {
        setIsEmailInvalid(false)
        e.preventDefault();

        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value);
        if (!isValidEmail) {
          // Handle invalid email (display error message, etc.)
          setIsEmailInvalid(true)
          return; // Exit function if email is invalid
        }

        setEmailInput('');
        if(!selectedAutomation.conditions[index].content || !selectedAutomation.conditions[index].content.to){
          selectedAutomation.conditions[index].content = {}
          selectedAutomation.conditions[index].content["to"] = []
        }

        selectedAutomation.conditions[index].content.to.push(e.target.value)
        setSelectedAutomation(prevState => ({
          ...prevState,
          conditions: selectedAutomation.conditions
        }));

        saveConditions()
      }
    };
  
    const removeEmail = (index,contentIndex) => {
      console.log(selectedAutomation.conditions[index].content)

      selectedAutomation.conditions[index].content.to = selectedAutomation.conditions[index].content.to.filter((_, i) => i !== contentIndex)

      setSelectedAutomation(prevState => ({
        ...prevState,
        conditions: selectedAutomation.conditions
      }));

      saveConditions()
      // setEmails(emails.filter((_, i) => i !== index));
    };

    const saveConditions = async (index, value) => {
      const docRef = doc(db, "automations", selectedAutomation.uuid);
      updateDoc(docRef, {
          conditions: selectedAutomation.conditions
        }).then(() => {

        });
    }

    // Function to handle checkbox selection
    const handleCheckboxChange = (email) => {

      if(!selectedAutomation.emails){
        selectedAutomation.emails = []
      }

      if (selectedAutomation.emails.includes(email)) {
        setSelectedAutomation(prevState => ({
          ...prevState,
          emails: selectedAutomation.emails.filter((e) => e !== email)
        }));

        selectedAutomation.emails = selectedAutomation.emails.filter((e) => e !== email)
      } else {
        var temp = [...selectedAutomation.emails, email]
        setSelectedAutomation(prevState => ({
          ...prevState,
          emails: temp
        }));

        selectedAutomation.emails = temp
      }

      // console.log(selectedAutomation.emails)

      const docRef = doc(db, "automations", selectedAutomation.uuid);
      updateDoc(docRef, {
          emails: selectedAutomation.emails
        }).then(() => {

        });
    };

    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (e) => {
      setInputValue(e.target.value);
    };

    const getEmailIcon = (email) => {
      let iconSrc
      if (email.endsWith('@googlemail.com') || email.endsWith('@gmail.com')) {
        iconSrc = gmail_icon;
      } else if (email.endsWith('@outlook.com') || email.endsWith('@live.com') || email.endsWith('@hotmail.com')) {
        iconSrc = outlook_icon;
      } else if (email.endsWith('@icloud.com')) {
        iconSrc = icloud_icon;
      } else {
        // Handle other email domains or invalid cases
        iconSrc = generic_email_icon;
      }
      return iconSrc
    }

    const deleteAutomation = (selectedAutomation) =>{
      logEvent(analytics, 'Automation View: Automation Deleted');
      deleteDoc(doc(db, "automations", selectedAutomation.uuid));
      setIsConfirmDialogOpened(false)
      setSelectedAutomation(null)
    }

    return (
        <>
            <div className="flex m-h-96 w-full bg-white rounded-lg border shadow-lg">
                <div className="min-w-48 w-48 border-r py-4 px-3">
                    <button onClick={addNewAutomation} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        New Automation
                    </button>
                    <ul className='mt-4'>
                    {automations.map((automation) => (
                      <>
                        <div
                        key={automation.uuid}
                        onClick={() => selectAutomation(automation)}
                        className={`cursor-pointer hover:bg-gray-200 px-2 py-2 mt-2 rounded truncate ${
                            (selectedAutomation && selectedAutomation.uuid === automation.uuid) ? 'font-bold bg-gray-200' : ''
                        }`}
                        >
                        {automation.name}
                          <div className='flex text-xs font-extralight text-slate-500'>
                            {/* <div>
                              {automation.enabled ? "enabled " : "disabled "}  
                            </div>    */}

                          {automation.enabled && 
                            <>
                              <span class="relative flex h-2 w-2 mt-1 mr-2">
                                <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
                                <span class="relative inline-flex rounded-full h-2 w-2 bg-green-500"></span>
                              </span>
                              <div className='mr-2'>Enabled</div>
                            </>
                          }

                          {!automation.enabled && 
                            <>
                              <span class="relative flex h-2 w-2 mt-1 mr-2">
                                {/* <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-gray-400 opacity-75"></span> */}
                                <span class="relative inline-flex rounded-full h-2 w-2 bg-gray-400"></span>
                              </span>
                              <div className='mr-2'>Disabled</div>
                            </>
                          }


                            <div>
                            · {automation.executionCount ? automation.executionCount : 0 } runs 
                            </div>   
                          </div>    
                        </div>
            
                      </>
                    ))}
                    </ul>
                </div>
                
                <div class="items-center justify-center w-full p-4">
                  
                  {selectedAutomation && 
                  <>
                    <div className='text-right -mb-9'>
                      <label className="inline-flex cursor-pointer">
                          <input
                              type="checkbox"
                              value=""
                              className="sr-only peer"
                              checked={selectedAutomation.enabled}
                              onChange={e => {
                                handleEnableAutomationChange(e.target.checked)
                              }}
                          />
                          <div className={`mt-1 relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green-300 rounded-full peer ${selectedAutomation.enabled ? 'peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white' : ''} after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all ${selectedAutomation.enabled ? 'peer-checked:bg-green-500' : ''}`}>
                          </div>
                      </label>
                    </div>

                    <h2 className="text-2xl font-bold mb-1" 
                        contentEditable="true"
                        suppressContentEditableWarning={true}
                        onBlur={(e) => handleNameChange(selectedAutomation.uuid, e.target.innerText)}
                        >{selectedAutomation ? selectedAutomation.name : ""}
                    </h2>
                    <div className='flex text-xs font-light text-slate-500'>
                      {selectedAutomation.enabled && 
                          <>
                            <span class="relative flex h-2 w-2 mt-1 mr-2">
                              <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
                              <span class="relative inline-flex rounded-full h-2 w-2 bg-green-500"></span>
                            </span>
                            <div className='mr-1'>Enabled</div>
                          </>
                        }

                        {!selectedAutomation.enabled && 
                          <>
                            <span class="relative flex h-2 w-2 mt-1 mr-2">
                              {/* <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-gray-400 opacity-75"></span> */}
                              <span class="relative inline-flex rounded-full h-2 w-2 bg-gray-400"></span>
                            </span>
                            <div className='mr-1'>Disabled</div>
                          </>
                        }
                      <div className='ml-1 mr-1'> · ⚡️ {selectedAutomation.executionCount ? selectedAutomation.executionCount : 0 } runs</div>

                      {/* <div >
                        {new Intl.DateTimeFormat('en-US', { dateStyle: 'medium' }).format(new Date(selectedAutomation.createdAt.seconds * 1000 + selectedAutomation.createdAt.nanoseconds / 1000000))}
                      </div> */}
                    </div>


                  </>
                  }

                    {selectedAutomation && 
                      <div className='mt-6 mb-2 font-bold text-center'>📥 Incoming Emails</div>
                    }

                    <div className={`rounded-xl p-3 border shadow-lg
                        ${
                          !selectedAutomation
                            ? 'hidden' 
                            : ''
                        } 
                        ${
                          selectedAutomation && userData.userObject.tokens.emailTokens.length > 1
                            ? '' 
                            : 'w-80 mx-auto'
                        }
                        `}>

                      <div className={`${
                          selectedAutomation && userData.userObject.tokens.emailTokens.length > 1
                            ? 'grid grid-cols-1 md:grid-cols-2 gap-3' 
                            : ''
                        }`}>
                        {selectedAutomation &&
                        userData.userObject.tokens.emailTokens.map((token, index) => (
                          <div
                            className={`rounded-lg py-2 pl-2 cursor-pointer ${
                              selectedAutomation.emails && selectedAutomation.emails.includes(token.email)
                                ? 'border-indigo-500 border-2 shadow-lg'
                                : 'border text-gray-300' 
                            }`}
                            key={index}
                            onClick={() => handleCheckboxChange(token.email)} 
                          >
                            <label className="flex items-center truncate cursor-pointer">
                              <input
                                type="checkbox"
                                checked={
                                  selectedAutomation.emails
                                    ? selectedAutomation.emails.includes(token.email)
                                    : false
                                }
                                onClick={() => handleCheckboxChange(token.email)} 
                                className="sr-only" 
                              />
                              <span className='text-xs flex'>

                              <img className={`h-4 mr-2 ${
                                  selectedAutomation.emails && selectedAutomation.emails.includes(token.email)
                                    ? 'opacity-100' 
                                    : 'opacity-50'
                                }`} src={getEmailIcon(token.email)} alt="gmail icon"></img>
                                {token.email}
                              </span>
                            </label>
                          </div>
                        ))}
                      </div>

                      {selectedAutomation && 
                        <div class="flex items-center mt-3">
                          <input 
                            checked={selectedAutomation.catchall}
                            id="checked-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                            onClick={(e) => { 
                              handleIncludeAllEmailsCheckboxChanges(e.target.checked)
                            }}
                          />
                          <label for="checked-checkbox" class="ms-2 text-xs font-light text-gray-900">Include all emails such as no-reply, notifications</label>
                        </div>                      
                      }

                      {selectedAutomation &&
                        <div class="flex items-center mt-3">
                          <input
                            checked={selectedAutomation.catch_cc_bcc}
                            id="checked-cc-bcc-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                            onClick={(e) => {
                              handleIncludeCCBCCCheckboxChanges(e.target.checked)
                            }}
                          />
                          <label for="checked-cc-bcc-checkbox" class="ms-2 text-xs font-light text-gray-900">Include emails where you are in CC or BCC</label>
                        </div>                      
                      }

                    </div>

                    {selectedAutomation &&
                    <div className="flex justify-center items-center h-12 -mt-2">
                        <svg
                            width="5"
                            height="12"
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-full"
                        >
                            <path
                                d="M 0 10 L 0 230"
                                stroke-miterlimit="10"
                                fill="none"
                                stroke="#5B64F6"
                                strokeWidth="5"
                                strokeDasharray="10"
                                strokeDashoffset="1"
                            >
                                <animate
                                    attributeName="stroke-dashoffset"
                                    values="100;0"
                                    dur="3s"
                                    calcMode="linear"
                                    repeatCount="indefinite"
                                />
                            </path>
                        </svg>
                    </div>                    
                    }

                    {selectedAutomation && selectedAutomation.conditions.map((condition,index) => (
                      <>

                        {condition.type === "if" && 
                        <>
                          <div className='mx-auto shadow-lg rounded-xl p-3 border-2 w-5/6 border-sky-500'>
                            <div className='flex'>
                              <span class="bg-sky-500 text-white text-xl font-medium me-2 px-3.5 py-1.5 rounded-md h-fit">
                                IF
                              </span>
                              <input
                                  type="text"
                                  placeholder="Describe the condition ..."
                                  className="w-1/3 p-2 border rounded-lg w-full"
                                  value={condition.action}
                                  onChange={(e) => {
                                    handleActionChange(index, e.target.value);
                                  }}
                                  onBlur={(e)=>{
                                    saveAction(index, e.target.value)
                                  }}
                              />
                            </div>

                            {/* <div class="flex items-center mt-2">
                                <input 
                                  checked={selectedAutomation.catchall}
                                  id="checked-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                                  onClick={(e) => { 
                                    handleIncludeAllEmailsCheckboxChanges(e.target.checked)
                                  }}
                                />
                                <label for="checked-checkbox" class="ms-2 text-sm font-light text-gray-900">Include all emails such as no-reply, notifications</label>
                            </div> */}
                          </div>

                          {/* <div className='h-10 initial left-1/2 mx-auto mt-0 border-2 border-dashed w-1 border-sky-500'></div>         */}
                          <div className="flex justify-center items-center h-12 -mt-2">
                                <svg
                                    width="5"
                                    height="12"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-full"
                                >
                                    <path
                                        d="M 0 10 L 0 230"
                                        stroke-miterlimit="10"
                                        fill="none"
                                        stroke="#0FA4E9FF"
                                        strokeWidth="5"
                                        strokeDasharray="10"
                                        strokeDashoffset="1"
                                    >
                                        <animate
                                            attributeName="stroke-dashoffset"
                                            values="100;0"
                                            dur="3s"
                                            calcMode="linear"
                                            repeatCount="indefinite"
                                        />
                                    </path>
                                </svg>
                            </div>
                        </>
                        }


                        {condition.type === "then" && 
                        <>
                          <div className='mt-0 shadow-lg rounded-xl p-3 border-2 border-green-500'>
                            <div className='flex'>
                              <div className='bg-green-500 text-white text-xl font-medium me-2 px-3.5 py-1.5 rounded-md h-fit'> THEN </div>


                                <select 
                                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                                  value={condition.action} 
                                  onChange={(e) => {
                                    handleActionChange(index, e.target.value)
                                    saveAction(index, e.target.value)
                                  }}
                                >
                                  <option selected>Choose an Action</option>
                                  <option value="draft">✍🏻 Draft a Reply</option>
                                  <option value="move_to_bin">🗑️ Move to Bin</option>
                                  <option value="forward">⏩ Forward</option>
                                </select>
                            </div>

                            {condition.action === "forward" && 
                              <div className='flex mt-4'>
                              <div className='bg-green-500 text-white text-xl font-medium me-2 px-3.5 py-1.5 rounded-md h-fit opacity-0'>THEN</div>

                              <div className='bg-gray-100 text-gray text-sm font-medium ml-10 px-2.5 py-0.5 rounded-md h-fit absolute'>To:</div>
                                <div className="flex flex-wrap gap-2">
                                  {condition.content && condition.content.to && (condition.content.to instanceof Array) && condition.content.to.map((email, contentIndex) => (
                                    <div key={contentIndex} className="h-8 border text-sm rounded-lg px-2 py-1 mb-0 flex items-center">
                                      <span className="text-gray-800">{email}</span>
                                      <button
                                        className="ml-2 mr-1 text-gray-600 hover:text-red-600"
                                        onClick={() => removeEmail(index,contentIndex)}
                                      >
                                        ✕
                                      </button>
                                    </div>
                                  ))}
                                  <div className=''>
                                    <input
                                      type="text"
                                      className={`border rounded-lg px-2 py-1 h-8 w-40 ${isEmailInvalid ? "border-red-300" : "border-gray-300"}`}
                                      placeholder="Add email..."
                                      value={emailInput}
                                      onChange={(e) => setEmailInput(e.target.value)}
                                      onKeyDown={(e) => {
                                        handleKeyDown(index,e)
                                      }}
                                    />
                                    {isEmailInvalid && 
                                      <div className='text-xs font-light text-red-400'>email is invalid</div>                                  
                                    }

                                  </div>

                                </div>
                            </div>
                            }
                          {(condition.action === "move_to_bin") && 
                              <>
                                {condition.action === "move_to_bin" && 
                                  <div className='flex'>
                                    <div className='bg-green-500 text-white text-xl font-medium me-2 px-3.5 py-1.5 rounded-md h-fit opacity-0'>THEN</div>
                                    <div className='text-xs mt-3 text-slate-400'>Automatically move matching emails to the trash. Emails can be recovered from the bin if needed.</div>
                                  </div>
                                }
                              </>
                            }

                          {(condition.action === "draft" || condition.action === "forward") && 
                              <>
                                <div className='flex mt-3'>
                                  <div className='bg-green-500 text-white text-xl font-medium me-2 px-3.5 py-1.5 rounded-md h-fit opacity-0'>THEN</div>

                                  {condition.action === "draft" && 
                                    <div className='bg-gray-100 text-gray text-sm font-medium ml-6 px-2.5 py-0.5 rounded-md h-fit absolute'>
                                      Goal:
                                    </div>                                  
                                  }

                                  {condition.action === "forward" && 
                                    <div className='bg-gray-100 text-gray text-sm font-medium ml-0 px-2.5 py-0.5 rounded-md h-fit absolute'>
                                      Message:
                                    </div>                                  
                                  }


                                  <textarea
                                    className='p-2 w-full border rounded-lg'
                                    value={condition.content?.body}
                                    onChange={(e) => {
                                      // setText(e.target.value)
                                      selectedAutomation.conditions[index].content ??= {};
                                      selectedAutomation.conditions[index].content.body = e.target.value
                                      setSelectedAutomation(prevState => ({
                                        ...prevState,
                                        conditions: selectedAutomation.conditions
                                      }));

                                    }}
                                    onBlur={(e) => {
                                      // console.log(index, e.target.value)
                                      selectedAutomation.conditions[index].content.body =  e.target.value

                                      const docRef = doc(db, "automations", selectedAutomation.uuid);
                                      updateDoc(docRef, {
                                          conditions: selectedAutomation.conditions
                                        }).then(() => {
                                      });
                                    }}
                                    placeholder="Enter text..."
                                    rows={5}
                                  />
                                </div>

                                {condition.action === "draft" && 
                                  <div className='flex'>
                                    <div className='bg-green-500 text-white text-xl font-medium me-2 px-3.5 py-1.5 rounded-md h-fit opacity-0'>THEN</div>
                                    {/* <div className='text-xs mt-3 text-slate-400'>No email will be sent; the draft will be saved to your inbox, ready for you to adjust and send.</div> */}
                                    <div className='text-xs mt-3 text-slate-400'>The draft will be saved to your inbox without sending. You can review and send it at your convenience.</div>
                                  </div>
                                }

                                {condition.action === "forward" && 
                                  <div className='flex'>
                                    <div className='bg-green-500 text-white text-xl font-medium me-2 px-3.5 py-1.5 rounded-md h-fit opacity-0'>THEN</div>
                                    <div className='text-xs mt-3 text-slate-400'>Forward matching emails to the designated recipient with the included message. Verify recipient details and context are appropriate.</div>
                                  </div>
                                }

                                {(condition.action === "draft" && userData.userObject.tokens.calendarTokens && userData.userObject.tokens.calendarTokens.length > 0) &&
                                  <div class="flex items-center mt-2">
                                    <div className='bg-green-500 text-white text-xl font-medium me-2 px-3.5 py-1.5 rounded-md h-fit opacity-0'>THEN</div>
                                    <input 
                                      checked={condition.content?.useCalendar ? true : false}
                                      id="checked-checkbox-calendar" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                                      onChange={(e) => { 
                                        selectedAutomation.conditions[index].content ??= {};
                                        selectedAutomation.conditions[index].content.useCalendar = e.target.checked;
                                        const docRef = doc(db, "automations", selectedAutomation.uuid);
                                        updateDoc(docRef, {
                                            conditions: selectedAutomation.conditions
                                          }).then(() => {
                                        });
                                        // handleUseCalendarDataCheckboxChanges(e.target.checked,condition)
                                      }}
                                    />
                                    <label for="checked-checkbox-calendar" class="ms-2 text-xs font-light text-gray-900">Use calendar data to personalize your draft reply.</label>
                                    {/* <label for="checked-checkbox-calendar" class="ms-2 text-xs font-light text-gray-900">Use calendar data to draft a reply.</label> */}
                                  </div>
                                }
                              </>
                            }
                          </div>
                        </>
                        }
                      </>
                    ))}

                    {selectedAutomation && 
                      <div 
                        className='text-center text-sm mt-8 text-red-400 cursor-pointer'
                        onClick={() => {
                          setIsConfirmDialogOpened(true)
                        }}
                      >Delete Automation
                      </div>
                    }
                </div>
            </div>

            {isConfirmDialogOpened && 
            <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
              <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

              <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                      <div class="sm:flex sm:items-start">
                        <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <svg class="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                          </svg>
                        </div>
                        <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                          <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Delete automation</h3>
                          <div class="mt-2">
                            <p class="text-sm text-gray-500">Are you sure you want to delete this automation? This action cannot be undone.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button type="button" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                        onClick={() => {
                          deleteAutomation(selectedAutomation)
                        }}
                      >Delete</button>
                      <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => {
                        setIsConfirmDialogOpened(false)
                      }}
                      >Cancel</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>            
            }

        </>
    )
}

// export default SmartLabelView;