import React, { useState, useEffect } from 'react';
import { db } from '../../config/firebaseConfig.js';
import { doc, updateDoc, collection, query, where, getDocs } from 'firebase/firestore';

import HorizontalBarChart from '../Charts/HorizontalBarChart.js';
import WeeklyDistributionChart from '../Charts/WeeklyDistributionChart.js';
import PieChart from '../Charts/PieChart.js';
import ScoreChart from '../Charts/ScoreChart.js';



export const EmailAnalyticsView = ({ userData, onUpdateLabels, isOpen, onClose, choosePlanOrigin, onLoaded}) => {
    const [isSyncing, setIsSyncing] = useState(false);
    const [isAlreadyProcessedError, setIsAlreadyProcessedError] = useState(false);
    const [isAnalyticsEnabled, setIsAnalyticsEnabled] = useState(false);
    const [analyticsData, setAnalyticsData] = useState({});
    const [selectedEmail, setSelectedEmail] = useState(null);
    const [dateRangeIndex, setDateRangeIndex] = useState(0);
    const [availableDateRanges, setAvailableDateRanges] = useState([]);
    const [nextAvailableTime, setNextAvailableTime] = useState("");

    useEffect(() => {
        if (userData.userObject && userData.userObject.configuration) {
            setIsAnalyticsEnabled(userData.userObject.configuration.isAnalyticsEnabled);
        }
    }, [userData]);

    // Load analytics data from local storage when the component mounts
    // useEffect(() => {
    //     const storedData = localStorage.getItem('analyticsData');
    //     if (storedData) {
    //         const parsedData = JSON.parse(storedData);
    //         setAnalyticsData(parsedData);
    //         const dateRanges = Object.values(parsedData).flatMap(emailData => emailData.map(d => d.dateRange));
    //         const formattedDateRanges = [...new Set(dateRanges.map(dr => `${new Date(dr.start_time.seconds * 1000).toLocaleDateString()} - ${new Date(dr.end_time.seconds * 1000).toLocaleDateString()}`))];
    //         setAvailableDateRanges(formattedDateRanges);
    //         const mostRecentDateIndex = formattedDateRanges.length - 1;
    //         setDateRangeIndex(mostRecentDateIndex);
    //         setSelectedEmail(Object.keys(parsedData)[0]);
    //     } else {
    //         setAnalyticsData({});
    //         setAvailableDateRanges([]);
    //         setDateRangeIndex(0);
    //         setSelectedEmail(null);
    //     }
    // }, []);

    // Load and display analytics data from local storage
    useEffect(() => {
        if (userData.userObject) {
            retrieveAnalytics().then(() => {
                if (onLoaded) {
                    onLoaded();
                }
            });
        }
    }, [userData.userObject]);    
    
    useEffect(() => {
        console.log('Updated analyticsData:', analyticsData);
        // Populate available date ranges
        if (Object.keys(analyticsData).length > 0) {
            const dateRanges = Object.values(analyticsData).flatMap(emailData => 
                emailData.map(d => d.dateRange)
            );
    
            const formattedDateRanges = [...new Set(
                dateRanges.map(dr => ({
                    start: new Date(dr.start_time.seconds * 1000),
                    end: new Date(dr.end_time.seconds * 1000),
                    formatted: `${new Date(dr.start_time.seconds * 1000).toLocaleDateString()} - ${new Date(dr.end_time.seconds * 1000).toLocaleDateString()}`
                }))
            )];
    
            formattedDateRanges.sort((a, b) => a.start - b.start);
    
            const sortedDateRanges = formattedDateRanges.map(dr => dr.formatted);
    
            setAvailableDateRanges(sortedDateRanges);
            const mostRecentDateIndex = sortedDateRanges.length - 1;
            setDateRangeIndex(mostRecentDateIndex);
            setSelectedEmail(Object.keys(analyticsData)[0]);
        } else {
            setAvailableDateRanges([]);
            setDateRangeIndex(0);
            setSelectedEmail(null);
        }
    }, [analyticsData]);

    useEffect(() => {
        if (isAlreadyProcessedError) {
            const targetDate = new Date();
            targetDate.setDate(targetDate.getDate() + ((7 - targetDate.getDay()) % 7) + 1); // Next Monday
            targetDate.setHours(9, 0, 0, 0); // 9:00 AM PST
            const interval = setInterval(() => {
                const now = new Date();
                const timeLeft = targetDate - now;
                const hours = Math.floor(timeLeft / (1000 * 60 * 60));
                const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
                setNextAvailableTime(`${hours}h ${minutes}m ${seconds}s`);
                if (timeLeft < 0) {
                    clearInterval(interval);
                    setIsAlreadyProcessedError(false);
                }
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [isAlreadyProcessedError]);    

    // Store analytics data in local storage whenever it changes
    // useEffect(() => {
    //     if (Object.keys(analyticsData).length > 0) {
    //         console.log('Storing analytics data in local storage:', analyticsData); // Add this line
    //         localStorage.setItem('analyticsData', JSON.stringify(analyticsData));
    //     }
    // }, [analyticsData]);

    const handleEnableAnalyticsChange = (checked) => {
        setIsAnalyticsEnabled(checked);
        updateUserConfiguration("isAnalyticsEnabled", checked);
    };

    const getSuggestions = (scoreComponents, inboundSecondsSpent, outboundSecondsSpent) => {
        const scores = [
            { name: "dedicatedTimeScore", value: scoreComponents.dedicatedTimeScore },
            { name: "dimensionEmailScore", value: scoreComponents.dimensionEmailScore },
            { name: "noiseEmailScore", value: scoreComponents.noiseEmailScore },
            { name: "unaddressedEmailScore", value: scoreComponents.unaddressedEmailScore },
            { name: "unorganisedEmailScore", value: scoreComponents.unorganisedEmailScore },
        ];
    
        // Sort scores in ascending order and get the two with the lowest values
        scores.sort((a, b) => a.value - b.value);
        const [firstMin, secondMin] = scores;
    
        // Create suggestions based on the lowest scores
        const suggestions = {
            dedicatedTimeScore: {
                description: "Spend more focused time on your emails. SkimAI can help you prioritize, so you focus on what truly matters.",
                savingPotential: 0.3 * inboundSecondsSpent + 0.3 * outboundSecondsSpent
                // rationale delegating work to AI allows to get more time back
            },
            dimensionEmailScore: {
                description: "Keep your emails concise and to the point. Avoid sending very long emails often. SkimAI can help draft clear and short messages.",
                savingPotential: 0.3 * inboundSecondsSpent + 0.4 * outboundSecondsSpent
                // rationale getting summaries of emails + writing effective emails
            },
            noiseEmailScore: {
                description: "Cut down on distractions by unsubscribing from unwanted emails. SkimAI's ability to unsubscribe/block noisy accounts can significantly clean up your inbox.",
                savingPotential: 0.25 * inboundSecondsSpent
                // rationale getting rid of notifications and annoying stuff
            },
            unaddressedEmailScore: {
                description: "Reply to important emails promptly to avoid backlogs. SkimAI can draft suitable replies for inbound emails that matter, ensuring you never miss a critical email.",
                savingPotential: 0.4 * inboundSecondsSpent + 0.4 * outboundSecondsSpent
                // rationale processing emails fast and effectively = less time reading, less time writing
            },
            unorganisedEmailScore: {
                description: "Keep your inbox organized with folders and labels. SkimAI's categorization features can automatically sort your emails into relevant folders for better management.",
                savingPotential: 0.3 * inboundSecondsSpent
                // rationale faster going through the inbound emails once they are nicely organised
            }
        };

        if (choosePlanOrigin) {
            const totalSavingPotential = suggestions[firstMin.name].savingPotential + suggestions[secondMin.name].savingPotential;
            return [{ 
                description: suggestions[firstMin.name].description,
                savingPotential: formatTime(totalSavingPotential / 7) 
            }];
            // return [{ description: "Combined suggestion for plan origin.", savingPotential: formatTime(totalSavingPotential / 7) }];
        }
    
        return [
            { ...suggestions[firstMin.name], savingPotential: formatTime(suggestions[firstMin.name].savingPotential/7) },
            { ...suggestions[secondMin.name], savingPotential: formatTime(suggestions[secondMin.name].savingPotential/7) }
        ];
    };
    

    const updateUserConfiguration = (configurationName, value) => {
        if (!userData.userObject["configuration"]) {
            userData.userObject["configuration"] = {};
        }
        userData.userObject["configuration"][configurationName] = value;

        const keyName = "configuration." + configurationName;
        const docRef = doc(db, "users", userData.userId);
        updateDoc(docRef, {
            [keyName]: value,
        }).then(() => {
            // Configuration updated
        });
    };

    const retrieveAnalytics = async () => {
        setIsAlreadyProcessedError(false);
        setIsSyncing(true);
    
        const dataObject = {
            userid: userData.userId,
            accessToken: userData.accessToken,
            choosePlanOrigin: choosePlanOrigin
        };
    
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get_emails_analytics`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataObject),
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            const reader = response.body.getReader();
            const decoder = new TextDecoder('utf-8');
            let { value, done } = await reader.read();
            let jsonString = '';
    
            while (!done) {
                jsonString += decoder.decode(value, { stream: true });
                const jsonLines = jsonString.split('\n');
                jsonString = jsonLines.pop();
    
                for (const line of jsonLines) {
                    if (line.trim()) {
                        try {
                            const data = JSON.parse(line);
                            if (data.status === 'alreadyProcessed') {
                                console.log('Email already processed:', data.email);
                                setIsAlreadyProcessedError(true);
                            } else if (data.status === 'processed') {
                                console.log('Email processed:', data.email);
                            } else {
                                console.log('Processing result:', data);
                            }
                        } catch (e) {
                            console.error('Failed to parse JSON line:', e, line);
                        }
                    }
                }

                ({ value, done } = await reader.read());
            }

            // await fetchAnalyticsFromFirestore();
            await Promise.all([fetchAnalyticsFromFirestore()]);
            // Reapply the selection logic after retrieving the data
            // const storedData = localStorage.getItem('analyticsData');
            // if (storedData) {
            //     const parsedData = JSON.parse(storedData);
            //     setAnalyticsData(parsedData);
            //     const dateRanges = Object.values(parsedData).flatMap(emailData => emailData.map(d => d.dateRange));
            //     const formattedDateRanges = [...new Set(dateRanges.map(dr => `${new Date(dr.start_time.seconds * 1000).toLocaleDateString()} - ${new Date(dr.end_time.seconds * 1000).toLocaleDateString()}`))];
            //     setAvailableDateRanges(formattedDateRanges);
            //     const mostRecentDateIndex = formattedDateRanges.length - 1;
            //     setDateRangeIndex(mostRecentDateIndex);
            //     setSelectedEmail(Object.keys(parsedData)[0]);
            // } else {
            //     setAnalyticsData({});
            //     setAvailableDateRanges([]);
            //     setDateRangeIndex(0);
            //     setSelectedEmail(null);
            // }
    
        } catch (error) {
            console.error('Error retrieving analytics:', error);
            setIsAlreadyProcessedError(true);
        } finally {
            setIsSyncing(false);
        }
    };

    const fetchAnalyticsFromFirestore = async () => {
        if (!userData.userObject) {
            return;
        }
    
        try {
            const q = query(collection(db, "email_analytics"), where("owner", "==", userData.userObject.uid));
            const querySnapshot = await getDocs(q);
    
            const analytics = {};
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                const email = data.email;
                if (!analytics[email]) {
                    analytics[email] = [];
                }
                analytics[email].push(data);
            });
    
            console.log('Analytics data from Firestore:', analytics);
            setAnalyticsData(analytics);
        } catch (error) {
            console.error('Error fetching analytics from Firestore:', error);
        }
    };

    // const updateAnalyticsData = (newAnalyticsData) => {
    //     console.log('Updating analytics data:', newAnalyticsData);
    //     setAnalyticsData(newAnalyticsData);
    //     localStorage.setItem('analyticsData', JSON.stringify(newAnalyticsData));
    //     const dateRanges = Object.values(newAnalyticsData).flatMap(emailData => emailData.map(d => d.dateRange));
    //     setAvailableDateRanges([...new Set(dateRanges.map(dr => `${new Date(dr.start_time.seconds * 1000).toLocaleDateString()} - ${new Date(dr.end_time.seconds * 1000).toLocaleDateString()}`))]);
    // };

    const handleSelectEmail = (email) => {
        setSelectedEmail(email);
    };

    const handleDateRangeChange = (direction) => {
        const newIndex = dateRangeIndex + direction;
        if (newIndex >= 0 && newIndex < availableDateRanges.length) {
            setDateRangeIndex(newIndex);
        }
    };

    const sumEmails = (emailData) => {
        if (!emailData) return 0;
        return Object.values(emailData).reduce((sum, dayData) => {
            return sum + Object.values(dayData || {}).reduce((daySum, count) => daySum + count, 0);
        }, 0);
    };    

    const formatTime = (seconds) => {
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        return h > 0 
            ? `${h.toString().padStart(2)}h${m.toString().padStart(2)}m`
            : `${m.toString().padStart(2)}m`;
    };    

    const formatTimeMinutes = (seconds) => {
        const totalMinutes = Math.floor(seconds / 60);
        return `${totalMinutes} minutes`;
    };

    const currentDateRange = availableDateRanges[dateRangeIndex];

    const filteredAnalyticsData = selectedEmail 
        ? analyticsData[selectedEmail].filter(d => {
            const dateRangeString = `${new Date(d.dateRange.start_time.seconds * 1000).toLocaleDateString()} - ${new Date(d.dateRange.end_time.seconds * 1000).toLocaleDateString()}`;
            return dateRangeString === currentDateRange;
        }) ?? []
        : [];

    const inboundEmailCount = selectedEmail && filteredAnalyticsData.length > 0 ? sumEmails(filteredAnalyticsData[0]?.inboundEmails) : 0;
    const outboundEmailCount = selectedEmail && filteredAnalyticsData.length > 0 ? sumEmails(filteredAnalyticsData[0]?.outboundEmails) : 0;
        
    const averageDailyTime = selectedEmail && filteredAnalyticsData.length > 0 && filteredAnalyticsData[0]?.sizeAndTimeDistribution ? formatTime(filteredAnalyticsData[0].sizeAndTimeDistribution.averageDailySecondsSpent) : "00h00m";
    const inboundTime = selectedEmail && filteredAnalyticsData.length > 0 && filteredAnalyticsData[0]?.sizeAndTimeDistribution ? formatTime(filteredAnalyticsData[0].sizeAndTimeDistribution.inboundSecondsSpent/7) : "00h00m";
    const outboundTime = selectedEmail && filteredAnalyticsData.length > 0 && filteredAnalyticsData[0]?.sizeAndTimeDistribution ? formatTime(filteredAnalyticsData[0].sizeAndTimeDistribution.outboundSecondsSpent/7) : "00h00m";
    const notificationTime = selectedEmail && filteredAnalyticsData.length > 0 && filteredAnalyticsData[0]?.sizeAndTimeDistribution ? formatTimeMinutes(filteredAnalyticsData[0].sizeAndTimeDistribution.notificationSecondsSpent/7) : "00h00m";
    const averageResponseTimeUser = selectedEmail && filteredAnalyticsData.length > 0 && filteredAnalyticsData[0]?.averageResponseTime ? formatTime(filteredAnalyticsData[0].averageResponseTime.user) : "00h00m";
    const averageResponseTimeRecipient = selectedEmail && filteredAnalyticsData.length > 0 && filteredAnalyticsData[0]?.averageResponseTime ? formatTime(filteredAnalyticsData[0].averageResponseTime.recipient) : "00h00m";
    
    return (
        <>

            {choosePlanOrigin && Object.keys(analyticsData).length == 0 &&
                <>
                    {!isAlreadyProcessedError && (
                        <div className="flex justify-center mb-10">
                            <button
                                className={`inline-flex px-2 py-2 text-xs font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 mt-3 ${isSyncing ? 'opacity-50' : ''}`}
                                onClick={() => {
                                    if (!isSyncing && !isAlreadyProcessedError) {
                                        retrieveAnalytics();
                                    }
                                }}
                                disabled={isSyncing}
                            >
                                {isSyncing && 
                                    <svg className={`w-4 h-4 me-2 ${isSyncing ? 'animate-spin' : ''}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.7 7.7A7.1 7.1 0 0 0 5 10.8M18 4v4h-4m-7.7 8.3A7.1 7.1 0 0 0 19 13.2M6 20v-4h4"/>
                                    </svg>
                                }
                                {isSyncing ? 'Analysing your emails' : '💾 Retrieve Analytics'}
                            </button>
                        </div>
                    )}
                </>
            }

            {choosePlanOrigin && !isSyncing && 
            <>
                <div className='text-2xl font-bold mb-6 mt-6 text-center'>
                    Hi {userData.userObject?.name ? userData.userObject?.name : userData.userObject?.email}, your free email analysis is ready! 
                </div>
            </>
            }


            <div className="grid md:grid-cols-3 gap-3">
                {!choosePlanOrigin && 
                <>
                    <div className="bg-white rounded-2xl shadow-lg p-4 duration-300 h-full col-span-3">
                        {/* TODO activate this once cloud run will exist */}
                        {/* <div className="text-right -mb-9">
                            <label className="inline-flex cursor-pointer">
                                <input
                                    type="checkbox"
                                    value=""
                                    className="sr-only peer"
                                    checked={isAnalyticsEnabled}
                                    onChange={e => handleEnableAnalyticsChange(e.target.checked)}
                                />
                                <div className={`mt-1 relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green-300 rounded-full peer ${isAnalyticsEnabled ? 'peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white' : ''} after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all ${isAnalyticsEnabled ? 'peer-checked:bg-green-500' : ''}`}>
                                </div>
                            </label>
                        </div> */}
                        <div className="text-xl font-semibold force-black">
                            📊 Inbox Insights 
                        </div>
                        
                        <div className="mb-4 mt-2 font-light force-black">
                            Gain valuable insights into your email usage. Identify patterns, uncover trends, and enhance your email management skills.
                        </div>

                        {choosePlanOrigin && isSyncing && (
                            <div className="mb-4 mt-2 font-light force-black">
                                We will notify you via email once the analysis is completed.
                            </div>
                        )}
                        
                        {isAlreadyProcessedError && !choosePlanOrigin && (
                            <div className="text-red-600 text-xs text-right mt-2">
                                {nextAvailableTime && <div>New analytics will be available in: {nextAvailableTime}</div>}
                            </div>
                        )}
        
                        {!isAlreadyProcessedError && (
                            <div className="flex justify-between">
                                <button
                                    className={`inline-flex px-2 py-2 text-xs font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 mt-3 ${isSyncing ? 'opacity-50' : ''}`}
                                    onClick={() => {
                                        if (!isSyncing && !isAlreadyProcessedError) {
                                            retrieveAnalytics();
                                        }
                                    }}
                                    disabled={isSyncing}
                                >
                                    {isSyncing && 
                                        <svg className={`w-4 h-4 me-2 ${isSyncing ? 'animate-spin' : ''}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.7 7.7A7.1 7.1 0 0 0 5 10.8M18 4v4h-4m-7.7 8.3A7.1 7.1 0 0 0 19 13.2M6 20v-4h4"/>
                                        </svg>
                                    }
                                    {isSyncing ? 'Retrieving Analytics...' : '💾 Retrieve Analytics'}
                                </button>
                            </div>
                        )}
                    </div>
                </>
                }

                {Object.keys(analyticsData).length !== 0 && (
                    <>
                        {!choosePlanOrigin && (
                            <div className="bg-white rounded-xl shadow-lg px-4 py-2 duration-300 h-full col-span-3">
                                <div className="flex justify-center items-center h-full">
                                    <div className="flex justify-between items-center w-full">
                                        <button onClick={() => handleDateRangeChange(-1)} disabled={dateRangeIndex === 0}>⬅️</button>
                                        <span className="mx-1 text-md text-center" style={{ flex: 1 }}>
                                            {currentDateRange}
                                        </span>
                                        <button onClick={() => handleDateRangeChange(1)} disabled={dateRangeIndex === availableDateRanges.length - 1}>➡️</button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {choosePlanOrigin && (
                            <>
                            <div className="bg-white rounded-xl shadow-lg px-4 py-2 duration-300 h-full col-span-3 mx-16">
                                <div className="text-md text-center">
                                    ✉️ {userData?.userObject?.tokens?.emailTokens?.[0]?.email ?? '...'}
                                </div>
                            </div>
                            <div className="bg-white rounded-xl shadow-lg px-4 py-2 duration-300 h-full col-span-3 mx-16">
                                <div className="text-md text-center">
                                    📆 {currentDateRange}
                                </div>
                            </div>
                            </>
                        )}

                        {!choosePlanOrigin && (
                            <div className="duration-300 h-full col-span-3">
                                <div className="flex justify-center items-center h-full">
                                    <div className="flex justify-between items-center w-full">
                                        <div className="flex items-center">
                                            {Object.keys(analyticsData).map((email) => (
                                                <div
                                                    key={email}
                                                    onClick={() => handleSelectEmail(email)}
                                                    className={`cursor-pointer mr-2 hover:bg-gray-200 integration-tag px-2 py-4 rounded truncate ${
                                                        (selectedEmail && selectedEmail === email) ? 'font-bold bg-gray-200' : ''
                                                    }`}
                                                >
                                                    <p className="text-xs force-black">{email}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="text-gray-800 text-xl font-semibold text-center mt-4 mb-1 col-span-3">
                            🏅 Email Productivity Score
                        </div>
    
                        {filteredAnalyticsData.some(data => data.scoreComponents && data.scoreComponents.totalScore) && (
                            <div className="duration-300 h-full col-span-2">
                                <div className="flex flex-col">
                                    <div className="bg-white rounded-2xl shadow-lg p-4 duration-300">
                                        <div className="flex justify-center items-center h-full">
                                            <div className="flex flex-col justify-between items-center w-full">
                                                {filteredAnalyticsData.map((data, index) => (
                                                    <React.Fragment key={index}>
                                                        {data.scoreComponents && data.scoreComponents.totalScore && (
                                                            <div className="">
                                                                <div className="flex flex-col items-center">
                                                                    <ScoreChart score={data.scoreComponents.totalScore} width={400} height={400} />
                                                                </div>
                                                                <div className="flex flex-col items-center mt-4">
                                                                    <span className='text-md text-gray-600'>
                                                                        {data.scoreComponents.totalScore > 95 && "OMG"}
                                                                        {data.scoreComponents.totalScore > 90 && data.scoreComponents.totalScore <= 95 && "Wow"}
                                                                        {data.scoreComponents.totalScore > 85 && data.scoreComponents.totalScore <= 90 && "Neat"}
                                                                        {data.scoreComponents.totalScore > 80 && data.scoreComponents.totalScore <= 85 && "Congrats"}
                                                                        {data.scoreComponents.totalScore > 70 && data.scoreComponents.totalScore <= 80 && "Great Job"}
                                                                        {data.scoreComponents.totalScore > 60 && data.scoreComponents.totalScore <= 70 && "Not bad"}
                                                                        {data.scoreComponents.totalScore <= 60 && "Keep Trying"}
                                                                        ! You are in the Top {" "}
                                                                        <strong>
                                                                            {data.scoreComponents.totalScore > 95 && "0.01%"}
                                                                            {data.scoreComponents.totalScore > 90 && data.scoreComponents.totalScore <= 95 && "0.1%"}
                                                                            {data.scoreComponents.totalScore > 85 && data.scoreComponents.totalScore <= 90 && "1%"}
                                                                            {data.scoreComponents.totalScore > 80 && data.scoreComponents.totalScore <= 85 && "5%"}
                                                                            {data.scoreComponents.totalScore > 70 && data.scoreComponents.totalScore <= 80 && "10%"}
                                                                            {data.scoreComponents.totalScore > 60 && data.scoreComponents.totalScore <= 70 && "20%"}
                                                                            {data.scoreComponents.totalScore <= 60 && "50%"}
                                                                        </strong>
                                                                        {" "}Email Users!
                                                                    </span>
                                                                    <div className="text-md text-gray-600 mt-4 p-1">
                                                                        Your score reflects how well you manage your emails, including the time you spend, how organized you are, and the amount of spam. Better email habits mean a higher score!
                                                                    </div>
                                                                </div>

                                                                <div className='text-center mt-4 mb-3 text-lg text-gray-600'><strong>👇 Tell a Friend</strong></div>
                                                                <div class="sharing-buttons flex flex-wrap justify-center items-center">                                                                    
                                                                    <a class="border-2 duration-200 ease inline-flex items-center mb-1 mr-1 transition p-1 rounded text-white border-blue-600 bg-blue-600 hover:bg-blue-700 hover:border-blue-700" target="_blank" rel="noopener" href="https://facebook.com/sharer/sharer.php?u=https%3A%2F%2Fskim.page" aria-label="Share on Facebook" draggable="false">
                                                                        <svg aria-hidden="true" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-4 h-4">
                                                                        <title>Facebook</title>
                                                                        <path d="M379 22v75h-44c-36 0-42 17-42 41v54h84l-12 85h-72v217h-88V277h-72v-85h72v-62c0-72 45-112 109-112 31 0 58 3 65 4z">
                                                                        </path>
                                                                        </svg>
                                                                    </a>
                                                                    <a class="border-2 duration-200 ease inline-flex items-center mb-1 mr-1 transition p-1 rounded text-white border-blue-600 bg-blue-600 hover:bg-blue-700 hover:border-blue-700" target="_blank" rel="noopener" href={`https://twitter.com/intent/tweet?text=Hey!%20I%20scored%20${data.scoreComponents.totalScore.toFixed(0)}%20on%20the%20Email%20Productivity%20Score.%20Get%20your%20free%20email%20analysis%20with%20SkimAI%20now!%20https%3A%2F%2Fskim.page&amp;`} aria-label="Share on X">
                                                                        <svg aria-hidden="true" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-4 h-4">
                                                                        <title>X</title>
                                                                        <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z">
                                                                        </path>
                                                                        </svg>
                                                                    </a>
                                                                    <a class="border-2 duration-200 ease inline-flex items-center mb-1 mr-1 transition p-1 rounded text-white border-blue-600 bg-blue-600 hover:bg-blue-700 hover:border-blue-700" target="_blank" rel="noopener" href={`https://www.linkedin.com/shareArticle?url=https%3A%2F%2Fskim.page&text=Hey!%20I%20scored%20${data.scoreComponents.totalScore.toFixed(0)}%20on%20the%20Email%20Productivity%20Score.%20Get%20your%20free%20email%20analysis%20with%20SkimAI%20now!&amp;summary=Hey!%20I%20scored%20${data.scoreComponents.totalScore.toFixed(0)}%20on%20the%20Email%20Productivity%20Score.%20Get%20your%20free%20email%20analysis%20with%20SkimAI%20now!&amp;source=https%3A%2F%2Fskim.page`} aria-label="Share on Linkedin">
                                                                        <svg aria-hidden="true" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-4 h-4">
                                                                        <title>Linkedin</title>
                                                                        <path d="M136 183v283H42V183h94zm6-88c1 27-20 49-53 49-32 0-52-22-52-49 0-28 21-49 53-49s52 21 52 49zm333 208v163h-94V314c0-38-13-64-47-64-26 0-42 18-49 35-2 6-3 14-3 23v158h-94V183h94v41c12-20 34-48 85-48 62 0 108 41 108 127z">
                                                                        </path>
                                                                        </svg>
                                                                    </a>
                                                                    <a class="border-2 duration-200 ease inline-flex items-center mb-1 mr-1 transition p-1 rounded text-white border-blue-600 bg-blue-600 hover:bg-blue-700 hover:border-blue-700" target="_blank" rel="noopener" 
                                                                      href={`mailto:?subject=${encodeURIComponent('Checkout my score')}&body=${encodeURIComponent(`Hey! ${'\n\n'} I scored ${data.scoreComponents.totalScore.toFixed(0)} on the Email Productivity Score. Get your free email analysis with SkimAI now! ${'\n\n'} https://skim.page`)}`} 
                                                                      aria-label="Share by Email" draggable="false">
                                                                        <svg aria-hidden="true" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-4 h-4">
                                                                        <title>Email</title>
                                                                        <path d="M464 64a48 48 0 0 1 29 86L275 314c-11 8-27 8-38 0L19 150a48 48 0 0 1 29-86h416zM218 339c22 17 54 17 76 0l218-163v208c0 35-29 64-64 64H64c-35 0-64-29-64-64V176l218 163z">
                                                                        </path>
                                                                        </svg>
                                                                    </a>
                                                                    {/* <a class="border-2 duration-200 ease inline-flex items-center mb-1 mr-1 transition p-1 rounded text-white border-blue-600 bg-blue-600 hover:bg-blue-700 hover:border-blue-700" target="_blank" rel="noopener" href={`https://www.reddit.com/submit?url=https%3A%2F%2Fskim.page&title=Hey!%20I%20scored%2070%20on%20the%20Email%20Productivity%20Score.%20What%20is%20your%20score?`} aria-label="Share on Reddit">
                                                                        <svg aria-hidden="true" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-4 h-4">
                                                                        <title>Reddit</title>
                                                                        <path d="M373 138.6c-25.2 0-46.3-17.5-51.9-41l0 0c-30.6 4.3-54.2 30.7-54.2 62.4l0 .2c47.4 1.8 90.6 15.1 124.9 36.3c12.6-9.7 28.4-15.5 45.5-15.5c41.3 0 74.7 33.4 74.7 74.7c0 29.8-17.4 55.5-42.7 67.5c-2.4 86.8-97 156.6-213.2 156.6S45.5 410.1 43 323.4C17.6 311.5 0 285.7 0 255.7c0-41.3 33.4-74.7 74.7-74.7c17.2 0 33 5.8 45.7 15.6c34-21.1 76.8-34.4 123.7-36.4l0-.3c0-44.3 33.7-80.9 76.8-85.5C325.8 50.2 347.2 32 373 32c29.4 0 53.3 23.9 53.3 53.3s-23.9 53.3-53.3 53.3zM157.5 255.3c-20.9 0-38.9 20.8-40.2 47.9s17.1 38.1 38 38.1s36.6-9.8 37.8-36.9s-14.7-49.1-35.7-49.1zM395 303.1c-1.2-27.1-19.2-47.9-40.2-47.9s-36.9 22-35.7 49.1c1.2 27.1 16.9 36.9 37.8 36.9s39.3-11 38-38.1zm-60.1 70.8c1.5-3.6-1-7.7-4.9-8.1c-23-2.3-47.9-3.6-73.8-3.6s-50.8 1.3-73.8 3.6c-3.9 .4-6.4 4.5-4.9 8.1c12.9 30.8 43.3 52.4 78.7 52.4s65.8-21.6 78.7-52.4z">
                                                                        </path>
                                                                        </svg>
                                                                    </a> */}
                                                                    {/* <a class="border-2 duration-200 ease inline-flex items-center mb-1 mr-1 transition p-1 rounded text-white border-blue-600 bg-blue-600 hover:bg-blue-700 hover:border-blue-700" target="_blank" rel="noopener" href={`https://discord.com/channels/@me?message=Hey!%20I%20scored%2070%20on%20the%20Email%20Productivity%20Score.%20Is%20that%20good?%0ACheck%20out%20SkimAI%20for%20a%20free%20email%20audit%20and%20improve%20your%20email%20productivity!%0Ahttps%3A%2F%2Fskim.page`} aria-label="Share on Discord">
                                                                    <svg aria-hidden="true" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 605 512" class="w-4 h-4">
                                                                        <title>Discord</title>
                                                                        <path d="M524.5 69.8a1.5 1.5 0 0 0 -.8-.7A485.1 485.1 0 0 0 404.1 32a1.8 1.8 0 0 0 -1.9 .9 337.5 337.5 0 0 0 -14.9 30.6 447.8 447.8 0 0 0 -134.4 0 309.5 309.5 0 0 0 -15.1-30.6 1.9 1.9 0 0 0 -1.9-.9A483.7 483.7 0 0 0 116.1 69.1a1.7 1.7 0 0 0 -.8 .7C39.1 183.7 18.2 294.7 28.4 404.4a2 2 0 0 0 .8 1.4A487.7 487.7 0 0 0 176 479.9a1.9 1.9 0 0 0 2.1-.7A348.2 348.2 0 0 0 208.1 430.4a1.9 1.9 0 0 0 -1-2.6 321.2 321.2 0 0 1 -45.9-21.9 1.9 1.9 0 0 1 -.2-3.1c3.1-2.3 6.2-4.7 9.1-7.1a1.8 1.8 0 0 1 1.9-.3c96.2 43.9 200.4 43.9 295.5 0a1.8 1.8 0 0 1 1.9 .2c2.9 2.4 6 4.9 9.1 7.2a1.9 1.9 0 0 1 -.2 3.1 301.4 301.4 0 0 1 -45.9 21.8 1.9 1.9 0 0 0 -1 2.6 391.1 391.1 0 0 0 30 48.8 1.9 1.9 0 0 0 2.1 .7A486 486 0 0 0 610.7 405.7a1.9 1.9 0 0 0 .8-1.4C623.7 277.6 590.9 167.5 524.5 69.8zM222.5 337.6c-29 0-52.8-26.6-52.8-59.2S193.1 219.1 222.5 219.1c29.7 0 53.3 26.8 52.8 59.2C275.3 311 251.9 337.6 222.5 337.6zm195.4 0c-29 0-52.8-26.6-52.8-59.2S388.4 219.1 417.9 219.1c29.7 0 53.3 26.8 52.8 59.2C470.7 311 447.5 337.6 417.9 337.6z">
                                                                        </path>
                                                                        </svg>
                                                                    </a> */}
                                                                    {/* <a class="border-2 duration-200 ease inline-flex items-center mb-1 mr-1 transition p-1 rounded text-white border-blue-600 bg-blue-600 hover:bg-blue-700 hover:border-blue-700" target="_blank" rel="noopener" href="https://slack.com/" aria-label="Share on Slack" onclick="alert('Copy the text below to share on Slack:\n\nHey! I scored 70 on the Email Productivity Score. Is that good?\nCheck out SkimAI for a free email audit and improve your email productivity!\nhttps://skim.page'); return false;">
                                                                        <svg aria-hidden="true" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-4 h-4">
                                                                            <title>Slack</title>
                                                                            <path d="M94.1 315.1c0 25.9-21.2 47.1-47.1 47.1S0 341 0 315.1c0-25.9 21.2-47.1 47.1-47.1h47.1v47.1zm23.7 0c0-25.9 21.2-47.1 47.1-47.1s47.1 21.2 47.1 47.1v117.8c0 25.9-21.2 47.1-47.1 47.1s-47.1-21.2-47.1-47.1V315.1zm47.1-189c-25.9 0-47.1-21.2-47.1-47.1S139 32 164.9 32s47.1 21.2 47.1 47.1v47.1H164.9zm0 23.7c25.9 0 47.1 21.2 47.1 47.1s-21.2 47.1-47.1 47.1H47.1C21.2 244 0 222.8 0 196.9s21.2-47.1 47.1-47.1H164.9zm189 47.1c0-25.9 21.2-47.1 47.1-47.1 25.9 0 47.1 21.2 47.1 47.1s-21.2 47.1-47.1 47.1h-47.1V196.9zm-23.7 0c0 25.9-21.2 47.1-47.1 47.1-25.9 0-47.1-21.2-47.1-47.1V79.1c0-25.9 21.2-47.1 47.1-47.1 25.9 0 47.1 21.2 47.1 47.1V196.9zM283.1 385.9c25.9 0 47.1 21.2 47.1 47.1 0 25.9-21.2 47.1-47.1 47.1-25.9 0-47.1-21.2-47.1-47.1v-47.1h47.1zm0-23.7c-25.9 0-47.1-21.2-47.1-47.1 0-25.9 21.2-47.1 47.1-47.1h117.8c25.9 0 47.1 21.2 47.1 47.1 0 25.9-21.2 47.1-47.1 47.1H283.1z"></path>
                                                                        </svg>
                                                                    </a> */}
                                                                    {/* <a class="border-2 duration-200 ease inline-flex items-center mb-1 mr-1 transition p-1 rounded text-white border-blue-600 bg-blue-600 hover:bg-blue-700 hover:border-blue-700" target="_blank" rel="noopener" href={`https://www.tiktok.com/`} aria-label="Share on TikTok" onclick="alert('Copy the text below to share on TikTok:\n\nHey! I scored 70 on the Email Productivity Score. Is that good?\nCheck out SkimAI for a free email audit and improve your email productivity!\nhttps://skim.page'); return false;">
                                                                        <svg aria-hidden="true" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-4 h-4">
                                                                            <title>TikTok</title>
                                                                            <path d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z"></path>
                                                                        </svg>
                                                                    </a> */}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="duration-300 h-full col-span-1">
                            <div className="flex flex-col h-full">

                            <div className="bg-white rounded-2xl shadow-lg p-4 duration-300 h-full col-span-1">
                                <div className="flex justify-center items-center h-full">
                                    <div className="flex flex-col justify-between items-center w-full">
                                        <span className="text-gray-600 text-md text-center my-2">
                                            Time You Spend on Emails
                                        </span>
                                        <div className="flex items-center">
                                            <span className="text-2xl mx-1">⏱️</span>
                                            <span className="text-4xl mx-1">{averageDailyTime}</span>
                                            <span className="text-sm text-gray-500" style={{ position: 'relative', top: '7px' }}>/day</span>
                                            </div>
                                    </div>
                                </div>
                            </div>


                                <div className="bg-white rounded-2xl shadow-lg p-4 duration-300 h-full mt-3">
                                    <div className="flex justify-center items-center h-full">
                                        <div className="flex flex-col justify-between items-center w-full">
                                        <span className="text-gray-600 text-md my-2">Emails Received</span>
                                            <div className="flex items-center">
                                                <span className="text-2xl mx-1">📥</span>
                                                <span className="text-4xl mx-1">{inboundEmailCount}</span>
                                            </div>
                                            <span className="text-gray-600 text-md mt-8 mb-2">Emails Sent</span>
                                            <div className="flex items-center">
                                                <span className="text-2xl mx-1">📤</span>
                                                <span className="text-4xl mx-1">{outboundEmailCount}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="text-gray-800 text-xl font-semibold text-center mt-4 mb-1 col-span-3">
                            💍 Email Engagement Overview
                        </div>

                        {filteredAnalyticsData.map((data, index) => (
                            data.notifications && 
                            data.notifications.rankingNotificationsSenders && 
                            Object.keys(data.notifications.rankingNotificationsSenders).length > 0 && (
                                <React.Fragment key={index}>
                                    <div className="bg-white rounded-2xl shadow-lg p-4 duration-300 h-full col-span-1">
                                        <div className="flex justify-center items-center h-full">
                                            <div className="flex flex-col justify-between items-center w-full">
                                                <span className="text-gray-600 text-md text-center my-2">
                                                    Notifications & No Replies
                                                </span>
                                                <div className="flex items-center">
                                                    <span className="text-2xl mx-1">🧹</span>
                                                    <span className="text-4xl mx-1">{data.notifications.totalNotificationsEmails}</span>
                                                </div>

                                                <span className="text-gray-600 text-sm text-center mt-6">
                                                    That's like <strong>{notificationTime} a day</strong> reading notifications.
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-white rounded-2xl shadow-lg p-4 duration-300 h-full col-span-2">
                                        <div className="text-gray-700 text-sm font-semibold">
                                            🧹 Top Notification Senders
                                        </div>
                                        <div className="flex justify-center items-center h-full">
                                            <div className="flex flex-col justify-between items-center w-full">
                                                <div className="mt-2 mb-2 w-full">
                                                    <HorizontalBarChart 
                                                        data={data.notifications.rankingNotificationsSenders}
                                                        firstLabel="Notification Sender"
                                                        secondLabel="Emails"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        ))}


                        {filteredAnalyticsData.map((data, index) => (
                            data.rankingSenders && 
                            Object.keys(data.rankingSenders).length > 0 && 
                            Object.values(data.rankingSenders.sendersEmails).some(email => email > 0) && (
                                <React.Fragment key={index}>
                                    <div className="bg-white rounded-2xl shadow-lg p-4 duration-300 h-full col-span-2">
                                        <div className="text-gray-700 text-sm font-semibold">
                                            📥 Top Email Senders
                                        </div>
                                        <div className="flex justify-center items-center h-full">
                                            <div className="flex flex-col justify-between items-center w-full">
                                                <div className="mt-2 mb-2 w-full">
                                                    <HorizontalBarChart
                                                        data={data.rankingSenders.sendersEmails}
                                                        firstLabel="Sender"
                                                        type="Sender"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="bg-white rounded-2xl shadow-lg p-4 duration-300 h-full col-span-1">
                                        <div className="flex justify-center items-center h-full">
                                            <div className="flex flex-col justify-between items-center w-full">
                                                <span className="text-gray-600 text-md text-center my-2">
                                                    It takes you
                                                </span>
                                                <div className="flex items-center">
                                                    <span className="text-2xl mx-1">⏱️</span>
                                                    <span className="text-4xl mx-1">{averageResponseTimeUser}</span>
                                                </div>
                                                <span className="text-gray-600 text-md text-center my-2">
                                                   to respond to emails
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        ))}

                        {filteredAnalyticsData.map((data, index) => (
                            data.rankingRecipients && 
                            Object.keys(data.rankingRecipients).length > 0 && 
                            Object.values(data.rankingRecipients.recipientsEmails).some(email => email > 0) && (
                                <React.Fragment key={index}>
                                    <div className="bg-white rounded-2xl shadow-lg p-4 duration-300 h-full col-span-2">
                                        <div className="text-gray-700 text-sm font-semibold">
                                            📤 Top Email Recipients
                                        </div>
                                        <div className="flex justify-center items-center h-full">
                                            <div className="flex flex-col justify-between items-center w-full">
                                                <div className="mt-2 mb-2 w-full">
                                                    <HorizontalBarChart
                                                        data={data.rankingRecipients.recipientsEmails}
                                                        firstLabel="Recipient"
                                                        type="Recipient"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="bg-white rounded-2xl shadow-lg p-4 duration-300 h-full col-span-1">
                                        <div className="flex justify-center items-center h-full">
                                            <div className="flex flex-col justify-between items-center w-full">
                                                <span className="text-gray-600 text-md text-center my-2">
                                                    Your recipients take
                                                </span>
                                                <div className="flex items-center">
                                                    <span className="text-2xl mx-1">⏱️</span>
                                                    <span className="text-4xl mx-1">{averageResponseTimeRecipient}</span>
                                                </div>
                                                <span className="text-gray-600 text-md text-center my-2">
                                                   to get back to you
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        ))}

                        <div className="text-gray-800 text-xl font-semibold text-center mt-4 mb-1 col-span-3">
                            🫁 Anatomy of Your Inbox
                        </div>

                        {filteredAnalyticsData.map((data, index) => (
                            data.inboundEmails && Object.keys(data.inboundEmails).length > 0 && Object.values(data.inboundEmails).some(dayData => Object.keys(dayData).length > 0) && (
                                <div key={index} className="bg-white rounded-2xl shadow-lg p-4 duration-300 h-full col-span-3">
                                    <div className="text-gray-700 text-sm font-semibold">
                                        📥 Weekly Breakdown of Emails Received
                                    </div>
                                    <div className="flex justify-center items-center h-full">
                                        <div className="flex flex-col justify-between items-center w-full">
                                            <div className='w-full'>
                                                <WeeklyDistributionChart 
                                                    inboundData={data.inboundEmails} 
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        ))}

                        {filteredAnalyticsData.map((data, index) => (
                            data.outboundEmails && Object.keys(data.outboundEmails).length > 0 && Object.values(data.outboundEmails).some(dayData => Object.keys(dayData).length > 0) && (
                                <div key={index} className="bg-white rounded-2xl shadow-lg p-4 duration-300 h-full col-span-3">
                                    <div className="text-gray-700 text-sm font-semibold">
                                        📤 Weekly Breakdown of Emails Sent
                                    </div>
                                    <div className="flex justify-center items-center h-full">
                                        <div className="flex flex-col justify-between items-center w-full">
                                            <div className='w-full'>
                                                <WeeklyDistributionChart 
                                                    outboundData={data.outboundEmails} 
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        ))}

                        {filteredAnalyticsData.map((data, index) => (
                            <React.Fragment key={index}>
                                {data.organisationStatus && data.organisationStatus.rankingCategories && Object.keys(data.organisationStatus.rankingCategories).length > 0 && (
                                    <div className="bg-white rounded-2xl shadow-lg p-4 duration-300 h-full col-span-3">
                                        <div className="text-gray-700 text-sm font-semibold">
                                            📁 Categories Distribution
                                        </div>
                                        <div className="flex justify-center items-center h-full">
                                            <div className="flex flex-col justify-between items-center w-full">
                                                <div className="mt-2 mb-2 flex justify-center w-full">
                                                    <PieChart data={data.organisationStatus.rankingCategories} width={300} height={300} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {data.organisationStatus && data.organisationStatus.rankingFolders && Object.keys(data.organisationStatus.rankingFolders).length > 0 && (
                                    <div className="bg-white rounded-2xl shadow-lg p-4 duration-300 h-full col-span-3">
                                        <div className="text-gray-700 text-sm font-semibold">
                                            📁 Folders Distribution
                                        </div>
                                        <div className="flex justify-center items-center h-full">
                                            <div className="flex flex-col justify-between items-center w-full">
                                                <div className="mt-2 mb-2 flex justify-center w-full">
                                                    <PieChart data={data.organisationStatus.rankingFolders} width={300} height={300} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {data.organisationStatus && data.organisationStatus.rankingLabels && Object.keys(data.organisationStatus.rankingLabels).length > 0 && (
                                    <div className="bg-white rounded-2xl shadow-lg p-4 duration-300 h-full col-span-3">
                                        <div className="text-gray-700 text-sm font-semibold">
                                            📁 Labels Distribution
                                        </div>
                                        <div className="flex justify-center items-center h-full">
                                            <div className="flex flex-col justify-between items-center w-full">
                                                <div className="mt-2 mb-2 flex justify-center w-full">
                                                    <PieChart data={data.organisationStatus.rankingLabels} width={300} height={300} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </React.Fragment>
                        ))}


                        {filteredAnalyticsData.map((data, index) => (
                            data.sizeAndTimeDistribution && 
                            Object.keys(data.sizeAndTimeDistribution.inboundSize).length > 0 && 
                            Object.values(data.sizeAndTimeDistribution.inboundSize).some(size => size > 0) && (
                                <React.Fragment key={index}>
                                    <div className="bg-white rounded-2xl shadow-lg p-4 duration-300 h-full col-span-2">
                                        <div className="text-gray-700 text-sm font-semibold">
                                            📏 Emails Received by Length
                                        </div>
                                        <div className="flex justify-center items-center h-full">
                                            <div className="flex flex-col justify-between items-center w-full">
                                                <div className="mt-2 mb-4 w-full">
                                                    <HorizontalBarChart
                                                        data={data.sizeAndTimeDistribution.inboundSize}
                                                        firstLabel="Email Length"
                                                        type="Sender"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="bg-white rounded-2xl shadow-lg p-4 duration-300 h-full col-span-1">
                                        <div className="flex justify-center items-center h-full">
                                            <div className="flex flex-col justify-between items-center w-full">
                                                <span className="text-gray-600 text-md text-center my-2">
                                                    Time Spent on Reading Emails
                                                </span>
                                                <div className="flex items-center">
                                                    <span className="text-2xl mx-1">⏱️</span>
                                                    <span className="text-4xl mx-1">{inboundTime}</span>
                                                    <span className="text-sm text-gray-500" style={{ position: 'relative', top: '7px' }}>/day</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        ))}

                        {filteredAnalyticsData.map((data, index) => (
                            data.sizeAndTimeDistribution && 
                            Object.keys(data.sizeAndTimeDistribution.outboundSize).length > 0 && 
                            Object.values(data.sizeAndTimeDistribution.outboundSize).some(size => size > 0) && (
                                <React.Fragment key={index}>
                                    <div className="bg-white rounded-2xl shadow-lg p-4 duration-300 h-full col-span-2">
                                        <div className="text-gray-700 text-sm font-semibold">
                                            📏 Emails Sent by Length
                                        </div>
                                        <div className="flex justify-center items-center h-full">
                                            <div className="flex flex-col justify-between items-center w-full">
                                                <div className="mt-2 mb-4 w-full">
                                                    <HorizontalBarChart
                                                        data={data.sizeAndTimeDistribution.outboundSize}
                                                        firstLabel="Email Length"
                                                        type="Recipient"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="bg-white rounded-2xl shadow-lg p-4 duration-300 h-full col-span-1">
                                        <div className="flex justify-center items-center h-full">
                                            <div className="flex flex-col justify-between items-center w-full">
                                                <span className="text-gray-600 text-md text-center my-2">
                                                    Time Spent on Writing Emails
                                                </span>
                                                <div className="flex items-center">
                                                    <span className="text-2xl mx-1">⏱️</span>
                                                    <span className="text-4xl mx-1">{outboundTime}</span>
                                                    <span className="text-sm text-gray-500" style={{ position: 'relative', top: '7px' }}>/day</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        ))}            
                        
                    </>
                )}
            </div>

            <div className="grid md:grid-cols-2 gap-3 mt-6">
                {filteredAnalyticsData.map((data, index) => (
                    data.scoreComponents && (
                        <React.Fragment key={index}>
                            {choosePlanOrigin ? (
                                <div className="text-gray-700 text-xl font-semibold text-center mt-4 mb-1 col-span-2">
                                    Potential Time Saving with SkimAI ⚡️
                                </div>
                            ) : (
                                <div className="text-gray-800 text-xl font-semibold text-center mt-4 mb-1 col-span-2">
                                    💡 Tips to Boost Your Score
                                </div>
                            )}

                            {getSuggestions(data.scoreComponents, data.sizeAndTimeDistribution.inboundSecondsSpent, data.sizeAndTimeDistribution.outboundSecondsSpent, choosePlanOrigin).map((suggestion, i) => (
                                <div 
                                    key={i} 
                                    className={`bg-white rounded-2xl shadow-lg p-4 duration-300 h-full flex flex-col justify-between ${choosePlanOrigin ? 'col-span-2' : 'col-span-1'}`}
                                >
                                    {!choosePlanOrigin && 
                                        <div className="text-md text-gray-600 p-1">
                                            {suggestion.description}
                                        </div>
                                    }
                                    <div className="flex flex-col items-center w-full mt-5 mb-3">
                                        <div className="flex items-center">
                                            <span className="text-2xl mx-1">⏱️</span>
                                            <span className="text-4xl mr-1">{suggestion.savingPotential}</span>
                                            <span className="text-sm text-gray-500" style={{ position: 'relative', top: '7px' }}>/day</span>
                                        </div>
                                        {/* <span className="text-gray-800 text-sm my-2 mt-3">Potential Time Reduction with SkimAI⚡️</span> */}
                                    </div>

                                    {choosePlanOrigin &&

                                        <div className="text-md text-gray-600 p-1 mb-1">
                                            {suggestion.description}
                                        </div>
                                    }
                                </div>
                            ))}
                        </React.Fragment>
                    )
                ))}
            </div>

        </>
    );    
};
