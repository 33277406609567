import React from 'react';
import { Arc } from '@visx/shape';
import { Group } from '@visx/group';
import { Text } from '@visx/text';

const ScoreChart = ({ score, width = 400, height = 400 }) => {
    const innerRadius = (Math.min(width, height) / 2) - 45;
    const outerRadius = (Math.min(width, height) / 2);
    const centerX = width / 2;
    const centerY = height / 2;

    const backgroundArc = {
        startAngle: 0,
        endAngle: 2 * Math.PI,
    };

    const foregroundArc = {
        startAngle: 0,
        endAngle: (2 * Math.PI) * (score / 100),
    };

    const fontSize = score >= 99.5 ? 120 : 120;
    const textOffsetNumber = 0;

    const emojiPositions = [
        { emoji: '🎉', x: -70, y: 56 },
        { emoji: '🥳', x: 95, y: 102 },
        { emoji: '🎊', x: 0, y: -110 }
    ];

    return (
        <svg width={width} height={height}>
            <defs>
                <linearGradient id="score-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" stopColor="#5864FF" />
                    <stop offset="100%" stopColor="#FBBC04" />
                </linearGradient>
            </defs>
            <Group top={centerY} left={centerX}>
                <Arc
                    {...backgroundArc}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    fill="#E6E6E6"
                />
                <Arc
                    {...foregroundArc}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    fill="url(#score-gradient)"
                    cornerRadius={score > 99.5 ? 0 : 20}
                />
                <text
                    x={-textOffsetNumber}
                    y={10}
                    fontSize={fontSize}
                    fill="#5864FF"
                    textAnchor="middle"
                    alignmentBaseline="middle"
                    style={{ fontFamily: 'Krona One', fontWeight: 'normal' }}
                >
                    {`${Math.round(score)}`}
                </text>
                {score >= 99.5 && emojiPositions.map((emoji, index) => (
                    <text
                        key={index}
                        x={emoji.x}
                        y={emoji.y}
                        fontSize={70}
                        fill="#5864FF"
                        textAnchor="middle"
                        alignmentBaseline="middle"
                        style={{ fontFamily: 'Krona One', fontWeight: 'normal' }}
                    >
                        {emoji.emoji}
                    </text>
                ))}
            </Group>
        </svg>
    );
};

export default ScoreChart;
