import React, { useState } from 'react';
import { Modal, ModalBody, SIZE, ROLE } from "baseui/modal";
import { doc, setDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../config/firebaseConfig.js';
import { v4 as uuidv4 } from 'uuid';

export const ReferFriendView = ({ isOpen, onClose, userId, userEmail, userName, accessToken }) => {
    const [emailList, setEmailList] = useState([]);
    const [emailInput, setEmailInput] = useState('');
    const [isEmailInvalid, setIsEmailInvalid] = useState(false);

    const handleAddEmail = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailRegex.test(emailInput)) {
            setEmailList([...emailList, emailInput]);
            setEmailInput('');
            setIsEmailInvalid(false);
        } else {
            setIsEmailInvalid(true);
        }
    };

    const handleRemoveEmail = (index) => {
        const newEmailList = emailList.filter((_, i) => i !== index);
        setEmailList(newEmailList);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === ',') {
            e.preventDefault();
            handleAddEmail();
        }
    };

    const saveReferralData = async () => {
        try {
            console.log('Saving referral data to Firebase...');
            const referralData = {
                user_email: userEmail,
                date: Timestamp.now(),
                emails: emailList
            };
            const referralDocRef = doc(db, "referrals", uuidv4());
            await setDoc(referralDocRef, referralData);
            console.log("Referral data saved successfully");
        } catch (error) {
            console.error('Error saving referral data:', error);
        }
    };

    const handleReferNow = async () => {
        // very hacky but works
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailRegex.test(emailInput)) {
            emailList.push(emailInput)
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/send_referral_email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    userid: userId,
                    accessToken: accessToken,
                    userEmail: userEmail,
                    userName: userName,
                    referredEmails: emailList
                })
            });
            const data = await response.json();
            console.log('Response from backend:', data);
            if (data.requiresReauth) {
                console.error('Reauthentication required');
                // handle reauthentication if needed
            } else if (data.success) {
                console.log("Referral emails sent successfully");
                await saveReferralData();
            } else {
                console.error('Error sending referral emails: ', data.error);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        onClose();
    };

    return (
        <Modal
            closeable
            isOpen={isOpen}
            animate
            size={SIZE.default}
            role={ROLE.dialog}
            onClose={onClose}
        >
            <ModalBody>
                <div className='p-4'>
                    <div className='text-2xl mt-2 text-black font-semibold'>📨 Refer a Friend and Save 50%!</div>
                    <div className='min-h-[180px] h-auto'>
                        <div className='text-md mt-6 mb-2'>
                            Enjoy 50% off your subscription for two months by inviting your friends to SkimAI! Once they join and activate their subscription, both of you will benefit from the discount. ✨
                        </div>
                        <div className="mt-4 mb-4">
                            <div className="flex flex-wrap gap-2">
                                {emailList.map((email, index) => (
                                    <div key={index} className="h-8 border text-sm rounded-lg px-2 py-1 mb-0 flex items-center">
                                        <span className="text-gray-800">{email}</span>
                                        <button
                                            className="ml-2 mr-1 text-gray-600 hover:text-red-600"
                                            onClick={() => handleRemoveEmail(index)}
                                        >
                                            ✕
                                        </button>
                                    </div>
                                ))}
                                <div className=''>
                                    <input
                                        type="text"
                                        className={`border rounded-lg px-2 py-1 h-8 w-40 ${isEmailInvalid ? "border-red-300" : "border-gray-300"}`}
                                        placeholder="Add email..."
                                        value={emailInput}
                                        onChange={(e) => setEmailInput(e.target.value)}
                                        onKeyDown={handleKeyDown}
                                    />
                                    {isEmailInvalid &&
                                        <div className='text-xs font-light text-red-400'>Email is invalid</div>
                                    }
                                </div>
                            </div>
                        </div>
                        <button
                            type="button"
                            className={`px-3 py-2 text-sm font-medium text-center inline-flex items-center rounded-lg focus:ring-4 focus:outline-none ${emailList.length === 0 ? 'text-white bg-blue-200' : 'text-white bg-blue-700 hover:bg-blue-800 focus:ring-blue-300'}`}
                            onClick={handleReferNow}
                            disabled={emailList.length === 0}
                        >
                            <svg className="w-5 h-5 mr-2 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"/>
                            </svg>
                            Refer Now
                        </button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};
