import React from 'react';
import { Pie } from '@visx/shape';
import { Group } from '@visx/group';
import { LegendOrdinal, LegendItem, LegendLabel } from '@visx/legend';
import { scaleOrdinal } from '@visx/scale';
import * as d3 from 'd3-scale-chromatic';

const PieChart = ({ data, width = 400, height = 400 }) => {
    const radius = Math.min(width, height) / 2;

    const customPalette = ['#FDD95C', '#8991FF', '#F18084', '#6ABFE8', '#FDD76A', '#66E2B2'];
    
    const d3Palette = d3.schemeCategory10;
    const filteredD3Palette = d3Palette.filter(color => !customPalette.includes(color));
    const extendedPalette = customPalette.concat(filteredD3Palette);

    const colorScale = scaleOrdinal({
        domain: Object.keys(data),
        range: extendedPalette,
    });

    const totalValue = Object.values(data).reduce((acc, value) => acc + value, 0);

    return (
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%' }}>
            <svg width={width} height={height}>
                <Group top={height / 2} left={width / 2}>
                    <Pie
                        data={Object.entries(data)}
                        pieValue={d => d[1]}
                        outerRadius={radius - 10}
                        innerRadius={0}
                    >
                        {pie => pie.arcs.map(arc => {
                            const [centroidX, centroidY] = pie.path.centroid(arc);
                            const { startAngle, endAngle } = arc;
                            const hasSpaceForLabel = endAngle - startAngle >= 0.1;
                            const percentage = ((arc.data[1] / totalValue) * 100).toFixed(1);

                            return (
                                <g key={arc.data[0]}>
                                    <path d={pie.path(arc)} fill={colorScale(arc.data[0])} />
                                    {hasSpaceForLabel && percentage >= 7 && (
                                        <text
                                            x={centroidX}
                                            y={centroidY}
                                            dy=".33em"
                                            fill="white"
                                            fontSize={12}
                                            fontWeight="bold"
                                            textAnchor="middle"
                                        >
                                            {`${percentage}%`}
                                        </text>
                                    )}
                                </g>
                            );
                        })}
                    </Pie>
                </Group>
            </svg>
            <div style={{ marginTop: 20, display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                <LegendOrdinal scale={colorScale} direction="row" labelMargin="0 15px 0 0">
                    {labels => labels.map((label, i) => (
                        <LegendItem key={`legend-${i}`} margin="0 15px 5px 0" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
                            <svg width={10} height={10}>
                                <rect fill={label.value} width={10} height={10} />
                            </svg>
                            <LegendLabel
                                style={{
                                    color: '#6B7280',
                                    margin: '0 0 0 4px',
                                    fontSize: '0.875rem'
                                }}
                            >
                                {label.text}
                            </LegendLabel>
                            <LegendLabel
                                style={{
                                    color: '#6B7280',
                                    fontSize: '0.875rem',
                                    marginLeft: '13px'
                                }}
                            >
                            </LegendLabel>
                        </LegendItem>
                    ))}
                </LegendOrdinal>
            </div>
        </div>
    );
};

export default PieChart;
