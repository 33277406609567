import ReactMarkdown from 'react-markdown';
import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
// import { db, auth, pythonAPIEndpoint} from '../../config/firebaseConfig.js';
import { db, auth } from '../../config/firebaseConfig.js';
import { collection, query, where, getDocs } from "firebase/firestore";
import { doc, updateDoc, getDoc } from 'firebase/firestore'
import { useLocation } from 'react-router-dom';

import { UserContext } from '../../UserContext.js';

import './SingleEmailView.css';

import {
  Checkbox,
  STYLE_TYPE,
  LABEL_PLACEMENT
} from "baseui/checkbox";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE,
  ROLE
} from "baseui/modal";
import { KIND as ButtonKind } from "baseui/button";
import { Button, KIND } from "baseui/button";
import {Delete, Check} from 'baseui/icon';
import { useAutoAnimate } from '@formkit/auto-animate/react'
import ContentEditable from 'react-contenteditable'
import { isDayDisabled } from 'baseui/datepicker/utils/index.js';
import { getAnalytics, logEvent } from '@firebase/analytics';

export const SingleEmailView = ({ isOpen, onClose, emailData, allEmails, userData, onRequireReAuth, onFinishAction, theTrainingData }) => {
    const analytics = getAnalytics();
    const [hasDraft, setHasDraft] = useState(false);
    const [hasSent, setHasSent] = useState(false);
    const [isDrafting, setIsDrafting] = useState(false);
    const [draftEmail, setDraftEmail] = useState("");
    const [parent, enableAnimations] = useAutoAnimate(/* optional config */);
    const [recommendation, setRecommendation] = useState(null)
    const [isDraftOpen, setIsDraftOpen] = useState(null) 
    const [trainingData, setTrainingData] = useState(theTrainingData ?? []);
    const [isHTMLHeavy, setIsHTMLHeavy] = useState(false)
    const debounceTimer = useRef(null);

    // init function
    useEffect(() => {
      // if noreply@ email, skip this to save some money? 
      getRecommendation()

      // TODO: should try to check something else too ... 
      if(emailData.original.originalHTML.includes("</div>")){
        setIsHTMLHeavy(true)
      }

    }, []);

    const markAsRead = async () => {
        onFinishAction(emailData)
        onClose()
        try {
          logEvent(analytics, 'Single View: Draft Clicked');
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/mark_email_read`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                  userid: userData.userId,
                  accessToken: userData.accessToken,
                  emailAddressId: emailData.toEmail,
                  threadId: emailData.threadId,
                  unreadMessageIds: emailData.unreadMessageIds,
              })
          });
          const data = await response.json();
          if (data.requiresReauth) {
            onRequireReAuth(data.email)
          } else if (data.success) {
            // onClose()
            // onFinishAction(emailData)
          } else {
            console.error('Error marking email as read: ', data.error);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };

    const archiveEmail = async () => {
      onFinishAction(emailData)
      onClose()

        try {
          logEvent(analytics, 'Single View: Archive Clicked');
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/archive_email`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                  userid: userData.userId,
                  accessToken: userData.accessToken,
                  emailAddressId: emailData.toEmail,
                  threadId: emailData.threadId,
                  unreadMessageIds: emailData.unreadMessageIds,
              })
          });
          const data = await response.json();
          if (data.requiresReauth) {
            onRequireReAuth(data.email)
          } else if (data.success) {
            // onClose()
            // onFinishAction(emailData)
          } else {
            console.error('Error deleting email: ', data.error);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    }

    const deleteEmail = async () => {
      onFinishAction(emailData)
      onClose()

        try {
          logEvent(analytics, 'Single View: Delete Clicked');
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/delete_email`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                  userid: userData.userId,
                  accessToken: userData.accessToken,
                  emailAddressId: emailData.toEmail,
                  threadId: emailData.threadId,
                  unreadMessageIds: emailData.unreadMessageIds,
              })
          });
          const data = await response.json();
          if (data.requiresReauth) {
            onRequireReAuth(data.email)
          } else if (data.success) {
            // onClose()
            // onFinishAction(emailData)
          } else {
            console.error('Error deleting email: ', data.error);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };

    const getRecommendation = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get_recommended_action`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                userid: userData.userId,
                accessToken: userData.accessToken,
                emailAddressId: emailData.toEmail,
                emailText: emailData.original.originalHTML,
                name: userData.userObject.name,
                trainingData: trainingData
            })
        });
        const data = await response.json();
        // console.log(data.options)
        console.log(JSON.parse(data.options))
        setRecommendation(Object.entries(JSON.parse(data.options)))
        // if (data.result == "success") {
        //   console.log("Email drafted successfully")
        //   setDraftEmail(data.draft)
        //   emailData.draftEmail = {}
        //   emailData.draftEmail["originalHTML"] = data.draft
        //   // emailData.draftEmail["id"] = data.email_output.id // theres no id from the backend ... 

        //   let index = allEmails.findIndex(obj => obj.threadId === emailData.threadId);
        //   allEmails[index] = emailData
        //   // uncomment this to deploy! this is just for debugging
        //   // localStorage.setItem("arrayOfEmailSummary", JSON.stringify(allEmails));

        // } else {
        //   console.error('Error drafting reply: ', data.error);
        // }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } 

    const draftReply = async (goal) => {
      setIsDrafting(true)
      //simplify verison of draft email function 
      // console.log(userData.userObject)
      try {
        logEvent(analytics, 'Single View: Reply Drafted');
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/draft_email`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                userid: userData.userId,
                accessToken: userData.accessToken,
                emailAddressId: emailData.toEmail,
                emailText: emailData.original.originalHTML,
                threadId: emailData.threadId,
                emailLabel: emailData.label,
                userTimeZone: userData.userTimeZone,
                name: userData.userObject.name,
                toneOfVoice: userData.userObject.tone_of_voice ?? "Polite and constructive",
                isCalendarAssistantEnabled: userData.userObject.configuration.isCalendarAssistantEnabled,
                scheduling_tool_link: userData.userObject.scheduling_tool_link,
                email_signature: userData.userObject.email_signature ?? "<br><div>Sent via <a href='http://www.skim.page?utm_source=SkimAI&utm_medium=email&utm_campaign=email_tool'>SkimAI⚡️</a></div>",
                goal: goal,
                trainingData: trainingData
            })
        });
        const data = await response.json();
        // console.log(data.draft)
        if (data.result == "success") {
          console.log("Email drafted successfully")
          setDraftEmail(data.draft)
          emailData.draftEmail = {}
          emailData.draftEmail["originalHTML"] = data.draft
          // emailData.draftEmail["id"] = data.email_output.id // theres no id from the backend ... 

          let index = allEmails.findIndex(obj => obj.threadId === emailData.threadId);
          allEmails[index] = emailData
          // uncomment this to deploy! this is just for debugging
          setIsDrafting(false)
          setIsDraftOpen(false)
          
          localStorage.setItem("arrayOfEmailSummary", JSON.stringify(allEmails));
        } else {
          console.error('Error drafting reply: ', data.error);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    const saveDraft = async (newDraftContent) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/save_draft_email`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            userid: userData.userId,
            accessToken: userData.accessToken,
            emailAddressId: emailData.toEmail,
            newDraft: newDraftContent,
            threadId: emailData.threadId
          })
        });
        const data = await response.json();
        if (!data.success) {
          console.error('Error saving draft: ', data.error);
          return false;
        }
        console.log("Draft saved successfully");
        return true;
      } catch (error) {
        console.error('Error saving draft:', error);
        return false;
      }
    };
    
    const handleChange = (evt, customDebounceTime = 3000) => {
      emailData.draftEmail["originalHTML"] = evt.target.value;
      let index = allEmails.findIndex(obj => obj.threadId === emailData.threadId);
      allEmails[index] = emailData;
      localStorage.setItem("arrayOfEmailSummary", JSON.stringify(allEmails));
    
      clearTimeout(debounceTimer.current);
      debounceTimer.current = setTimeout(() => {
        saveDraft(evt.target.value).then((success) => {
          if (success) {
            console.log('Draft saved after debounce.');
          }
        });
      }, customDebounceTime);
    };

    const sendEmail = async () => {
      onFinishAction(emailData)
      onClose()
      clearTimeout(debounceTimer.current);
      const draftSavedSuccessfully = await saveDraft(emailData.draftEmail?.originalHTML);
      if (!draftSavedSuccessfully) {
        console.log('Could not save draft. Email send aborted.');
        return;
      }
      try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/send_email`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                  userid: userData.userId,
                  accessToken: userData.accessToken,
                  emailAddressId: emailData.toEmail,
                  threadId: emailData.threadId,
                  unreadMessageIds: emailData.unreadMessageIds,
              })
          });
          const data = await response.json();
          if (data.requiresReauth) {
              onRequireReAuth(data.email);
          } else if (data.success) {
              console.log("Email sent successfully");
              setHasSent(true);
              setIsDrafting(false);
          } else {
              console.error('Error sending email: ', data.error);
          }
      } catch (error) {
          console.error('Error fetching data:', error);
      }
    };

    return (
            <Modal
                closeable
                isOpen={isOpen}
                animate
                autoFocus
                size={SIZE.default}
                role={ROLE.dialog}
                onClose={onClose}
            >
            <ModalHeader>{emailData.subject}</ModalHeader>

            <div className="bg-white rounded-xl shadow-lg duration-300 transform hover:shadow-xl mx-4 inline-block rounded-lg p-1 background-animate">
              <div class="block rounded-lg bg-white px-3 py-3 text-sm font-medium text-gray-900 pb-8">
                <div className="text-sm">✨ Summary</div>
                
                {/* {emailData.label === "meeting_request" && 
                  <div className='absolute right-0 text-right pr-3 -mt-6 text-sm flex opacity-100 group-hover:opacity-0 transition-all'>
                    <span class="bg-red-100 text-red-800 border-gray-300 text-xs font-medium px-2 py-1 rounded-md">📆 Meeting Request</span>
                  </div>  
                } */}

                <div className="mt-2" style={{ fontWeight: 600 }}>
                  {emailData.summary}
                </div>

              </div>
            </div>

              <div className="text-center mt-[-28px]" ref={parent}>

                  <div href="#" class="relative mt-2 inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 mr-2 cursor-pointer"
                  // onMouseEnter={() => setIsDraftOpen(true)}
                  onClick={() =>{
                    if(!isDrafting){
                      // draftReplyV2()
                      setIsDraftOpen(!isDraftOpen)
                      logEvent(analytics, 'Single View: Draft Clicked');
                    }
                  }}>
                    {isDrafting ? 
                    <div className="flex justify-center">
                      <div className="animate-bounce mr-2">🏄🏻‍♂️  </div>
                      Drafting ..</div> : "✍🏻 Draft ... "}
                  </div>                  
                  


                  {!isDrafting && 
                  <span 
                    className={`transition-opacity ${isDraftOpen ? 'opacity-30' : 'opacity-100'} hover:opacity-100`}
                  >
                  <div href="#" class="relative mt-2 inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 mr-2 cursor-pointer"
                    onClick={() => {
                      markAsRead()
                  }}>
                    ✅ Read
                  </div>

                  <div href="#" class="relative mt-2 inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 mr-2 cursor-pointer"
                    onClick={() => {
                      archiveEmail();
                  }}>
                     🗂️ Archive 
                  </div>

                  <div href="#" class="relative mt-2 inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 mr-2 cursor-pointer"
                    onClick={() => {
                      deleteEmail();
                  }}>
                    🗑️ Trash 
                  </div>
                  </span>

                  }
              </div>            

              {/* <div className="bg-white rounded-xl shadow-lg p-4 duration-300 transform hover:shadow-xl border mx-4 mt-4">

              </div> */}
              <div className="" ref={parent}>
                {recommendation == null && isDraftOpen && 
                <div className='text-center'>
                  <div href="#" class="mt-2 relative inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 mr-2 cursor-pointer"
                  >
                      Loading ... 
                  </div>  
                </div>

                }
                { recommendation != null && isDraftOpen && !isDrafting &&
                    <div className="text-center pl-4 pr-2">
                      {recommendation.map(([key, value]) => (
                        <div href="#" class="mt-2 relative inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 mr-2 cursor-pointer"
                        onClick={() =>{
                          if(!isDrafting){
                            draftReply(value)
                          }
                        }}>
                            {value}
                        </div>               

                      ))}

                        <div href="#" class="mt-2 relative inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 mr-2 cursor-pointer"
                        onClick={() =>{
                          if(!isDrafting){
                            draftReply("reply with neutral tone")
                          }
                        }}>
                            ⚡️ I'm feeling lucky
                        </div>  
                    </div>                  
                }
              </div>


            <div ref={parent}>
              {emailData.draftEmail != "" &&  !hasSent &&
                <div className="bg-white rounded-xl shadow-lg p-4 duration-300 transform hover:shadow-xl border mx-4 mt-4">
                  <div className="text-sm mb-3 force-black">✍🏻 Draft</div>
                  <div className="text-sm mb-3 force-black">To: {emailData.fromEmail}</div>
                  <ContentEditable
                    disabled={false}  
                    className="mt-2 whitespace-pre-line force-black"
                    html={emailData.draftEmail?.originalHTML} // innerHTML of the editable div
                    onChange={handleChange} // handle innerHTML change
                    tagName='article' // Use a custom HTML tag (uses a div by default)
                  />

              <div className="flex justify-between items-center">
                <div className='text-xs mt-5 text-gray-400'>💡 Tips: You can customize your writing style in Settings > Your Writing Style.</div>
                <div
                  href="#"
                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    if (emailData.type !== "imap") {
                      sendEmail();
                    }
                  }}
                >
                  📤 Send {emailData.type === "imap" ? "(coming soon)" : ""}
                </div>
              </div>

                </div>            
              }
            </div>

              <div className="email-content bg-white rounded-xl shadow-lg p-4 mt-4 duration-300 transform hover:shadow-xl border mx-4">
              <div className='absolute text-xs right-4 text-gray text-slate-400'> {emailData.date}</div>
                <div className="text-sm mb-2 force-black">✉️ Original email</div>
                <div className="text-xs force-black">From: {emailData.fromEmail} </div>
                <div className="text-xs mb-4 force-black">To: {emailData.toEmail} </div>

                {!isHTMLHeavy && 
                  <div className="overflow-scroll force-black whitespace-pre-line" dangerouslySetInnerHTML={{__html:emailData.original?.originalHTML}}>
                  </div>                
                }

                {isHTMLHeavy && 
                  <div className="overflow-scroll force-black" dangerouslySetInnerHTML={{__html:emailData.original?.originalHTML}}>
                  </div>                
                }

                
              </div>

            <ModalFooter>
            </ModalFooter>
            </Modal>
    )
}