import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import { Tabs, Tab } from "baseui/tabs-motion";
import './TutorialView.css';
import {
  Modal,
  ModalBody,
  SIZE,
  ROLE
} from "baseui/modal";
import { db } from '../../config/firebaseConfig.js';
import { doc, updateDoc, getDoc } from 'firebase/firestore'


export const TestEmailView = ({ isOpen, onClose, userData, labelData}) => {

    // Dummy email list with default status
    const initialEmailList = [
        { title: 'Welcome to our service!', status: 'good' },
        { title: 'Your account has been activated', status: 'good' },
        { title: 'Reset your password', status: 'good' },
        { title: 'Special offer just for you', status: 'good' },
        { title: 'Your monthly newsletter', status: 'good' },
        { title: 'Confirm your email address', status: 'good' },
        { title: 'Thank you for your purchase', status: 'good' },
        { title: 'Your order has been shipped', status: 'good' },
        { title: 'Invitation to our event', status: 'good' },
        { title: 'Feedback request', status: 'good' }
    ];

    const [emailList, setEmailList] = useState(initialEmailList);

    useEffect(() => {
        if (isOpen) {
            console.log("now run the email testing! calling backend");
        }
    }, [isOpen]);

    const handleMarkEmail = (index, status) => {
        const updatedEmailList = emailList.map((email, i) =>
            i === index ? { ...email, status } : email
        );
        setEmailList(updatedEmailList);
        console.log(`Marked email ${index} as ${status}`, updatedEmailList);
    };

    return (
        <Modal
            closeable
            isOpen={isOpen}
            animate
            size={SIZE.default}
            role={ROLE.dialog}
            onClose={onClose}
        >
             <ModalBody>
                <div className="p-4">
                    <div className="text-lg font-semibold mb-4">{labelData.name}</div>
                    <div className="text-sm text-gray-500 mb-4">{userData.userId}</div>
                    <div>
                        {emailList.map((email, index) => (
                            <div key={index} className="mb-4 p-4 border rounded-lg shadow-sm">
                                <div className="text-base font-medium mb-2">{email.title}</div>
                                <div className="flex justify-end space-x-2">
                                    <button
                                        onClick={() => handleMarkEmail(index, 'good')}
                                        className={`font-bold py-2 px-4 rounded ${email.status === 'good' ? 'bg-blue-500 hover:bg-blue-700 text-white' : 'bg-blue-500 text-white opacity-50'}`}
                                    >
                                        Good
                                    </button>
                                    <button
                                        onClick={() => handleMarkEmail(index, 'bad')}
                                        className={`font-bold py-2 px-4 rounded ${email.status === 'bad' ? 'bg-red-500 hover:bg-red-700 text-white' : 'bg-red-500 text-white opacity-50'}`}
                                    >
                                        Bad
                                    </button>
                                </div>
                                <div className="text-sm text-gray-500 mt-2">Current status: {email.status}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}