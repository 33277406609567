import React, { useEffect, useState, useContext } from 'react';
import { Button, SIZE } from 'baseui/button';
import { useAuthState } from "react-firebase-hooks/auth";
import { doc, updateDoc } from "firebase/firestore";
import { auth, db } from '../../config/firebaseConfig.js';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/logo-blue.png';
import logo_v2 from '../../assets/logo-v2.png';
import report_img from '../../assets/report-1.png';

import sparkle from '../../assets/pricing-decoration.png';

import { Card, StyledBody, StyledAction } from 'baseui/card';
import { ConnectEmailView } from './../Email/ConnectEmailView.js';

import './ChoosePlan.css'; // Import the new CSS file
import { collection, query, where, getDocs } from "firebase/firestore";
import {loadStripe} from '@stripe/stripe-js';
import { getAnalytics, logEvent } from '@firebase/analytics';
import { useLocation } from 'react-router-dom';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

import { UserContext } from '../../UserContext.js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function ChoosePlan() {
  const analytics = getAnalytics();
  const location = useLocation();

  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const [clientSecret, setClientSecret] = useState('');
  const [isLoading, setIsLoading] = useState(false)
  const [customerEmail, setCustomerEmail] = useState('');
  const [userObject, setUserObject] = useState(false);
  const { userId, setUserId } = useContext(UserContext);
  const [isConnectEmailOpen, setIsConnectEmailOpen] = useState(false);
  const [stripeCustomerId, setStripeCustomerId] = useState('');

  useEffect(() => {
    logEvent(analytics, 'chooseplan_view', {
        firebase_screen: location.pathname,
        firebase_screen_class: 'firebase-routes-analytics', 
    });
  }, [location]);

  useEffect(() => {
    const getData = async () =>  {
      if(user){
        const q = query(collection(db, "users"), where("uid", "==", user?.uid));
        console.log(user)
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          const userData = doc.data();
          setUserId(doc.id);
          if(userData.subscription_active && userData.stripe_customer_id){
            navigate("/email")
            return;
          }
          setCustomerEmail(userData.email);
          setUserObject(userData);
          setStripeCustomerId(userData.stripe_customer_id || '');
        });
      }
    }
    getData()
  }, [user]);

  const redirectToStripe = (url) => {
    window.location.href = url + "?prefilled_email=" + user?.email;
  };

  const containerStyle = {
    opacity: isLoading ? 0.5 : 1,
    pointerEvents: isLoading ? 'none' : 'auto',
  };

  const handleEmailConnectionAction = (email_type) => {
    initiateConnection(email_type)
    // initiateAuth(email_type);
  }

  const initiateConnection = (connectionType) => {

    logEvent(analytics, `Email View: Connect ${connectionType} Clicked`);

    if (user && userId) {
        setUserId(userId);
        localStorage.setItem('userId', userId);
        localStorage.setItem('authType', connectionType);
        localStorage.setItem('fromPath', location.pathname);
        initiateAuth(connectionType);
    } else {
        console.error('User is not authenticated');
    }
  };

  const initiateAuth = async (type) => {
    try {
        // Construct the URL with the type parameter
        const requestUrl = `${process.env.REACT_APP_BACKEND_URL}/initiate_auth?type=${encodeURIComponent(type)}`;

        const authResponse = await fetch(requestUrl);
        const authData = await authResponse.json();
        const authUrl = authData.authUrl;

        // Redirect to the authUrl
        window.location.href = authUrl;
    } catch (error) {
        console.error(`Error initiating ${type} authentication:`, error);
    }
  };

  const handleSubscription = (priceId) => {
    if (isLoading) return;
  
    setIsLoading(true);
    logEvent(analytics, 'tariff_button_click', {
      plan: priceId === process.env.REACT_APP_STRIPE_MONTHLY_PID ? 'Monthly' : 'Yearly',
      price: priceId === process.env.REACT_APP_STRIPE_MONTHLY_PID ? '$4.99' : '$49.99'
    });
  
    fetch(`${process.env.REACT_APP_BACKEND_URL}/create-checkout-session`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_uid: user?.uid,
        accessToken: user?.accessToken,
        price_id: priceId,
        email: customerEmail,
        stripe_customer_id: stripeCustomerId
      }),
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then(async err => {
            if (err.error === "Customer already has an active subscription") {
              navigate('/email');
            } else {
              throw new Error(err.error);
            }
          });
        }
        return res.json();
      })
      .then((data) => {
        setClientSecret(data.clientSecret);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("Subscription error:", err.message);
        setIsLoading(false);
      });
  }

  return (
    <>


{!clientSecret && (
        <div class="pt-12 md:pt-20">
          <div className="welcome-container">
            <img src={logo_v2} alt="Logo" className="welcome_logo"/>
          </div>
            <div class="max-w-6xl mx-auto px-4 sm:px-6">
            
            <div class="relative max-w-3xl mx-auto text-center pb-12">
              <h2 class="font-inter-tight text-3xl md:text-4xl font-bold text-zinc-900 mb-4">Hire Your Email Copilot Today.</h2>
                {/* <p class="text-lg text-zinc-500">Special Deal Alert: Enjoy a <span class="font-bold underline">50% Discount</span> for a Limited Time! Secure our best price plan now before prices increase.</p> */}
            </div>

                <div class="pb-12 md:pb-20 lg:w-2/3 mx-auto">

                    <div class="max-w-sm mx-auto grid gap-6 md:grid-cols-2 items-start md:max-w-none">
                
                        <div class="h-full">
                            <div class="relative flex flex-col h-full p-6 rounded-lg border shadow-xl">
                                <div class="mb-4">
                                    <div class="text-lg text-zinc-900 font-semibold mb-1">Pro</div>
                                    {/* <div class="text-zinc-900 text-md line-through">$9.99</div> */}

                                    <div class="font-inter-tight inline-flex items-baseline mb-2">
                                        <span class="text-zinc-900 font-bold text-2xl">$4.99</span>
                                        <span class="text-zinc-900 font-bold text-3xl"></span>
                                        <span class="text-zinc-500 font-medium">/month</span>
                                    </div>
                                    <div class="text-zinc-500">Ideal for users with a single email account who receive more than 50 emails per day.</div>
                                </div>
                                <div class="grow">
                                    <div class="text-sm text-zinc-900 font-medium mb-4">Includes:</div>
                                    <ul class="text-zinc-600 dark:text-zinc-400 text-sm space-y-3 grow">
                                        <li class="flex items-center">
                                            <svg class="w-3 h-3 fill-emerald-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                            </svg>
                                            <div
                                                class="relative"
                                                x-data="{ open: false }"
                                            >
                                                <button
                                                    class="block text-left text-zinc-500 underline decoration-dotted decoration-zinc-300 underline-offset-4"
                                                    aria-describedby="tooltip-01"      
                                                >
                                                    Unlimited Automations
                                                </button>
                                            </div>
                                        </li>
                                        <li class="flex items-center">
                                            <svg class="w-3 h-3 fill-emerald-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                            </svg>
                                            <div
                                                class="relative"
                                                x-data="{ open: false }"
                                            >
                                                <button
                                                    class="block text-left text-zinc-500 underline decoration-dotted decoration-zinc-300 underline-offset-4"
                                                    aria-describedby="tooltip-02"       
                                                >
                                                    Unlimited Email Processing
                                                </button>
                                            </div>
                                        </li>
                                        <li class="flex items-center">
                                            <svg class="w-3 h-3 fill-emerald-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                            </svg>
                                            <div
                                                class="relative"
                                                x-data="{ open: false }"
                                            >
                                                <button
                                                    class="block text-left text-zinc-500 underline decoration-dotted decoration-zinc-300 underline-offset-4"
                                                    aria-describedby="tooltip-03"          
                                                >
                                                    Automated Draft Creation
                                                </button>
                                            </div>
                                        </li>
                                        <li class="flex items-center">
                                            <svg class="w-3 h-3 fill-emerald-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                            </svg>
                                            <div
                                                class="relative"
                                                x-data="{ open: false }"
                                            >
                                                <button
                                                    class="block text-left text-zinc-500 underline decoration-dotted decoration-zinc-300 underline-offset-4"
                                                    aria-describedby="tooltip-04"       
                                                >
                                                    Smart Labels Categorization
                                                </button>
                                            </div>
                                        </li>
                                        <li class="flex items-center">
                                            <svg class="w-3 h-3 fill-emerald-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                            </svg>
                                            <div
                                                class="relative"
                                                x-data="{ open: false }"
                                            >
                                                <button
                                                    class="block text-left text-zinc-500 underline decoration-dotted decoration-zinc-300 underline-offset-4"
                                                    aria-describedby="tooltip-05"   
                                                >
                                                    Standard Support
                                                </button>
                                            </div>
                                        </li>                                                                                                                                                                                               
                                    </ul>
                                </div>
                                <div class="mt-8">
                                    {/* <div class="text-center text-xs font-bold mb-2">
                                        7-day free trial
                                    </div> */}
                                    <div class="btn text-zinc-100 bg-gradient-to-r from-zinc-700 to-zinc-900 hover:from-zinc-900 hover:to-zinc-900 w-full shadow cursor-pointer" 
                                      onClick={() => handleSubscription(process.env.REACT_APP_STRIPE_MONTHLY_PID)}
                                      >
                                      {isLoading ? "Loading ..." : "Start Pro Plan"}
                                       {/* Start Trial - Pro */}
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                
                        <div class="h-full">
                            <div class="relative flex flex-col h-full p-6 rounded-lg bg-zinc-800 shadow-xl">
                                <img class="absolute right-6 -top-5 mix-blend-exclusion" src={sparkle} alt="Pricing decoration" width="76" height="74" aria-hidden="true"/>
                                <div class="mb-4">
                                    <div class="text-lg text-zinc-200 font-semibold mb-1">Premium</div>
                                    {/* <div class="text-zinc-200 text-md line-through">$99.99</div> */}

                                    <div class="font-inter-tight inline-flex items-baseline mb-2">
                                        <span class="text-zinc-200 font-bold text-2xl">$49.99</span>
                                        <span class="text-zinc-200 font-bold text-3xl"></span>
                                        <span class="text-zinc-300 font-medium">/year</span>
                                    </div>
                                    <div class="text-zinc-400">Ideal for power users managing multiple email accounts and receiving over 100 emails daily.</div>
                                </div>
                                <div class="grow">
                                    <div class="text-sm text-zinc-200 font-medium mb-4">Includes:</div>
                                    <ul class="text-zinc-600 dark:text-zinc-400 text-sm space-y-3 grow">
                                        <li class="flex items-center">
                                            <svg class="w-3 h-3 fill-emerald-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                            </svg>
                                            <div
                                                class="relative"
                                                x-data="{ open: false }"
                                            >
                                                <button
                                                    class="block text-left text-zinc-400 underline decoration-dotted decoration-zinc-600 underline-offset-4"
                                                    aria-describedby="tooltip-07"
                                                >
                                                All Pro Plan Features
                                                </button>
                                            </div>
                                        </li>
                                        <li class="flex items-center">
                                            <svg class="w-3 h-3 fill-emerald-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                            </svg>
                                            <div
                                                class="relative"
                                                x-data="{ open: false }"
                                            >
                                                <button
                                                    class="block text-left text-zinc-400 underline decoration-dotted decoration-zinc-600 underline-offset-4"
                                                    aria-describedby="tooltip-08" 
                                                >
                                                    24/7 Support
                                                </button>
                                            </div>
                                        </li>
                                        <li class="flex items-center">
                                            <svg class="w-3 h-3 fill-emerald-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                            </svg>
                                            <div
                                                class="relative"
                                                x-data="{ open: false }"
                                            >
                                                <button
                                                    class="block text-left text-zinc-400 underline decoration-dotted decoration-zinc-600 underline-offset-4"
                                                    aria-describedby="tooltip-09"
                                                >
                                                    Free Setup & Onboarding
                                                </button>
                                            </div>
                                        </li>
                                        <li class="flex items-center">
                                            <svg class="w-3 h-3 fill-emerald-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                            </svg>
                                            <div
                                                class="relative"
                                                x-data="{ open: false }"
                                            >
                                                <button
                                                    class="block text-left text-zinc-400 underline decoration-dotted decoration-zinc-600 underline-offset-4"
                                                    aria-describedby="tooltip-10"
                                                >
                                                    Free Inbox Analysis
                                                </button>
                                            </div>
                                        </li>                                 
                                    </ul>
                                </div>
                                <div class="mt-8">
                                    {/* <div class="text-white text-center text-xs font-bold mb-2">
                                        7-day free trial
                                    </div> */}
                                    <div class="btn text-zinc-600 bg-white hover:text-zinc-900 w-full shadow cursor-pointer"
                                      onClick={() => handleSubscription(process.env.REACT_APP_STRIPE_YEARLY_PID)}
                                    >
                                      {isLoading ? "Loading ..." : "Start Premium Plan"}
                                        {/* Start Trial - Premium */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                  <div className='text-sm text-center mt-4 text-slate-400 mb-4'>
                    {/* Enjoy a 7-day money-back guarantee. You will be charged upon activation. If unsatisfied, cancel within the first 7 days and email us to start the refund process. */}
                    You will be charged upon activation.
                    Do you have questions? Reach out to us at{" "}
                    <a href='mailto:support@skim.page' className='underline'>support@skim.page</a>
                    {""}.
                  </div>

                  <div class="flex justify-center mt-4">
                    <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      onClick={() => {
                        navigate('/email');
                      }}>
                      <svg class="w-6 h-6 text-white me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14M5 12l4-4m-4 4 4 4"/>
                      </svg>
                      Back to Home
                    </button>
                  </div>

                </div>
            </div>
      </div>
    )}

      {clientSecret && (
        <div class="flex flex-col h-screen">
        <div class="md:hidden flex justify-center items-center py-4">
          <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          onClick={()=>{
            setClientSecret('')
          }}>
          <svg class="w-6 h-6 text-white me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14M5 12l4-4m-4 4 4 4"/>
          </svg>
        Back to Subscription Plans
        </button>
        </div>

        <div class="flex-1 flex items-center justify-center">
          <div class="w-3/4">
            <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret }}>
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          </div>
        </div>

        <div class="hidden md:block fixed bottom-0 left-0 p-4">
          <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          onClick={()=>{
            setClientSecret('')
          }}>
          <svg class="w-6 h-6 text-white me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14M5 12l4-4m-4 4 4 4"/>
          </svg>
        Back to Subscription Plans
        </button>
        </div>
      </div>
      )}
    </>
  );
}

export default ChoosePlan;