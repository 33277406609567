import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import { doc, setDoc, updateDoc, getDoc, deleteDoc, onSnapshot,Timestamp } from 'firebase/firestore'
import { collection, query, where, getDocs } from "firebase/firestore";
import { db, auth, pythonAPIEndpoint} from '../../config/firebaseConfig.js';
import { Tabs, Tab } from "baseui/tabs-motion";
import { v4 as uuidv4 } from 'uuid';
import './TrainingDataView.css';
import {
  Modal,
  ROLE
} from "baseui/modal";
import { useAutoAnimate } from '@formkit/auto-animate/react'
import ContentEditable from 'react-contenteditable'
import { ProgressBar, SIZE } from "baseui/progress-bar";


export const TrainingDataView = ({ isOpen, onClose, userData,theTrainingData, onUpdateTrainingData, isSubscriptionActive}) => {
    //const currentUser = firebase.auth().currentUser;
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [trainingData, setTrainingData] = useState(theTrainingData ?? []);
    const [url, setUrl] = useState('');
    const [isAddingData, setIsAddingData] = useState(false)
    const [isSingleDataMode, setIsSingleDataMode] = useState(false)
    const [selectedData, setSelectedData] = useState({})
    const [isAddViaTextModalOpen, setIsAddViaTextModalOpen] = useState(false)
    const [isAddViaUrlModalOpen, setIsAddViaUrlModalOpen] = useState(false)
    const [trainingText, setTrainingText] = useState('')
    const [parent, enableAnimations] = useAutoAnimate(/* optional config */)
    const [isValidUrl, setIsValidUrl] = useState(true); // Initially assume URL is valid
    const [selectedIndex, setSelectedIndex] = useState(null); // Initially assume URL is valid
    const [trainingUsedPercentage, setTrainingUsedPercentage] = useState(0);
    const [charUsed, setCharUsed] = useState(0);
    const [isURLError, setIsURLError] = useState(false); // Initially assume URL is valid

    const charLimit = 40000;

    const addTrainDataUrl = async (url) => {
        if(!validateUrl(url)){
            setIsValidUrl(false)
            return
        }

        setIsValidUrl(true)        
        setIsAddingData(true)
        setIsURLError(false)
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/train_data`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    url: url,
                    userid: userData.userId,
                    accessToken: userData?.accessToken,
                })
            });
            const data = await response.json();
            if(data.error){
                setIsAddingData(false)
                setIsURLError(true)
                return;
            }
            var objectToSave = data.data
            var uuid = uuidv4()
            objectToSave["owner"] = userData.user_uid
            objectToSave["uuid"] = uuid
            objectToSave["type"] = "url"
            objectToSave["createdAt"] =  Timestamp.now()
            setTrainingData([data.data,...trainingData])
            await setDoc(doc(db, "training_data",uuid), objectToSave);
            setIsAddingData(false)
            setIsAddViaUrlModalOpen(false)
            updateParentData()
            setUrl("")
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsAddingData(false)
            setIsURLError(true)
        }
    };

    const updateParentData = () => {
        setTrainingData((prevState) => {
            if(onUpdateTrainingData){
                onUpdateTrainingData(prevState)
            }
            return prevState;
          });
    }

    const addTrainDataText = async () => {
        setIsAddingData(true)
        var objectToSave = {}
        var uuid = uuidv4()
        objectToSave["owner"] = userData.user_uid
        objectToSave["uuid"] = uuid
        objectToSave["type"] = "text"
        objectToSave["createdAt"] =  Timestamp.now()
        objectToSave["main_content"] = trainingText
        await setDoc(doc(db, "training_data",uuid), objectToSave);
        setTrainingData([objectToSave,...trainingData])
        setIsAddViaTextModalOpen(false)
        setIsAddingData(false)
        updateParentData()
        setTrainingText("")
    }
    
    const handleInputChange = (event) => {
        setUrl(event.target.value);
    };

    const removeItem = (indexToRemove, event) => {
        event.stopPropagation();
        let objectToRemove = trainingData[indexToRemove]
        deleteDoc(doc(db, "training_data", objectToRemove.uuid));
        // return 
        const updatedList = trainingData.filter((item, index) => index !== indexToRemove);
        setTrainingData(updatedList);
        updateParentData()
    };

    const validateUrl = (inputUrl) => {
        // Regular expression for URL validation
        const urlPattern = new RegExp(
            '^(ftp|http|https):\/\/[^ "]+$'
        );
        return urlPattern.test(inputUrl);
    };

    const updateTrainingData = async (evt) =>{
        // setTrainingData(trainingData)
        trainingData[selectedIndex].main_content = evt.target.value
        setTrainingData(trainingData)
        const trainingRef = doc(db, "training_data", trainingData[selectedIndex].uuid);
        await updateDoc(trainingRef, {
          main_content: evt.target.value
        });

        updateParentData()
    }

    const stripHtmlTags = (html) => {
        if (!html || typeof html !== 'string') return '';
        return html.replace(/<[^>]*>?/gm, '');
      };

    const countCharUsed = () => {

        let totalCount = 0;
        for (let i = 0; i < trainingData.length; i++) {
          const content = trainingData[i].main_content;
          const charCount = content.length;
          totalCount += charCount;
        }

        setCharUsed(totalCount)
        // now calculate the percentage. 
        setTrainingUsedPercentage((totalCount/charLimit*100).toFixed(2))
    }

    useEffect(() => {
        countCharUsed()
    }, [trainingData]);
    
    return (
        <>
            <Modal
                closeable
                isOpen={isOpen}
                animate
                size={SIZE.auto}
                role={ROLE.dialog}
                onClose={onClose}
            >
                {!isSingleDataMode && 
                    <div className='p-4'>
                        <div className='text-xl font-semibold force-black'>🧠 Train SkimAI with Your Data</div>
                        <div className='mb-4 mt-2 font-light force-black'>
                            Power up SkimAI by adding your data-FAQs, pricing, locations—to provide tailored, informed responses. Ensure URLs are publicly accessible.
                            {/* Feed SkimAI your data so that we can accurately address frequent inquiries with informed, personalized drafts. Please make sure that the url is publicly available when adding via url.  */}
                        </div>                        
                        <div class="grid grid-cols-3 gap-4">
                            <div className="group relative flex items-center border-dashed border-2 cursor-pointer block max-w-sm bg-white border border-gray-300 rounded-lg shadow-md overflow-hidden w-full min-h-[200px]">
                                {trainingUsedPercentage < 100 && 
                                <div className="w-full p-4 text-center">
                                    <span className="add-text block">+ Add</span>
                                    <div className="buttons flex flex-col absolute top-0 left-0 w-full h-full items-center justify-center bg-white opacity-0 group-hover:opacity-100 transition-all">
                                    <button
                                        type="button"
                                        className={`px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg ${
                                            isSubscriptionActive ? 'hover:bg-blue-800' : ''
                                        } focus:ring-4 focus:outline-none focus:ring-blue-300 ${
                                            !isSubscriptionActive ? 'opacity-50' : ''
                                        }`}
                                        onClick={() => {
                                            if (isSubscriptionActive) {
                                                setIsAddViaUrlModalOpen(true);
                                            }
                                        }}
                                        disabled={!isSubscriptionActive}
                                    >
                                        <svg
                                        className="w-4 h-4 text-white me-2"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M13.213 9.787a3.391 3.391 0 0 0-4.795 0l-3.425 3.426a3.39 3.39 0 0 0 4.795 4.794l.321-.304m-.321-4.49a3.39 3.39 0 0 0 4.795 0l3.424-3.426a3.39 3.39 0 0 0-4.794-4.795l-1.028.961"
                                        />
                                        </svg>
                                        Add via URL
                                    </button>
                                    <button
                                        type="button"
                                        className={`mt-2 px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg ${
                                            isSubscriptionActive ? 'hover:bg-blue-800' : ''
                                        } focus:ring-4 focus:outline-none focus:ring-blue-300 ${
                                            !isSubscriptionActive ? 'opacity-50' : ''
                                        }`}
                                        onClick={() => {
                                        if (isSubscriptionActive) {
                                            setIsAddViaTextModalOpen(true);
                                        }
                                        }}
                                        disabled={!isSubscriptionActive}
                                    >
                                        <svg
                                        className="w-4 h-4 text-white me-2"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M3 6.2V5h11v1.2M8 5v14m-3 0h6m2-6.8V11h8v1.2M17 11v8m-1.5 0h3"
                                        />
                                        </svg>
                                        Add in plain text
                                    </button>
                                    </div>
                                </div>                                
                                }


                                <div className='absolute inset-x-0 bottom-0'>
                                        {trainingUsedPercentage > 100 && 
                                        <>
                                            <div className='text-xs text-red-600 text-center mb-4'>
                                                Memory full. You can delete some of your unused training data. We plan to add more memory in the future.
                                            </div>
                                        </>
                                        }
                                        <div className='text-xs text-center'> 
                                            🧠 Memory: {trainingUsedPercentage ?? 0 }% used
                                        </div>
                                        <ProgressBar
                                            size={SIZE.large}
                                            value={trainingUsedPercentage}

                                        />
                                    </div>
                            </div>
                            {/* card view */}
                            {trainingData.map((data, index) => (
                            <div
                                ref={parent}
                                key={index}
                                className="flex relative"
                                onMouseEnter={() => setHoveredIndex(index)}
                                onMouseLeave={() => setHoveredIndex(null)}
                            >
                                <div className="cursor-pointer block max-w-sm bg-white border border-gray-300 rounded-lg shadow-md hover:bg-gray-100 overflow-hidden w-full"
                                onClick={() => {
                                    setIsSingleDataMode(true)
                                    // setSelectedData(data)
                                    setSelectedIndex(index)
                                }}
                                >
                                    {data.image && 
                                        <img class="rounded-t-lg h-20 w-full object-cover" src={data.image} alt="" />                                        
                                    }

                                    
                                    <div className='p-4'>
                                    {data.type === "url" && 
                                        <>
                                            <h5 className="mb-1 text-md font-bold tracking-tight text-gray-900 line-clamp-1">
                                                {data.title}
                                            </h5>
                                            <div className='text-xs text-gray-400 truncate'>{data.url}</div>
                                            <p className="text-sm text-gray-700 mt-3 line-clamp-2">
                                                {data.description}
                                            </p>
                                        </>
                                    }

                                    {data.type === "text" && 
                                        <div className='text-sm line-clamp-5 text-ellipsis'>
                                            {stripHtmlTags(data.main_content)}
                                        </div>
                                    }
                                        {hoveredIndex === index && (
                                            <button className="absolute top-0 right-0 rounded-full bg-white w-5 h-5 shadow-md -mt-2 -mr-2 hover:bg-slate-200 z-10"
                                                onClick={(event) => removeItem(index, event)} 
                                            >
                                
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-3 h-3 ml-1"
                                                >
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                                </svg>
                                                    
                                            </button>
                                        )}
                                    </div>                                        
                                    

                                </div>
                            </div>
                            ))}

                        </div>                    
                    </div>
                }

                {isSingleDataMode && 
                    <div className='p-4'>
                        <button type="button" class="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mb-4"
                        onClick={()=> {
                            setIsSingleDataMode(false)
                            // setTrainingData()
                        }}>
                            <svg class="w-3 h-3 me-2 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m15 19-7-7 7-7"/>
                            </svg>
                        Back
                        </button>

                        <ContentEditable
                            disabled={false}  
                            className="mt-2 whitespace-pre-line h-[42rem] overflow-scroll"
                            html={trainingData[selectedIndex].main_content} // innerHTML of the editable div
                            onChange={updateTrainingData} // handle innerHTML change
                            tagName='article' // Use a custom HTML tag (uses a div by default)
                        /> 

                    </div>
                }

                <div className='text-right pr-2 pb-2'>
                    <button type="button" className="mt-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none"
                    onClick={() => {
                        onClose()
                    }}
                    >Done</button>
                </div>
            </Modal>

            {isAddViaTextModalOpen && (
            <Modal
                onClose={() => {
                    setIsAddViaTextModalOpen(false)
                }}
                closeable
                isOpen={isAddViaTextModalOpen}
                animate
                size={SIZE.default}
                role={ROLE.dialog}
            >
                <div className='p-4 flex flex-col'>
                    <div className='text-2xl mt-2 text-black font-semibold'>Add via text</div>
                    <div className='h-70 flex flex-col'>

                        <div
                            className="mt-4 whitespace-pre-wrap text-lg text-black bg-slate-100 p-4 rounded-lg flex-grow"
                            contentEditable="true"
                            disabled={isAddingData}
                            onBlur={(e) => {
                                setTrainingText(e.currentTarget.textContent)
                            }}
                        >
                            {trainingText}
                        </div>

                        <button
                            type="button"
                            className="mt-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 self-end focus:outline-none"
                            disabled={isAddingData}
                            onClick={() => {
                                // console.log(trainingText)
                                addTrainDataText()
                                // now we save it to firebase ...
                            }}
                        >
                            {isAddingData ? "Saving ... " : "Save"}
                        </button>
                    </div>
                </div>

            </Modal>
            )}

            {isAddViaUrlModalOpen && (
            <Modal
                onClose={() => {
                    setIsAddViaUrlModalOpen(false)
                }}
                isOpen={isAddViaUrlModalOpen}
                animate
                size={SIZE.default}
                role={ROLE.dialog}
            >
                <div className='p-4'>
                    <div className='text-xl mt-2 text-black font-semibold'>Add via url</div>
                    <div class="relative mb-4">
                        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                            </svg>
                        </div>
                        <input
                            type="search"
                            id="search"
                            className={`block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 ${
                                isAddingData ? 'opacity-70' : ''
                            }`}
                            placeholder="Paste any URL ..."
                            value={url}
                            onChange={handleInputChange}
                            disabled={isAddingData}
                            required
                        />

                        <button
                            className={`text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 ${
                                isAddingData ? 'opacity-70' : ''
                            }`}
                            disabled={isAddingData}
                            onClick={() => {
                                addTrainDataUrl(url)
                            }}
                        >
                            {isAddingData ? "Adding ... " : "Add URL" }
                        </button>

                    </div>

                    {!isValidUrl && (
                        <p className="text-red-500 text-sm mt-1">
                            Please enter a valid URL.
                        </p>
                    )}

                    {isURLError && (
                        <p className="text-red-500 text-sm mt-1">
                            Whoops. There seems to be something wrong with your URL. Please try again or use adding via text instead. 🙇🏻‍♂️
                        </p>
                    )}
                </div>
            </Modal>
            )}
        </>
    )
}