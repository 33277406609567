import React, { useState, useEffect, useContext, useMemo } from 'react';
import './Integrations.css';
import NavBar from '../NavBar/NavBar.tsx'
import Footer from '../Footer/Footer.tsx' 
import { useAuthState } from 'react-firebase-hooks/auth';
// import { db, auth, pythonAPIEndpoint} from '../../config/firebaseConfig.js';
import { db, auth } from '../../config/firebaseConfig.js';
import { collection, query, where, getDocs } from "firebase/firestore";
import { doc, updateDoc, getDoc } from 'firebase/firestore'
import { useLocation } from 'react-router-dom';

import { UserContext } from '../../UserContext.js';
import { useAutoAnimate } from '@formkit/auto-animate/react'
import { getAnalytics, logEvent } from '@firebase/analytics';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE,
  ROLE
} from "baseui/modal";
import { KIND as ButtonKind } from "baseui/button";
import {
  Checkbox,
  STYLE_TYPE,
  LABEL_PLACEMENT
} from "baseui/checkbox";

function Integrations() {
    const analytics = getAnalytics();

    const [isConnected, setIsConnected] = useState(false);
    const [isDraftEmailChecked, setIsDraftEmailChecked] = useState(false);
    const [isCalendarAssistantChecked, setIsCalendarAssistantChecked] = useState(false);


    const [isEmailConnected, setIsEmailConnected] = useState(false);
    const [isCalendarConnected, setIsCalendarConnected] = useState(false);

    const [userObject, setUserObject] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [user, loading, error] = useAuthState(auth);
    
    const { userId, setUserId } = useContext(UserContext);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');

    const [isConfirmationOpen, setIsConfirmationOpen] = React.useState(false);
    const [itemToDisconnect, setItemToDisconnect] = useState({ type: '', value: '' });
    const [isDisconnectLoading, setIsDisconnectLoading] = React.useState(false);

    useEffect(() => {
      logEvent(analytics, 'screen_view', {
          firebase_screen: location.pathname,
          firebase_screen_class: 'firebase-routes-analytics', 
      });
    }, [location]);

    const updateUserConfiguration = async (key, value) => {
      setIsLoading(true); // Show loading state
  
      try {
        const userDocRef = doc(db, "users", userId);
        const userDoc = await getDoc(userDocRef);
  
        if (userDoc.exists()) {
          const currentConfiguration = userDoc.data().configuration || {};
          const newConfiguration = { ...currentConfiguration, [key]: value };
          
          await updateDoc(userDocRef, { configuration: newConfiguration });
        }
      } catch (error) {
        console.error("Error updating user configuration:", error);
        // Handle the error appropriately
      } finally {
        setIsLoading(false); // Hide loading state
      }
    };

    // Function to fetch updated user data from Firebase
    const fetchUpdatedUserData = async () => {
      if (userId) {
          const userRef = doc(db, "users", userId);
          const userSnap = await getDoc(userRef);

          if (userSnap.exists()) {
              const userData = userSnap.data();
              setIsEmailConnected(userData.tokens?.emailTokens?.length > 0);
              setIsCalendarConnected(userData.tokens?.calendarTokens?.length > 0);
              setIsDraftEmailChecked(userData.configuration?.isDraftEmailEnabled || false);
              setIsCalendarAssistantChecked(userData.configuration?.isCalendarAssistantEnabled || false);
          } else {
              console.error("No such document!");
          }
      }
    };
  
    const handleCheckboxChange = (feature, isChecked) => {
      switch (feature) {
        case 'calendarAssistant':
          setIsCalendarAssistantChecked(isChecked);
          updateUserConfiguration('isCalendarAssistantEnabled', isChecked);
          break;
        case 'draftEmail':
          setIsDraftEmailChecked(isChecked);
          updateUserConfiguration('isDraftEmailEnabled', isChecked);
          break;
        default:
          console.error('Unsupported feature:', feature);
      }
    };
    
    useEffect(() => {
      const getData = async () =>  {
        if(user){
          const q = query(collection(db, "users"), where("uid", "==", user?.uid));
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            const userData = doc.data();
            setUserObject(userData);
            setUserId(doc.id);

            // Check if email tokens are connected
            const emailTokens = userData.tokens?.emailTokens || [];
            setIsEmailConnected(emailTokens.length > 0);
    
            // Check if calendar tokens are connected
            const calendarTokens = userData.tokens?.calendarTokens || [];
            setIsCalendarConnected(calendarTokens.length > 0);

            if(userData.configuration){
              setIsDraftEmailChecked(doc.data().configuration.isDraftEmailEnabled)
              setIsCalendarAssistantChecked(doc.data().configuration.isCalendarAssistantEnabled)
            } else {
              setIsDraftEmailChecked(false)
              setIsCalendarAssistantChecked(false)
            }
          });
        }
      }
      getData()
    }, [code, user]);

    // This function sends a request to your backend to initiate the email connection process
    const triggerBackendAction = async (action, type, value) => {
      if (action !== 'disconnect') {
        console.error(`Unsupported action: ${action}`);
        return;
      }
    
      const endpoint = `${process.env.REACT_APP_BACKEND_URL}/disconnect_google_service`;
      const successMessage = type === 'email' ? "Disconnected from Email" : "Disconnected from Calendar";
      const failureMessage = type === 'email' ? "Failed to disconnect from Email" : "Failed to disconnect from Calendar";
    
      const requestBody = {
        userid: userId,
        accessToken: user.accessToken,
        service: value,
        service_type: type,
      };
    
      try {
        let response = await fetch(endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(requestBody)
        });
    
        let data = await response.json();
    
        // Update the state based on the backend's response.
        setIsConnected(data.connected);
    
        if (data.success) {
          console.log(successMessage);
          
          await fetchUpdatedUserData();
          fetchUserEmails();
        } else {
          console.error(failureMessage, data.error);
        }
      } catch (error) {
        console.error(`Error performing ${action} action for ${type}:`, error);
      } finally {
        
        setIsDisconnectLoading(false);
        setIsConfirmationOpen(false);
      }
    };

    const initiateAuth = async (type) => {
      try {
        // Construct the URL with the type parameter
        const requestUrl = `${process.env.REACT_APP_BACKEND_URL}/initiate_auth?type=${encodeURIComponent(type)}`;

        const authResponse = await fetch(requestUrl);
        const authData = await authResponse.json();
        const authUrl = authData.authUrl;
  
        // Redirect to the authUrl
        window.location.href = authUrl;
      } catch (error) {
        console.error(`Error initiating ${type} authentication:`, error);
      }
    };
  
    const fetchUserEmails = async () => {
      const userRef = doc(db, "users", userId);
      const userSnap = await getDoc(userRef);
      
      if (userSnap.exists()) {
        const userData = userSnap.data();
        setUserObject(userData); // Assuming this contains the email tokens
      } else {
        // Handle the error if the document is not found
        console.error("No such document!");
      }
    };
  
    const initiateConnection = (connectionType) => {

      logEvent(analytics, 'Connect Email Clicked');

      if (user && userId) {
          setUserId(userId);
          localStorage.setItem('userId', userId);
          localStorage.setItem('authType', connectionType);
          localStorage.setItem('fromPath', location.pathname);
          initiateAuth(connectionType);
      } else {
          console.error('User is not authenticated');
      }
    };

    const disconnectItem = (type, item) => {
      setItemToDisconnect({ type, value: item });
      setIsConfirmationOpen(true);
    };

    return (
      <div className="integration-connector-view">
        <NavBar></NavBar>
        
        <div class="flex justify-center my-12 px-6">
          <div className="w-full lg:w-2/3 xl:w-1/2">
            <div className="integrations-container">
              {/* Render legacy tokens if it's an array directly */}
              {Array.isArray(userObject.tokens) && userObject.tokens.map((token, index) => (
                <div key={index} className="integration-tag mr-2">
                  <p className="text-sm">{token.email}</p>
                  <span className="remove-integration" onClick={() => disconnectItem('email', token.email)}>✕</span>
                </div>
              ))}
              {/* Render emailTokens if tokens is an object with emailTokens key */}
              {userObject.tokens && !Array.isArray(userObject.tokens) && Array.isArray(userObject.tokens.emailTokens) && userObject.tokens.emailTokens.map((token, index) => (
                <div key={index} className="integration-tag mr-2">
                  <p className="text-sm">{token.email}</p>
                  <span className="remove-integration" onClick={() => disconnectItem('email', token.email)}>✕</span>
                </div>
              ))}
              <div onClick={() => initiateConnection('google_email')} className="add-integration-button">
                <p className="text-sm text-center">+ Connect Gmail</p>
              </div>
            </div>

            <div className="integrations-container">
              {userObject.tokens && !Array.isArray(userObject.tokens) && Array.isArray(userObject.tokens.calendarTokens) && userObject.tokens.calendarTokens.map((token, index) => (
                <div key={index} className="integration-tag mr-2">
                  <p className="text-sm">{token.email}</p>
                  <span className="remove-integration" onClick={() => disconnectItem('calendar', token.email)}>✕</span>
                </div>
              ))}
              <div 
                onClick={() => initiateConnection('google_calendar')}
                className="add-integration-button">
                  <p className="text-sm text-center">+ Connect Calendar</p>
              </div>
            </div>

            <div className="grid grid-flow-row sm:grid-cols-2 gap-3">

            {
              isEmailConnected && (
                <div className="cursor-pointer bg-white rounded-3xl shadow-lg p-6 duration-300 transform hover:shadow-xl mt-4">
                  <div className="flex items-center">
                    <div className="rounded-lg w-16 h-16 flex-shrink-0">
                      <p className="text-5xl">✍🏻</p>
                      {/* <img src="{{job.imgUrl}}" alt="Profile Image" className="shadow-lg rounded-lg"> */}
                    </div>
                    <div className="ml-4">
                      <h2 className="text-xl font-semibold">Draft Email Assistant</h2>
                    </div>
                    <div className="flex-grow"></div>
                    <div className="mb-9">
                      <Checkbox
                        checked={isDraftEmailChecked}
                        disabled={!isEmailConnected}
                        checkmarkType={STYLE_TYPE.toggle}
                        onChange={e => handleCheckboxChange('draftEmail', e.target.checked)}
                        labelPlacement={LABEL_PLACEMENT.right}
                      />
                    </div>
                  </div>
                  <div className="mb-6">
                    <p className="text-gray-700 leading-relaxed line-clamp-3">
                      Let SkimAI continuously scan your unread emails, prioritize them, and save reply drafts in your mailbox.
                    </p>
                  </div>
                </div>
              )
            }

            { 
              isCalendarConnected && (
                <div className="cursor-pointer bg-white rounded-3xl shadow-lg p-6 duration-300 transform hover:shadow-xl mt-4">
                  <div class="flex items-center">
                    <div class="rounded-lg w-16 h-16 flex-shrink-0">
                      <p className="text-5xl">📆</p>
                      {/* <img src="{{job.imgUrl}}" alt="Profile Image" class="shadow-lg rounded-lg"> */}
                    </div>
                    <div class="ml-4">
                      <h2 class="text-xl font-semibold">Meeting Scheduler</h2>
                    </div>
                    <div class="flex-grow"></div> 
                    <div class="mb-9">
                      <Checkbox
                          checked={isCalendarAssistantChecked}
                          disabled={!isCalendarConnected}
                          checkmarkType={STYLE_TYPE.toggle}
                          onChange={e => handleCheckboxChange('calendarAssistant', e.target.checked)}
                          labelPlacement={LABEL_PLACEMENT.right}
                      />                  
                    </div>

                  </div>
                  <div class="mb-6">
                    <p class="text-gray-700 leading-relaxed line-clamp-3">
                    Whenever an inbound email requires it, SkimAI will find available time slots and draft meeting invites.
                    </p>
                  </div>
                </div>
              )
            }
            </div>
            {/* TODO: send to stripe customer portal */}
            <button type="button" class="mt-6 text-gray-900 bg-white hover:bg-gray-100 border border-gray-300 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 me-2 mb-2">
              <svg class="w-6 h-6 me-3 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="square" stroke-linejoin="round" stroke-width="2" d="M10 19H5a1 1 0 0 1-1-1v-1a3 3 0 0 1 3-3h2m10 1a3 3 0 0 1-3 3m3-3a3 3 0 0 0-3-3m3 3h1m-4 3a3 3 0 0 1-3-3m3 3v1m-3-4a3 3 0 0 1 3-3m-3 3h-1m4-3v-1m-2.1 1.9-.7-.7m5.6 5.6-.7-.7m-4.2 0-.7.7m5.6-5.6-.7.7M12 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
              </svg>
              Manage Subscription ...
            </button>
            {/* <div className='mt-4'> Manage Subscription ... </div> */}
          </div>
        </div>

        <Footer />

        <Modal
          onClose={() => setIsConfirmationOpen(false)}
          closeable
          isOpen={isConfirmationOpen}
          animate
          autoFocus
          size={SIZE.default}
          role={ROLE.dialog}
        >
          <ModalHeader>Remove connection</ModalHeader>
          <ModalBody>
            Are you sure you want to remove the connection to {itemToDisconnect.value}?
          </ModalBody>
          <ModalFooter>
            <ModalButton kind={ButtonKind.tertiary} onClick={() => setIsConfirmationOpen(false)}>
              Cancel
            </ModalButton>
            <ModalButton
              isLoading={isDisconnectLoading}
               onClick={() => {
                setIsDisconnectLoading(true)
                triggerBackendAction('disconnect', itemToDisconnect.type, itemToDisconnect.value);
               }}
            >
              Remove
            </ModalButton>
          </ModalFooter>
        </Modal>
      </div>
    );
}

export default Integrations;