// src/components/Footer/Footer.tsx
import React from 'react';
import './Footer.css';

function Footer({ openVideoModal, openReferModal }) {
    
    return (
    <div className="fixed bottom-0 mb-4 z-10 w-full flex px-4 justify-between">
        <div className="flex">
            <a
                href="https://skimai.canny.io/feature-requests"
                target="_blank"
                rel="noopener noreferrer"
                className="text-sm mr-2 border font-medium text-gray-800 bg-white rounded-lg hover:bg-gray-100 py-2 px-4 shadow flex items-end"
            >
                🤗 Share Feature Ideas
            </a>

            <button
                className="text-sm mr-2 border font-medium text-gray-800 bg-white rounded-lg hover:bg-gray-100 py-2 px-4 shadow flex items-end"
                onClick={openVideoModal}
            >
                🎥 Watch Tutorials
            </button>
        </div>

        <button
            className="text-sm mr-2 border font-medium text-gray-800 bg-white rounded-lg hover:bg-gray-100 py-2 px-4 shadow ml-auto"
            onClick={openReferModal}
        >
            📢 Refer a Friend and Save 50%
        </button>
    </div>
    );
}

export default Footer;
