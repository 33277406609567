import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";
// import { auth, pythonAPIEndpoint } from '../../config/firebaseConfig';
import { auth } from '../../config/firebaseConfig';
import { Input } from 'baseui/input';
import { Button, SIZE } from 'baseui/button';
import logo from "../../assets/logo-blue.svg";
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from 'baseui/modal';
import './InviteCode.css'; // Use the same CSS file as Auth

function InviteCode() {
  const [code, setCode] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const [isChecking, setIsChecking] = useState(false);
  const [shouldPopupPaid, setShouldPopupPaid] = useState(false);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  const redeemCode = () => {
    setIsChecking(true);
    setErrorMessage("");
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userid: user?.uid, code: code })
    };
    // fetch(pythonAPIEndpoint + '/invitecode', requestOptions)
    fetch(`${process.env.REACT_APP_BACKEND_URL}/invitecode`, requestOptions)
      .then(response => response.json())
      .then(response => {
        setIsChecking(false);
        if (response.result === "success") {
          navigate("/email");
        } else {
          setErrorMessage(response.message || "An error occurred");
        }
      })
      .catch(error => {
        setIsChecking(false);
        setErrorMessage("Failed to connect to the server.");
      });
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const resultParam = params.get('status');
    if (resultParam === 'paid') {
      setShouldPopupPaid(true);
      navigate("/invitecode");
    }
  }, [user, loading, navigate]);

  function close() {
    setShouldPopupPaid(false);
  }

  return (
    <div className="invite">
      <Modal onClose={close} isOpen={shouldPopupPaid}>
        <ModalHeader>We are processing your payment</ModalHeader>
        <ModalBody>
          Thank you for purchasing SkimAI Pro. We will process your payment shortly, and you should soon be able to enjoy unlimited access to SkimAI.
        </ModalBody>
        <ModalFooter>
          <ModalButton kind="tertiary" onClick={close}>Cancel</ModalButton>
          <ModalButton onClick={close}>Done</ModalButton>
        </ModalFooter>
      </Modal>

      <div className="invite__container">
        <div className="welcome-container">
          {/* <span className="welcome-text">Welcome to</span> */}
          <img src={logo} alt="Logo" className="welcome_logo"/>
        </div>

        <Input
          value={code}
          onChange={e => setCode(e.target.value)}
          placeholder="Invite Code"
          clearOnEscape
        />
        <br></br>

        {/* Display error message if any */}
        {errorMessage && <div className="error-message">{errorMessage}</div>}

        <Button
          onClick={redeemCode}
          disabled={isChecking}
          size={SIZE.large}
          overrides={{
            BaseButton: {
              style:({
                marginBottom: '14px',
              }),
            },
          }}
        >
          {isChecking ? "Checking..." : "Redeem Code"}
        </Button>

        <div className="info-text">
          Looking for an invite code? Tweet at <a href="https://twitter.com/pungme" target="blank">@pungme</a> or <a href="https://twitter.com/originalmatth" target="blank">@originalmatth</a>.
        </div>

        {/* <a href="/chooseplan" className="link-text">Or pay now to get early access</a> */}
      </div>
    </div>
  );
}

export default InviteCode;
