import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import { Tabs, Tab } from "baseui/tabs-motion";
import './ConnectEmailView.css';
import {
  Modal,
  ModalBody,
  SIZE,
  ROLE
} from "baseui/modal";
import { db } from '../../config/firebaseConfig.js';
import { doc, updateDoc, getDoc } from 'firebase/firestore'
import { collection, query, where, onSnapshot, Timestamp, setDoc,getDocs } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';

import onboard_1 from "../../assets/onboard-1.png"
import onboard_2 from "../../assets/onboard-2.png"
import onboard_automation from "../../assets/onboard-automation.png"
import { SmartLabelView } from './SmartLabelView.js'


export const OnboardView = ({ isOpen, onClose,userData, onUpdateAutomaticDraft, onEnableSmartLabel,onEnableAutomation, onUpdateLabels}) => {

  const [step,setSteps] = useState(1);
  const [isNewUser, setIsNewUser] = useState(false)
  // shit code but works
  useEffect(() => {
    console.log(userData)
    const fetchData = () => {
      const q = query(collection(db, "automations"), where("owner", "==", userData.user_uid));

      return onSnapshot(q, (snapshot) => {
        const tempAutomationData = [];
        console.log(snapshot.docs)

        if(snapshot.docs.length === 0){
          //empty 
          console.log("new user! creating default automation ...") 
          setIsNewUser(true)
          // createAutomation(true)
        }
        // else do nothing
        // setSelectedAutomation(tempAutomationData[0]); // Update the selected automation if needed
        // selectAutomation(automations[0])
      });
    };

    // Subscribe to updates
    const unsubscribe = fetchData();

    // Unsubscribe when component unmounts or when you no longer need updates
    // For example, in a cleanup function or in a useEffect cleanup
    return () => unsubscribe();
  }, [userData.user_uid]); 

  // the worst code I've ever written
  const createAutomation = async (enable) => {
    //TODO: sanity check here pls
    let allEmails = userData.userObject.tokens.emailTokens
    var emailsArray = []
    allEmails.forEach((object) => {
      emailsArray.push(object.email)
    });

    var uuid0 = uuidv4()
    const newAutomation0 = {
      enabled: false,
      name: `Promotional Email Filter`,
      owner: userData.user_uid,
      uuid: uuid0,
      emails:emailsArray,
      createdAt: Timestamp.now(),
      catchall:true,
      conditions: [
        {
          type: "if",
          action: "the email is a cold sales attempt and contains keywords like 'offer', 'promotion', 'newsletter'",
        },
        {
          type: "then",
          action:"move_to_bin",
        }
      ]
    };

    var uuid1 = uuidv4()
    const newAutomation1 = {
      enabled: false,
      name: `Meeting Slots Suggestions`,
      owner: userData.user_uid,
      uuid: uuid1,
      emails:emailsArray,
      createdAt: Timestamp.now(),
      catchall:false,
      conditions: [
        {
          type: "if",
          action: "the email mentions a new meeting without a precise date and time",
        },
        {
          type: "then",
          action:"draft",
          useCalendar:true,
          content: {
            body: "Politely accept the opportunity and suggest some slots.",
            to:[]
          }
        }
      ]
    };

    var uuid2 = uuidv4()
    const newAutomation2 = {
      enabled: enable,
      name: `Standard Reply`,
      owner: userData.user_uid,
      uuid: uuid2,
      emails:emailsArray,
      createdAt: Timestamp.now(),
      catchall:false,
      conditions: [
        {
          type: "if",
          action: "An email requires a reply.",
        },
        {
          type: "then",
          action:"draft",
          content: {
            body: "Write a polite reply to the sender.",
            to:[]
          }
        }
      ]
    };
    await setDoc(doc(db, "automations",uuid2), newAutomation2);
    await setDoc(doc(db, "automations",uuid1), newAutomation1);
    await setDoc(doc(db, "automations",uuid0), newAutomation0);
  };

  const updateUserConfiguration = () => {
    // console.log(userData)
    if(!userData.userObject["configuration"]){
        userData.userObject["configuration"] = {}
    }

    userData.userObject["configuration"]["isSyncLabelsToGmailEnabled"] = true
    userData.userObject["configuration"]["isSyncLabelsToIMAPEnabled"] = true

    var keyName = "configuration.isSyncLabelsToGmailEnabled"
    var keyName2 = "configuration.isSyncLabelsToIMAPEnabled"
    const docRef = doc(db, "users", userData.userId);
    updateDoc(docRef, {
      [keyName]: true,
      [keyName2]: true
    }).then(async () => {

      const dataObject = {
          userid: userData.userId,
          accessToken: userData.accessToken,
      };

      try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/sync_smart_label`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(dataObject)
          });

          if (!response.ok) {
              throw new Error('Network response was not ok');
          }

          // we have to sync this as well ...
          const data = await response.json();
          // console.log(data)
          if(data.labels){
            userData.userObject["labels"] = data.labels
          }
          // console.log(userData.userObject["labels"])
          // then save the user data to firebase
          // setLabels(data.labels)
      } catch (error) {
          console.error('Error creating label:', error);
      }
    });
  }

  //TODO: make this support other cases as well 
  return (
      <Modal
          closeable
          isOpen={isOpen}
          animate
          autoFocus
          size={SIZE.default}
          role={ROLE.dialog}
          onClose={onClose}
      >
        <ModalBody>

          {/* {(step === 1) && 
            <div className='flex flex-col items-center h-auto'>
              <div className='text-2xl mt-2 text-black font-semibold'>🤖 Automatic Draft</div>
              <div className='mt-3 text-lg'>
                SkimAI can automatically scans your inbox, identifies the crucial emails, composes responses, and saves them to your Drafts folder. <b>No email is sent.</b> You can change this anytime in the settings.
              </div>
              
              <img class="h-auto max-w-full bg-white bg-auto mt-4" src={onboard_1} alt="image description"></img>

              <button type="button" class="px-5 py-2.5 text-sm font-medium text-white inline-flex items-center focus:outline-none bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 rounded-lg text-center mt-6"
                onClick={() => {
                    onUpdateAutomaticDraft(true)
                    setSteps(2)
                }}
                >

                <svg class="w-4 h-4 text-white me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                  <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm13.7-1.3a1 1 0 0 0-1.4-1.4L11 12.6l-1.8-1.8a1 1 0 0 0-1.4 1.4l2.5 2.5c.4.4 1 .4 1.4 0l4-4Z" clip-rule="evenodd"/>
                </svg>

                Sounds good, enable Automatic Draft
              </button> 
              <div className='mt-2 cursor-pointer' onClick={() => {
                    setSteps(2)
                }}>I'll do this later</div>

            </div>            
          } */}

          {(step === 1) && 
              <div className='flex flex-col items-center h-auto'>
                <div className='text-2xl mt-2 text-black font-semibold'>🏷️ Smart Labels</div>
                <div className='mt-3 text-lg'>
                  Organize your inbox with Smart Labels. Automatically sort your unread emails into labels in Gmail or into folders in iCloud and Outlook.
                  {/* SkimAI can synchronizes smart labels as labels in Gmail, and as folders in iCloud and Outlook. All smart labels will only apply to your unread emails. */}
                </div>
                
                <img class="h-auto max-w-full rounded-2xl bg-white bg-auto mt-4" src={onboard_2} alt="image description"></img>

                <button type="button" class="px-5 py-2.5 text-sm font-medium text-white inline-flex items-center focus:outline-none bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 rounded-lg text-center mt-3"
                  onClick={() => {
                      // onUpdateAutomaticDraft(true)
                      updateUserConfiguration(true)
                      // onClose()
                      // open smart label modal
                      onEnableSmartLabel()
                      setSteps(2)
                  }}
                  >

                  <svg class="w-4 h-4 text-white me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm13.7-1.3a1 1 0 0 0-1.4-1.4L11 12.6l-1.8-1.8a1 1 0 0 0-1.4 1.4l2.5 2.5c.4.4 1 .4 1.4 0l4-4Z" clip-rule="evenodd"/>
                  </svg>
                  Enable Smart Labels Sync and continue
                </button> 

                <div className='mt-2 cursor-pointer' onClick={() => {
                      // onClose()
                      setSteps(3)
                  }}>Maybe Later</div>

            </div>
          }

          {(step === 2) && 
            <>
              <SmartLabelView 
                userData={{
                  userId: userData.userId,
                  accessToken: userData.accessToken,
                  userObject: userData.userObject
                }}
                onUpdateLabels={onUpdateLabels}
              ></SmartLabelView>   

              <div className='text-center'>
                <button type="button" className="mt-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none"
                onClick={() => {
                  setSteps(3)
                }}
                >Done</button>
              </div>
            </>
          }
          {(step === 3) && 
              <div className='flex flex-col items-center h-auto h-2/3'>
                <div className='text-2xl mt-2 text-black font-semibold'>🤖 Automations</div>
                <div className='mt-3 text-lg'>
                  Set up automations in SkimAI to work silently in the background, streamlining how your emails are managed. Customize conditions and actions to let SkimAI handle your emails just the way you want.
                  {/* You can create automation in SkimAI that runs in the background and applies to your email account. You can adjust conditions and set up how you would like SkimAI to address each case. */}
                </div>
                
                {/* put the whole automation view here ? nah better screenshot and explain user to ask the*/}
                <img class="h-auto max-w-full rounded-2xl bg-white bg-auto mt-4" src={onboard_automation} alt="image description"></img>

                <button type="button" class="px-5 py-2.5 text-sm font-medium text-white inline-flex items-center focus:outline-none bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 rounded-lg text-center mt-3"
                  onClick={() => {
                      // onUpdateAutomaticDraft(true)
                      // updateUserConfiguration(true)
                      onClose()
                      // now send user to the automation
                      // open smart label modal
                      if(isNewUser){
                        createAutomation(true)
                      }
                      onEnableAutomation()

                      // setSteps(2)
                  }}
                  >

                  <svg class="w-4 h-4 text-white me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm13.7-1.3a1 1 0 0 0-1.4-1.4L11 12.6l-1.8-1.8a1 1 0 0 0-1.4 1.4l2.5 2.5c.4.4 1 .4 1.4 0l4-4Z" clip-rule="evenodd"/>
                  </svg>
                  Explore Automation Setup
                  {/* Sounds good! Let's go to automation. */}
                </button> 

                <div className='mt-2 cursor-pointer' onClick={() => {
                      onClose()
                      if(isNewUser){
                        createAutomation(false)
                      }
                      // setSteps(2)
                  }}>Maybe Later</div>

            </div>
          }

        </ModalBody>
      </Modal>
  )
}

// export default ConnectEmailView;