import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import { Tabs, Tab } from "baseui/tabs-motion";
import './ConnectEmailView.css';
import {
  Modal,
  SIZE,
  ROLE
} from "baseui/modal";

export const ConnectEmailView = ({ isOpen,choosePlanOrigin, onClose,userData, onEmailConnectionAction}) => {
    const [activeKey, setActiveKey] = React.useState("0");
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showError, setShowError] = useState(false);
    const [isCheckingConnection, setIsCheckingConnection] = useState(false);
    const [isConnectingGmail, setIsConnectingGmail] = useState(false)
    const [isConnectingOutlook, setIsConnectingOutlook] = useState(false)

    const handleIMAPConnect = async (email, password, imap) => {
        setShowError(false)
        setIsCheckingConnection(true)
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/connect_imap_service`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: email,
                    password: password,
                    userid: userData.userId,
                    imap: imap,
                    service_type: "email"
                })
            });
            const data = await response.json();
            console.log(data)
            setIsCheckingConnection(false)
            if(data.connected === false){
                setShowError(true)
            } else {
                // dirty but works: dismiss and refresh the page for now 
                window.location.reload();
            }
            
          } catch (error) {
            console.error('Error fetching data:', error);
          }
    };

    return (
        <Modal
            closeable
            isOpen={isOpen}
            animate
            size={SIZE.default}
            role={ROLE.dialog}
            onClose={onClose}
        >
            <Tabs
                activeKey={activeKey}
                onChange={({ activeKey }) => {
                    setActiveKey(activeKey);
                }}
                activateOnFocus
                >
                <Tab title="Gmail">
                <div className='flex items-center justify-center h-96'>
                    <button type="button" className="text-white bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2"
                    onClick={() => {
                        // onClose()
                        setIsConnectingGmail(true)
                        onEmailConnectionAction('google_email')
                    }}>
                        <svg className="w-4 h-4 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 19">
                            <path fillRule="evenodd" d="M8.842 18.083a8.8 8.8 0 0 1-8.65-8.948 8.841 8.841 0 0 1 8.8-8.652h.153a8.464 8.464 0 0 1 5.7 2.257l-2.193 2.038A5.27 5.27 0 0 0 9.09 3.4a5.882 5.882 0 0 0-.2 11.76h.124a5.091 5.091 0 0 0 5.248-4.057L14.3 11H9V8h8.34c.066.543.095 1.09.088 1.636-.086 5.053-3.463 8.449-8.4 8.449l-.186-.002Z" clipRule="evenodd"/>
                        </svg>
                        {isConnectingGmail ? "Connecting ... " : "Connect your Gmail account"} 
                    </button>
                </div>
                </Tab>

                <Tab title="Outlook">
                {/* <div className='text-3xl text-center'>Outlook</div> */}

                <div className='flex items-center justify-center h-96'>

                    <button 
                        type="button" 
                        class="text-gray-900 bg-white hover:bg-gray-100 border border-gray-300 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2"
                        onClick={() => {
                            // onClose()
                            setIsConnectingOutlook(true)
                            onEmailConnectionAction('microsoft_email')
                        }}
                    >
                    <svg className="me-2 -ms-1" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="24px" height="24px">
                        <path fill="#03A9F4" d="M21,31c0,1.104,0.896,2,2,2h17c1.104,0,2-0.896,2-2V16c0-1.104-0.896-2-2-2H23c-1.104,0-2,0.896-2,2V31z"/><path fill="#B3E5FC" d="M42,16.975V16c0-0.428-0.137-0.823-0.367-1.148l-11.264,6.932l-7.542-4.656L22.125,19l8.459,5L42,16.975z"/><path fill="#0277BD" d="M27 41.46L6 37.46 6 9.46 27 5.46z"/><path fill="#FFF" d="M21.216,18.311c-1.098-1.275-2.546-1.913-4.328-1.913c-1.892,0-3.408,0.669-4.554,2.003c-1.144,1.337-1.719,3.088-1.719,5.246c0,2.045,0.564,3.714,1.69,4.986c1.126,1.273,2.592,1.91,4.378,1.91c1.84,0,3.331-0.652,4.474-1.975c1.143-1.313,1.712-3.043,1.712-5.199C22.869,21.281,22.318,19.595,21.216,18.311z M19.049,26.735c-0.568,0.769-1.339,1.152-2.313,1.152c-0.939,0-1.699-0.394-2.285-1.187c-0.581-0.785-0.87-1.861-0.87-3.211c0-1.336,0.289-2.414,0.87-3.225c0.586-0.81,1.368-1.211,2.355-1.211c0.962,0,1.718,0.393,2.267,1.178c0.555,0.795,0.833,1.895,0.833,3.31C19.907,24.906,19.618,25.968,19.049,26.735z"/>
                    </svg>
                        {isConnectingOutlook ? "Connecting ... " : "Connect your Outlook account"} 
                    </button>

                    {/* <button type="button"
                            className="text-white bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2"
                            onClick={() => {
                        // onClose()
                        onEmailConnectionAction('microsoft_email')
                    }}>
                        <svg className="w-4 h-4 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 19">
                            <path fillRule="evenodd" d="M8.842 18.083a8.8 8.8 0 0 1-8.65-8.948 8.841 8.841 0 0 1 8.8-8.652h.153a8.464 8.464 0 0 1 5.7 2.257l-2.193 2.038A5.27 5.27 0 0 0 9.09 3.4a5.882 5.882 0 0 0-.2 11.76h.124a5.091 5.091 0 0 0 5.248-4.057L14.3 11H9V8h8.34c.066.543.095 1.09.088 1.636-.086 5.053-3.463 8.449-8.4 8.449l-.186-.002Z" clipRule="evenodd"/>
                        </svg>
                        Connect your Outlook account
                    </button> */}
                </div>
                </Tab>
                
                {!choosePlanOrigin && 
                    <Tab title="iCloud mail">
                        <div className='h-96 px-20 pt-8'>

                            <div className='text-3xl text-center'>iCloud</div>
                            <div class="mb-4">
                                <label for="email" class="block mb-2 text-sm font-medium text-gray-900">Email address</label>
                                <input type="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="john.doe@icloud.com" required 
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                />
                            </div>     
                            <div class="mb-4">
                                <label for="password" class="block mb-2 text-sm font-medium text-gray-900">Password</label>
                                <input type="password" id="password" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="•••••••••" required 
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                />
                            </div> 
                            <div className='mb-5 text-sm'>
                                Important: You need to <a href='https://support.apple.com/en-gb/102654' className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline" target='blank'> generate app-specific password</a> and use it here. It will be encrypted and stored securely. 
                            </div>

                            <button
                                type="button"
                                class={`text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none ${isCheckingConnection ? 'opacity-50 pointer-events-none' : ''}`}
                                onClick={() => {
                                    const imap = 'icloud'
                                    handleIMAPConnect(email, password, imap);
                                }}
                                disabled={isCheckingConnection}
                            >
                                {isCheckingConnection ? "Checking connection ..." : "Connect"}
                            </button>
                            
                            {showError && 
                                <div className='text-sm text-red-600'> IMAP login failed. Please make sure that you generate app-specific password and use it here.</div>
                            }
                    
                        </div>
                    </Tab>
                }

                {/* <Tab title="Yahoo mail">
                    <div className='h-96 px-20 pt-8'>

                        <div className='text-3xl text-center'>Yahoo</div>
                        <div class="mb-4">
                            <label for="email" class="block mb-2 text-sm font-medium text-gray-900">Email address</label>
                            <input type="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="john.doe@yahoo.com" required 
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            />
                        </div>     
                        <div class="mb-4">
                            <label for="password" class="block mb-2 text-sm font-medium text-gray-900">Password</label>
                            <input type="password" id="password" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="•••••••••" required 
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            />
                        </div> 
                        <div className='mb-5 text-sm'>
                            Important: You need to <a href='https://help.yahoo.com/kb/generate-manage-third-party-passwords-sln15241.html' className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline" target='blank'> generate app-specific password</a> and use it here. It will be encrypted and stored securely. 
                        </div>

                        <button
                            type="button"
                            class={`text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none ${isCheckingConnection ? 'opacity-50 pointer-events-none' : ''}`}
                            onClick={() => {
                                const imap = 'yahoo'
                                handleIMAPConnect(email, password, imap);
                            }}
                            disabled={isCheckingConnection}
                        >
                            {isCheckingConnection ? "Checking connection ..." : "Connect"}
                        </button>
                        
                        {showError && 
                            <div className='text-sm text-red-600'> IMAP login failed. Please make sure that you generate app-specific password and use it here.</div>
                        }
               
                    </div>
                </Tab> */}

                {/* <Tab title="Zoho mail">
                    <div className='h-96 px-20 pt-8'>

                        <div className='text-3xl text-center'>Zoho</div>
                        <div class="mb-4">
                            <label for="email" class="block mb-2 text-sm font-medium text-gray-900">Email address</label>
                            <input type="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="john.doe@zohomail.eu" required 
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            />
                        </div>     
                        <div class="mb-4">
                            <label for="password" class="block mb-2 text-sm font-medium text-gray-900">Password</label>
                            <input type="password" id="password" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="•••••••••" required 
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            />
                        </div> 
                        <div className='mb-5 text-sm'>
                            Important: First you need to <a href='https://www.zoho.com/mail/help/imap-access.html?zredirect=f&zsrc=langdropdown&lb=en' className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline" target='blank'> enable IMAP access</a>, then you need to <a href='https://www.zoho.com/mail/help/adminconsole/two-factor-authentication.html#alink5' className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline" target='blank'> generate app-specific password</a> and use it here. It will be encrypted and stored securely.
                        </div>

                        <button
                            type="button"
                            class={`text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none ${isCheckingConnection ? 'opacity-50 pointer-events-none' : ''}`}
                            onClick={() => {
                                const imap = 'zoho_eu'
                                handleIMAPConnect(email, password, imap);
                            }}
                            disabled={isCheckingConnection}
                        >
                            {isCheckingConnection ? "Checking connection ..." : "Connect"}
                        </button>
                        
                        {showError && 
                            <div className='text-sm text-red-600'> IMAP login failed. Please make sure that you generate app-specific password and use it here.</div>
                        }
               
                    </div>
                </Tab> */}

                {/* <Tab title="GMX mail">
                    <div className='h-96 px-20 pt-8'>

                        <div className='text-3xl text-center'>GMX</div>
                        <div class="mb-4">
                            <label for="email" class="block mb-2 text-sm font-medium text-gray-900">Email address</label>
                            <input type="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="john.doe@web.de" required 
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            />
                        </div>     
                        <div class="mb-4">
                            <label for="password" class="block mb-2 text-sm font-medium text-gray-900">Password</label>
                            <input type="password" id="password" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="•••••••••" required 
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            />
                        </div> 
                        <div className='mb-5 text-sm'>
                            Important: You need to <a href='https://support.gmx.com/pop-imap/toggle.html' className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline" target='blank'> enable IMAP access</a>, then you need to <a href='https://support.gmx.com/security/2fa/application-specific-passwords.html' className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline" target='blank'> generate app-specific password</a> and use it here. It will be encrypted and stored securely.
                        </div>

                        <button
                            type="button"
                            class={`text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none ${isCheckingConnection ? 'opacity-50 pointer-events-none' : ''}`}
                            onClick={() => {
                                const imap = 'gmx'
                                handleIMAPConnect(email, password, imap);
                            }}
                            disabled={isCheckingConnection}
                        >
                            {isCheckingConnection ? "Checking connection ..." : "Connect"}
                        </button>
                        
                        {showError && 
                            <div className='text-sm text-red-600'> IMAP login failed. Please make sure that you generate app-specific password and use it here.</div>
                        }

                    </div>
                </Tab> */}

                {/* <Tab title="Web.de mail">
                    <div className='h-96 px-20 pt-8'>

                        <div className='text-3xl text-center'>Web.de</div>
                        <div class="mb-4">
                            <label for="email" class="block mb-2 text-sm font-medium text-gray-900">Email address</label>
                            <input type="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="john.doe@web.de" required 
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            />
                        </div>     
                        <div class="mb-4">
                            <label for="password" class="block mb-2 text-sm font-medium text-gray-900">Password</label>
                            <input type="password" id="password" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="•••••••••" required 
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            />
                        </div> 
                        <div className='mb-5 text-sm'>
                            Important: You need to <a href='https://hilfe.web.de/pop-imap/einschalten.html' className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline" target='blank'> enable IMAP access</a>, then you need to <a href='https://hilfe.web.de/sicherheit/2fa/anwendungsspezifisches-passwort.html' className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline" target='blank'> generate app-specific password</a> and use it here. It will be encrypted and stored securely.
                        </div>

                        <button
                            type="button"
                            class={`text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none ${isCheckingConnection ? 'opacity-50 pointer-events-none' : ''}`}
                            onClick={() => {
                                const imap = 'webde'
                                handleIMAPConnect(email, password, imap);
                            }}
                            disabled={isCheckingConnection}
                        >
                            {isCheckingConnection ? "Checking connection ..." : "Connect"}
                        </button>
                        
                        {showError && 
                            <div className='text-sm text-red-600'> IMAP login failed. Please make sure that you generate app-specific password and use it here.</div>
                        }

                    </div>
                </Tab> */}

                {/* <Tab title="Outlook">
                    <div className='h-96 px-20 pt-8'>

                        <div className='text-3xl text-center'>Outlook</div>
                        <div class="mb-4">
                            <label for="email" class="block mb-2 text-sm font-medium text-gray-900">Email address</label>
                            <input type="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="john.doe@outlook.com" required 
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            />
                        </div>     
                        <div class="mb-4">
                            <label for="password" class="block mb-2 text-sm font-medium text-gray-900">Password</label>
                            <input type="password" id="password" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="•••••••••" required 
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            />
                        </div>
                        <div className='mb-5 text-sm'>
                            Important: First, you need to <a href='https://support.microsoft.com/en-us/account-billing/how-to-use-two-step-verification-with-your-microsoft-account-c7910146-672f-01e9-50a0-93b4585e7eb4' className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline" target='blank'>activate two-step verification (2FA)</a>. Once 2FA is enabled, you can <a href='https://support.microsoft.com/en-us/account-billing/using-app-passwords-with-apps-that-don-t-support-two-step-verification-5896ed9b-4263-e681-128a-a6f2979a7944' className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline" target='blank'>generate an app-specific password</a> to use here. It will be encrypted and stored securely.
                        </div>

                        <button
                            type="button"
                            class={`text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none ${isCheckingConnection ? 'opacity-50 pointer-events-none' : ''}`}
                            onClick={() => {
                                const imap = 'outlook'
                                handleIMAPConnect(email, password, imap);
                            }}
                            disabled={isCheckingConnection}
                        >
                            {isCheckingConnection ? "Checking connection ..." : "Connect"}
                        </button>
                        
                        {showError && 
                            <div className='text-sm text-red-600'> IMAP login failed. Please make sure that you generate app-specific password and use it here.</div>
                        }
               
                    </div>
                </Tab> */}
            </Tabs>
        </Modal>
    )
}

// export default ConnectEmailView;