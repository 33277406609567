import React, { useEffect } from 'react';
import { Client as Styletron } from 'styletron-engine-atomic';
import { styled } from 'baseui';
import { FileUploader } from "baseui/file-uploader";
import { db, auth, pythonAPIEndpoint} from '../../config/firebaseConfig.js';
import styles from './Document.module.css'; // wrong error message 
import { doc, setDoc, updateDoc, getDoc, deleteDoc, onSnapshot } from 'firebase/firestore'
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { collection, query, where, getDocs } from "firebase/firestore";
import {useStyletron} from 'baseui';
import { Link,useNavigate, useLocation } from "react-router-dom";
import { Avatar } from "baseui/avatar";
import logo from "../../assets/logo-blue.svg"

import {
  Modal,
  ModalHeader,
  ModalBody,
  ROLE,
  ModalFooter,
  ModalButton,
} from "baseui/modal";
import { Button, SIZE, KIND as ButtonKind } from 'baseui/button';
import { Upload, Delete, Menu } from 'baseui/icon';
import { Tabs, Tab } from "baseui/tabs-motion";
import { Input } from "baseui/input";

import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth, signOut } from "firebase/auth";
import { ProgressBar } from "baseui/progress-bar";

const engine = new Styletron();

const Centered = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
});

const tokenlimit = 50000

let urlEndpoint = "http://127.0.0.1:8000"
function Home() {
  // const db = getFirestore(app);
  const storage = getStorage();

  const [tokenused, setTokenUsed] = React.useState(0);
  const [errorMessage, setErrorMessage] = React.useState(
    ""
  );

  const [isUploading, setIsUploading] = React.useState(false);
  const [getSummary, setSummary] = React.useState()
  const [selectedItemId,setSelectedItemId] = React.useState("nothing");
  const [userId, setUserId] = React.useState("")
  const [user, loading, error] = useAuthState(auth);

  //should change the name from data to something else ...
  const [data, setData] = React.useState([{
    fileName:"",
    id:"",
    raw_text:"",
    raw_result:"",
    chats:[],
    thumbnail:""
  }]);
  // const [chatData, setChatData] = React.useState([]);
  // for modal
  const [isOpen, setIsOpen] = React.useState(false);
  const [isPaid, setIsPaid] = React.useState(false);
  const [isAILoading, setIsAILoading] = React.useState(false);
  const [isCustomQuestionOpen, setIsCustomQuestionOpen] = React.useState(false);

  const [shouldPopupPaid, setShouldPopupPaid] = React.useState(false);

  //mock: we should load this from the server side
  const items = [    { id: 1, title: 'Pitch 1', content: 'Content for Pitch 1' },    { id: 2, title: 'Pitch 2', content: 'Content for Pitch 2' },    { id: 3, title: 'Pitch 3', content: 'Content for Pitch 3' },  ];

  const [css] = useStyletron();

  const [activeKey, setActiveKey] = React.useState("0");

  const [question, setQuestion] = React.useState("");

  const TextWithLineBreaks = (textWithLineBreaks) => {
    const textWithHTMLBreaks = textWithLineBreaks.split("\n").map((line, index) => {
      return <React.Fragment key={index}>{line}<br /></React.Fragment>
    });
    return <div>{textWithHTMLBreaks}</div>;
  };  

  const navigate = useNavigate();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
  function closeDeleteDialog() {
    setIsDeleteDialogOpen(false);
  }

  // This is for the init case where user doesnt have the question 
  const [questionnaire, setQuestionnaire] = React.useState([
    'What is subject of the text?',
    'Summarise the text.',
    'What pieces of information is the text lacking?',
    'What next steps do you recommend?'
  ]);

  // const [inputs, setInputs] = React.useState({});

  const handleInputChange = (event, index) => {
    setQuestionnaire(questionnaire.map((str, i) => i === index ? event.target.value : str));
  }
  
  const handleSave = async () => {
    console.log('Input values:', questionnaire);
    // console.log(user)
    console.log(userId)
    //here we remove empty string from Array
    await updateDoc(doc(db, "users", userId), {
      questionnaire: questionnaire.filter(str => str !== "")
    });

    setIsCustomQuestionOpen(false)

  }



  useEffect(() => {

    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    
    const getData = async () =>  {
      console.log(user)
      const q = query(collection(db, "pitchdeck"), where("owner", "==", user?.uid));
      const querySnapshot = await getDocs(q);
      const dataArray = [];
      querySnapshot.forEach((doc) => {
        let tempObj = {}
        tempObj["fileName"] = doc.data().fileName
        tempObj["id"] = doc.id
        tempObj["raw_text"] = doc.data().raw_text
        tempObj["raw_result"] = doc.data().raw_result
        tempObj["chats"] = doc.data().chats
        if(doc.data().thumbnail){
          tempObj["thumbnail"] = doc.data().thumbnail._byteString.binaryString
        }
        dataArray.push(tempObj);
        if(doc.data().chats){}
      });

      setData(dataArray); 

      const q2 = query(collection(db, "users"), where("uid", "==", user?.uid));
      const querySnapshot2 = await getDocs(q2);
      querySnapshot2.forEach((doc) => {
        // console.log(doc.data())
        // console.log(user?.emailVerified, doc.data().enabled, doc.data().paid)

        // if(doc.data().paid){
        //    // do nothing
        // } else if (!user?.emailVerified && !doc.data().enabled) {
        //   const params = new URLSearchParams(window.location.search);
        //   const resultParam = params.get('status');
        //   if(resultParam === 'paid'){
        //     navigate("/invitecode?status=paid")
        //   }else{
        //     navigate("/invitecode")
        //   }
        // }
        
        // setTokenUsed(doc.data().token_used)
        setIsPaid(doc.data().paid)
      });

      const q3 = query(collection(db, "users"),  where("uid", "==", user?.uid));
      const unsubscribe = onSnapshot(q3, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // console.log("token used changed:" + doc.data().token_used)
          setTokenUsed(doc.data().token_used)
          setUserId(doc.id) // set current user od
          console.log(doc.data())
          console.log(doc.id)
          // if user has their own custom questionaire, then we should also fetch it and replace here ...
          if(doc.data().questionnaire){
            setQuestionnaire(doc.data().questionnaire)
          }
        });
      });

    }
    getData();

    // console.log(user)

    const params = new URLSearchParams(window.location.search);
    const resultParam = params.get('status');

    if(resultParam === 'paid'){
      setShouldPopupPaid(true)
      navigate("/home")
    }
    
  }, [user, loading]);


  // var shouldPopupPaid = resultParam === 'paid';
  function close() {
    setShouldPopupPaid(false);
  }
  return (
    <Centered>

      <Modal onClose={close} isOpen={shouldPopupPaid}>
        <ModalHeader>We are processing your payment</ModalHeader>
        <ModalBody>
        Thank you for purchasing SkimAI Pro. We will process your payment shortly, and you should soon be able to enjoy unlimited access to SkimAI.
        </ModalBody>
        <ModalFooter>
          <ModalButton kind="tertiary" onClick={close}>
            Cancel
          </ModalButton>
          <ModalButton onClick={close}>Done</ModalButton>
        </ModalFooter>
      </Modal>
      
      <div className={styles.LogoView}>
        <div style={{marginLeft:10,paddingTop:5,marginRight:10,fontSize:22,fontWeight:800}}>
          <img src={logo} alt="Logo"/>
        </div>
        {isPaid && 
          <div className={styles.ProBadge}>PRO</div>      
        }
      </div>

      <div className={styles.NavView} >

        {/* THIS IS HORRIBLE WAY OF DOING WE SHOULD CLEAN IT UP LATER */}
        {user?.displayName &&
        <div>
          <Avatar
          name={user?.displayName!}
          src={user?.photoURL!}
          size="scale1000"/>        

          <div style={{marginLeft:10,paddingTop:10, float:"right"}}>
            {user?.displayName!}
          </div>
        </div>
        }

        {!user?.displayName &&
        <div>
          <Avatar
          name={user?.email!}
          size="scale1000"/>        

          <div style={{marginLeft:10,paddingTop:10, float:"right"}}>
            {user?.email!}
          </div>
        </div>
        }

      </div>

        <div className={styles.CardView} style={{height:window.innerHeight - 127}}>

          {/* LEFT NAV */}
          <div className={styles.LeftView}>
            <div className={styles.ButtonContainer}>
            
            {isPaid &&
                <div className={styles.UploadButton}>
                <Button 
                  startEnhancer={() => <Upload size={24} />}
                  onClick={() => {
                    setIsOpen(true);
                  }}
                  size={SIZE.mini}
                  >
                  Upload PDF
                </Button>

                <Button  
                style={{marginLeft:10}}
                startEnhancer={() => <Menu size={24} />}
                size={SIZE.mini}
                onClick={() => {
                  setIsCustomQuestionOpen(true)
                }}>
                  Edit Questionnaire
                  </Button>
              </div>            
            }

            {!isPaid && 
              <div style={{textAlign:"center", margin:"auto", padding: 15}}>
                  <div>
                    We are no longer offering a free trial, please choose a plan with the button below.
                  </div>

                  <Button  
                    style={{marginTop:10}}
                    size={SIZE.mini}
                    onClick={() => {
                      navigate("/chooseplan")
                    }}>
                  Choose plan 
                  </Button>
                  <div style={{marginTop:25}}>
                  If you have already paid, we are checking your payment and will activate your account very soon.
                  </div>
              </div>

            }


              <div className={styles.DataItems}>
                {data.map((item) => (
                  <div
                    key={item.id}
                    onClick={() => setSelectedItemId(item.id)}
                    className={styles.LeftViewItem}
                    style={{
                      backgroundColor: item.id === selectedItemId ? '#dedede' : 'white', 
                    }}
                  >
                    {/* {item.thumbnail} */}
                    {item.thumbnail && 
                      <div className={styles.imageThumbnailDiv}>
                        <img className={styles.imageThumbnail} src={`data:image/jpeg;base64,${item.thumbnail}`}/>               
                      </div>
                    }
                    <div className={styles.pitchFileName}>
                      {item.fileName}
                    </div>
                    {item.id === selectedItemId && 
                      <div className={styles.DeleteButton}>
                        <Delete 
                          color="gray"
                          size={22} 
                          onClick={() => {
                            setIsDeleteDialogOpen(true)
                          }}
                        />                
                      </div>
                    }
                  </div>
                ))}
              </div>

              <Modal onClose={closeDeleteDialog} isOpen={isDeleteDialogOpen}>
                <ModalHeader>Are you sure?</ModalHeader>
                <ModalBody>
                  Are you sure you want to delete this document and its content? This action can not be reverted. 
                </ModalBody>
                <ModalFooter>
                  <ModalButton kind="tertiary" onClick={closeDeleteDialog}>
                    Cancel
                  </ModalButton>
                  <ModalButton onClick={() => {
                    // console.log(selectedItemId)
                    //actually deleting it 
                    deleteDoc(doc(db, "pitchdeck", selectedItemId));
                    setData(
                      data.filter(a => a.id !== selectedItemId)
                    );
                    setIsDeleteDialogOpen(false)
                    // data.find(item => item.id === selectedItemId)
                  }}>Delete</ModalButton>
                </ModalFooter>
              </Modal>

              <div className={styles.LogoutButton}>
                <Link to="/">
                  <Button 
                  size={SIZE.mini}
                  onClick={() => {
                    const auth = getAuth();
                    signOut(auth).then(() => {
                      // Sign-out successful.
                      // should go back to logout
                      // let navigate = useNavigate();
                      // navigate("/login"); 

                    }).catch((error) => {
                      // An error happened.
                    });
                  }}>
                    Logout
                  </Button>
                </Link>
              </div>

              {/* {!isPaid && (
                <div className={styles.Progressbar}>
                  <div className={styles.ProgressbarText}> {tokenused ?? 0 } out of {tokenlimit} used</div>
                <ProgressBar
                    value={((tokenused ?? 0) /tokenlimit)*100}
                    overrides={{
                      BarProgress: {
                        style: ({ $theme }) => ({
                          outline: `${$theme.colors.warning200} solid`,
                          backgroundColor: `rgb(85, 100, 255)`
                        })
                      }
                    }}
                  />
                </div>
              )} */}

            </div>
          </div>

          {/* RIGHT NAV */}
          {selectedItemId !== "nothing" && (
            <div style={{ width: '75%' }}>
              <Tabs
                activeKey={activeKey}
                onChange={({ activeKey }) => {
                  setActiveKey(activeKey);
                }}
                activateOnFocus
              >
                <Tab title="Document Analysis">
                  {selectedItemId && data.find(item => item.id === selectedItemId) && (
                    <div className={styles.SummaryText} style={{maxHeight:window.innerHeight - 210}}>
                      {/* {data.find(item => item.id === selectedItemId).raw_result && 
                      TextWithLineBreaks(data.find(item => item.id === selectedItemId).raw_result)} */}

                      {/* Worst code ever */}
                       {
                        data.find(item => item.id === selectedItemId).raw_result && 
                       !data.find(item => item.id === selectedItemId).raw_result.includes("<h3>") && 
                       TextWithLineBreaks(data.find(item => item.id === selectedItemId).raw_result)
                       }

                      {
                      data.find(item => item.id === selectedItemId).raw_result && 
                      data.find(item => item.id === selectedItemId).raw_result.includes("<h3>") && 
                      <div dangerouslySetInnerHTML={{__html: data.find(item => item.id === selectedItemId).raw_result}}/>
                      }

                  {isAILoading &&
                      <div>
                      Thinking ... 
                    </div>
                  }
                    </div>
                  )}
                </Tab>
                <Tab title="Q&A with Skim">
                  {selectedItemId && (
                      <div style={{position: 'relative'}}>
                        <div style={{height:window.innerHeight - 260, overflow: 'scroll'}}>
                          
                          {/* COULD BE BETTER WRITTEN */}
                          {data.find(item => item.id === selectedItemId) && 
                          data.find(item => item.id === selectedItemId).chats?.map((item, index) => (
                                <div key={index}>
                                  <div dangerouslySetInnerHTML={{__html: item}}/>
                                  <br></br>
                                </div>
                          ))}
                        </div>
                        
                        <div className={styles.inputChat}>

                          <div style={{width: "80%", marginRight:15, display:"inline-block"}}>
                            <Input
                                value={question}
                                onChange={e => setQuestion(e.target.value)}
                                placeholder="Ask me anything ... "
                              />
                          </div>


                          <Button onClick={async () => {
                              //TODO: re write this maybe, this whole thing is a monster
                              let tempData = data.find(item => item.id === selectedItemId)
                              let tempChatdata = []
                              if(tempData.chats){
                                tempChatdata = tempData.chats
                              }
        
                              tempChatdata = [...tempChatdata, "You: " + question] 

                              const newData = data.map(item => {
                                if(item.id === selectedItemId){
                                  return {
                                    ...item,
                                    chats: tempChatdata
                                  }
                                } else{
                                  return item
                                }
                              });
                              setData(newData)

                              const pitchdeckRef = doc(db, "pitchdeck", selectedItemId);
                              updateDoc(pitchdeckRef, {
                                chats: tempChatdata
                              });
                              // console.log(tempChatdata)
                              const requestOptions = {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json' },
                                body: JSON.stringify({ 
                                  userid: user?.uid,
                                  pitchId: selectedItemId,
                                  question: question,
                                  messages: tempChatdata,
                                  accessToken: user.accessToken
                                })
                              };
                              fetch(pythonAPIEndpoint + '/question/', requestOptions)
                                .then(response => response.json())
                                .then(respond => {
                                  // console.log(data.result)
                                  tempChatdata = [...tempChatdata, "AI: " + respond.result] // because react is not updating the setChat data rightaway

                                  const newData = data.map(item => {
                                    if(item.id === selectedItemId){
                                      return {
                                        ...item,
                                        chats: tempChatdata
                                      }
                                    } else{
                                      return item
                                    }
                                  });

                                  setData(newData)

                                  // this is saved too slow
                                  const pitchdeckRef = doc(db, "pitchdeck", selectedItemId);
                                  updateDoc(pitchdeckRef, {
                                    chats: tempChatdata
                                  });

                              });
                              setQuestion("")
                          }}>Send</Button>
                        </div>
                      </div>
                  )}
                </Tab>
              </Tabs>

            </div>
          )}
        </div>
        {/* <Link to="/editor?agentId=4ea662c2-3c0a-491e-8db1-3cbd2505175a&view=edit"> */}
        <Link to="/editor?agentId=4ea662c2-3c0a-491e-8db1-3cbd2505175a&view=edit">
                <button className={styles.playButton}>
                ✍🏻 EDIT 
                </button>
              </Link>

      <Modal
        onClose={() => setIsOpen(false)}
        closeable
        isOpen={isOpen}
        animate
        autoFocus
        size={SIZE.default}
        role={ROLE.dialog}
      >
      <ModalHeader>Upload document</ModalHeader>
      <ModalBody>
      <FileUploader 
          onCancel={() => setIsUploading(false)}
          onDrop={(acceptedFiles, rejectedFiles) => {
       
            // handle file upload...
            setIsUploading(true);
            var fileName = acceptedFiles[0].name;
            var uuid = uuidv4()
            const storageRef = ref(storage, uuid + "/file.pdf" ); // create folder
            uploadBytes(storageRef, acceptedFiles[0]).then((snapshot) => {
              // this can be done in the server side
              setDoc(doc(db, "pitchdeck", uuid), {
                fileName: fileName,
                owner: user?.uid //TODO: put the authentication up front 
              }).then(value => {
                const requestOptions = {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({ 
                    userid: user?.uid,
                    accessToken: user.accessToken,
                    pitchId: uuid
                  })
                };

                setIsAILoading(true)
                fetch(pythonAPIEndpoint + '/pitchtotextv2/' , requestOptions)
                    .then(async response => {
                      const reader = response.body?.getReader();
                      let buffer = '';

                      const docRef = doc(db, "pitchdeck",uuid);
                      const docSnap = await getDoc(docRef);
                      if (docSnap.exists()) {
                        let tempData = docSnap.data()
                        // console.log("Document data:", docSnap.data());
                        let tempObj = {}
                        tempObj["fileName"] = tempData.fileName
                        tempObj["id"] = docSnap.id
                        tempObj["raw_text"] = tempData.raw_text
                        tempObj["raw_result"] = tempData.raw_result
                        tempObj["chats"] = tempData.chats
                        if(tempData.thumbnail){
                          tempObj["thumbnail"] = tempData.thumbnail._byteString.binaryString
                        }
                        // data.push(tempObj)
                        setData([tempObj,...data])
                        setSelectedItemId(docSnap.id)
                      } else {
                        // doc.data() will be undefined in this case
                        console.log("No such document!");
                      }
                      

                      reader?.read().then(function processResult(result) {
                        if (result.done) {
                          console.log(buffer); // final result
                          setIsAILoading(false)
                          return;
                        }
                        
                        buffer += new TextDecoder().decode(result.value);

                        setData(prevData => {
                          const updatedData = [...prevData]; // create a copy of the previous data array
                          updatedData[0] = {...updatedData[0], raw_result: buffer}; // update the specific index with the new value
                          return updatedData; // return the updated data array
                        });
                        // console.log("Streaming txt")
                        // console.log(buffer);
                        return reader.read().then(processResult);
                      });

                    })
                    .then(async thedata => {

                      setIsUploading(false);
                      setIsOpen(false);
                      // setSummary(thedata.result); // deprecated? 

                      if (thedata.error){
                        alert(thedata.error.toString())// progressMessage = thedata.error.toString();
                        return
                      }

                    });
              })
            });
          }}
        progressMessage={
          isUploading ? `Processing... hang tight.` : ''
        }
        accept=".pdf"
        errorMessage={errorMessage}/>
      </ModalBody>
    </Modal>


    <Modal
      onClose={() => setIsCustomQuestionOpen(false)}
      closeable
      isOpen={isCustomQuestionOpen}
      animate
      autoFocus
      size={SIZE.default}
      role={ROLE.dialog}
    >
      <ModalHeader>Edit Questionnaire</ModalHeader>
      <ModalBody>
        <div style={{maxHeight:600,overflow:"scroll"}}>
        {questionnaire.map((item,index) => (
          <div style={{marginBottom:10}}> 
          <Input
            key={index} 
            value={item} 
            onChange={(event) => handleInputChange(event, index)}
            placeholder={(index+1) + "."}
          />

          </div>
        ))}
          <Button
            onClick={() => {
              setQuestionnaire([...questionnaire, ""])
            }}
            size={SIZE.mini}
          >
            Add Question
          </Button>
        </div>
      </ModalBody>
      <ModalFooter>
        <ModalButton 
        onClick={() =>{
          setIsCustomQuestionOpen(false)
        }}
        kind={ButtonKind.tertiary}>
          Cancel
        </ModalButton>
        <ModalButton onClick={handleSave}>Save</ModalButton>
      </ModalFooter>
    </Modal>

    </Centered>
  );

}

export default Home;