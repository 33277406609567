import { useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from '../../UserContext';

function AuthCallback() {
    const location = useLocation();
    const navigate = useNavigate();
    const { setUserId } = useContext(UserContext);

    console.log("AuthCallback component rendered");

    useEffect(() => {
        const code = new URLSearchParams(location.search).get('code');
        const localStorageUserId = localStorage.getItem('userId');
        const authType = localStorage.getItem('authType');
        const fromPath = localStorage.getItem('fromPath');

        // Parse the authType to determine the service type and provider
        const [provider, service] = authType.split('_');
    
        if (code && localStorageUserId && authType) {
            handleOAuthRedirect(code, localStorageUserId, service, fromPath, provider);
        }
    }, [location, navigate, setUserId]);
    
    const handleOAuthRedirect = async (code, userId, service, fromPath, provider) => {
        try {
            // Determine the correct endpoint URL
            const endpointUrl = `${process.env.REACT_APP_BACKEND_URL}/connect_${provider}_service`;
            const requestBody = JSON.stringify({
                userid: userId,
                code: code,
                service_type: service, // 'email' or 'calendar'
            });

            console.log(`Sending request to ${endpointUrl} with body:`, requestBody);
    
            const response = await fetch(endpointUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: requestBody,
            });
    
            // Parse the JSON response
            const data = await response.json();
            console.log(`Response from /connect_${provider}_service for ${service}:`, data);

            if (data.connected) {
                console.log(`Successfully connected to ${service}`);
                navigate(fromPath);
                localStorage.removeItem('userId');
                localStorage.removeItem('fromPath');
                localStorage.removeItem('authType');
            } else {
                console.log(`Failed to connect to ${service}`);
                // Handle failure (e.g., show an error message or redirect to an error page)
            }
        } catch (error) {
            console.error(`Error connecting to ${service}:`, error);
            // Handle network or other errors (e.g., show an error message or redirect to an error page)
        }
    };
    
    return (
        <div className="flex justify-center items-center h-screen bg-gray-100 text-gray-700">
            <div className="text-center">
                <div className="animate-bounce inline-block mr-2">⚡️</div>
                <p className="mt-2">Checking your authentication status...</p>
            </div>
        </div>
    );
}

export default AuthCallback;
