import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import { Tabs, Tab } from "baseui/tabs-motion";
import './TutorialView.css';
import {
  Modal,
  ModalBody,
  SIZE,
  ROLE
} from "baseui/modal";
import { db } from '../../config/firebaseConfig.js';
import { doc, updateDoc, getDoc } from 'firebase/firestore'
import { oembed } from "@loomhq/loom-embed";


export const TutorialView = ({ isOpen, onClose}) => {

//   const [step,setSteps] = useState(1);
    const [videoHTML, setVideoHTML] = useState("");
    const [selectedVideo, setSelectedVideo] = useState(null);

    const videos = [
        {
          title: 'How To Connect Email',
          videoId: 'video_email',
          videoUrl: 'https://www.loom.com/share/8e29312120e14f82b1006018b616a82a?sid=08658f99-4c15-46d3-9b35-a44ee564b7ff'
        },
        {
          title: 'How To Connect Calendar',
          videoId: 'video_calendar',
          videoUrl: 'https://www.loom.com/share/2106c618c45540f49eed9bf1ce78cbcc?sid=595bf900-dbd1-443e-87f5-fdea56db7bfb'
        },
        {
            title: 'How To Master the Basics',
            videoId: 'video_summary',
            videoUrl: 'https://www.loom.com/share/4274849dc4654126bd51c8cee2b71e07?sid=442b32e3-d5e5-4a43-be0e-4b0152872bfb'
        },
        {
            title: 'How To Smart Labels',
            videoId: 'video_labels',
            videoUrl: 'https://www.loom.com/share/e61774f990ae45bfa735b55f548103c2?sid=dff1fc09-e101-44f0-b915-e8a6b759e499'
        },
        // {
        //     title: 'How To Automatic Draft',
        //     videoId: 'video_automatic',
        //     videoUrl: 'https://www.loom.com/share/730780f687d349d68cdfcbb28919e23a?sid=be1d7dc8-b439-4252-bd83-cfe08f1e1f89'
        // },
        {
            title: 'How To Train SkimAI',
            videoId: 'video_train',
            videoUrl: 'https://www.loom.com/share/9cece2c358fc4704b11e380c1d3d5d53?sid=cc8746b2-d2a8-491c-ab8d-3336e9730396'
        },
        {
            title: 'How To Automations',
            videoId: 'video_automations',
            videoUrl: 'https://www.loom.com/share/0fe13fe95fd04e8d83ef084b5bd54987?sid=9acf96fc-7c5f-489a-a48a-4381a4da2b11'
        },
      ];

    async function embedLoomVideo(url) {
        // const loomUrl = 'https://www.loom.com/share/8f7b0598e8b24887a9ac4227dfcca665?sid=42b985f4-ed00-48f1-9247-a2a369a9910c';
        const { html } = await oembed(url);
        setVideoHTML(html);
    }

    const handleVideoClick = (video) => {
        setSelectedVideo(video);
        embedLoomVideo(video.videoUrl)
    };

    // Function to handle going back to the video list
    const handleBackClick = () => {
        setSelectedVideo(null);
    };

    return (
        <Modal
            closeable
            isOpen={isOpen}
            animate
            size={SIZE.default}
            role={ROLE.dialog}
            onClose={onClose}
        >
            <ModalBody>
                <div className='p-4'>
                    {selectedVideo === null ? (
                        <>
                        <div className='text-2xl mt-2 text-black font-semibold'>🎥 Tutorials</div>
                        <div className='min-h-[200px] h-auto'>
                            <div className='text-md mt-6 mb-2'>
                            Dive into our tutorials to quickly enhance your productivity with SkimAI's features.
                            </div>
                            <div className='mt-4'>
                            <ul className='list-disc pl-5 cursor-pointer'>
                                {videos.map((video, index) => (
                                <li className='hover:underline' key={index} onClick={() => handleVideoClick(video)}>
                                    {video.title}
                                </li>
                                ))}
                            </ul>
                            </div>
                        </div>
                        </>
                    ) : (
                        <>
                        {/* <button onClick={handleBackClick}>Back</button> */}
                        <button type="button" class="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mb-4"
                        onClick={()=> {
                            handleBackClick()
                            // setTrainingData()
                        }}>
                            <svg class="w-3 h-3 me-2 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m15 19-7-7 7-7"/>
                            </svg>
                        Back
                        </button>
                        <div className="video-container min-h-96">
                            <div className='text-xl font-bold'>{selectedVideo.title}</div>
                            <div className="prose mt-6 rounded-lg overflow-hidden" dangerouslySetInnerHTML={{ __html: videoHTML }} />
                        </div>
                        </>
                    )}
                </div>
            </ModalBody>
        </Modal>
    )
}