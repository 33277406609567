import './App.css';
import Auth from './components/Auth/Auth.tsx'
import InviteCode from './components/InviteCode/InviteCode.tsx'
import ChoosePlan from './components/ChoosePlan/ChoosePlan.js'
import Waitlist from './components/ChoosePlan/Waitlist.js'

import Home from './components/Home/Home';

import AppStore from './components/AppStore/AppStore.tsx'
import Editor from './components/Editor/Editor.tsx'
import Integrations from './components/Integrations/Integrations.js';
import Return from './components/ChoosePlan/Return.js'
import Document from './components/Document/Document.tsx'
import Email from './components/Email/Email.js';
import MainView from './components/Main/MainView';
import AuthCallback from './components/Integrations/AuthCallback.js';
import { UserContext } from './UserContext';

import * as React from "react";
import { useEffect, useState } from 'react';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { ThemeProvider,LightTheme,createTheme,lightThemePrimitives, BaseProvider } from 'baseui';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const engine = new Styletron();

function App() {

  const [userId, setUserId] = useState(null);
  
  useEffect(() => {
    document.title = 'SkimAI⚡️ - 10x Your Productivity';
  }, []);

  return (
    <UserContext.Provider value={{ userId, setUserId }}>
      <StyletronProvider value={engine}>
        <BaseProvider theme={LightTheme}>

        <ThemeProvider
          theme={createTheme(lightThemePrimitives, {
            colors: { 
              buttonPrimaryFill: "#5564FF",
              buttonPrimaryHover: "#8691FF"
            }
          })}
        >
          <div className="app">
            <Router>
              <Routes>
                <Route exact path="/" element={<Auth />} />
                <Route path="/auth_callback" element={<AuthCallback />} />
                {/* <Route exact path="/invitecode" element={<InviteCode />} /> */}
                {/* <Route exact path="/waitlist" element={<Waitlist />} /> */}
                <Route exact path="/chooseplan" element={<ChoosePlan />} />

                {/* <Route exact path="/home" element={<Home />} /> */}

                {/* <Route exact path="/integrations" element={<Integrations />} /> */}
                <Route exact path="/email" element={<Email />} />
                <Route exact path="/document" element={<Document />} />
                <Route path="/return" element={<Return />} />
                {/* Demo Pages */}
                {/* <Route exact path="/main" element={<MainView />} /> */}
                {/* <Route exact path="/appstore" element={<AppStore />} /> */}
                {/* <Route exact path="/editor" element={<Editor />} /> */}
              </Routes>
            </Router>
          </div>
        </ThemeProvider>

        </BaseProvider>
      </StyletronProvider>
    </UserContext.Provider>
  );
}

export default App;