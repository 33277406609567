import React, { useState, useEffect, useContext } from 'react';
import './NavBar.css';
import logo from "../../assets/logov2.png"
import { useAuthState } from 'react-firebase-hooks/auth';
import { UserContext } from '../../UserContext.js';
import { getAuth, signOut } from "firebase/auth";
import { auth } from '../../config/firebaseConfig.js';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from '../../config/firebaseConfig.js';

export default function NavBar() {
  const [user] = useAuthState(auth);
  const { userId, setUserId } = useContext(UserContext);
  const [subscriptionActive, setSubscriptionActive] = useState(true);
  const [isStripeCustomerId, setisStripeCustomerId] = useState(false)
  const [isActive, setIsActive] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  let navigate = useNavigate();

  const toggleDropdown = () => {
    setIsActive(!isDropdownOpen);
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleButtonClick = async (action) => {
    if (action === 'log-out') {
      handleLogout();
    } else if (action === 'privacy-policy') {
      window.open('https://skim.page/pp/Privacy_Policy.pdf', '_blank');
    } else if (action === 'manage-subscription') {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/create-portal-session`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userid: userId,
            accessToken: user?.accessToken
          })
        });

        const data = await response.json();
        if (data.url) {
          window.open(data.url, '_blank');
          // window.location.href = data.url; // Redirect to Stripe Customer Portal
        } else {
          console.error('Portal URL not received');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }

    setIsDropdownOpen(false);
    setIsActive(false);
  };

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
      // Sign-out successful.
      navigate("/"); // Navigate to the login page after successful sign-out
    }).catch((error) => {
      // An error happened.
      console.error("Logout failed", error);
    });
  };

  useEffect(() => {
    if (isDropdownOpen) {
      const handleOutsideClick = (event) => {
        if (!event.target.closest('.profile-dropdown-container')) {
          setIsDropdownOpen(false);
          setIsActive(false);
        }
      };

      document.addEventListener('mousedown', handleOutsideClick);
      return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
      };
    }
  }, [isDropdownOpen]);

  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      if (user) {
        const q = query(collection(db, "users"), where("uid", "==", user.uid));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          const userData = doc.data();
          setSubscriptionActive(userData.subscription_active);
          setisStripeCustomerId(!!userData.stripe_customer_id);
        });
      }
    };
    checkSubscriptionStatus();
  }, [user]);
  
  return (
    <nav className="mainnav sticky bg-white shadow-lg fixed w-full top-0">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Logo Section */}
          <div className="flex items-center">
            <div className="cursor-pointer flex-shrink-0 text-gray-800">
            {/* <div className="cursor-pointer flex-shrink-0 text-gray-800" onClick={() => navigate('/home')}> */}
              <img src={logo} alt="Logo" className="logo-img"/>
            </div>
          </div>

          <div className="flex items-center justify-between w-full">
            <div className="flex items-center ml-auto">
              {
                !subscriptionActive && (
                  <div className="text-sm font-medium text-gray-800">Your free trial has ended</div>
                )
              }
              {
                !isStripeCustomerId && (
                <button 
                  className="text-sm font-medium text-gray-800 bg-blue-700 hover:bg-blue-800 text-white px-4 py-2 m-4 rounded-lg"
                  onClick={() => navigate('/chooseplan')}
                >
                  Upgrade
                </button>
                )
              }
            </div>
          </div>

          {/* Profile Section */}
          <div className="profile-dropdown-container">
            <div className={`profile-section ${isActive ? 'active' : ''} h-12`} onClick={toggleDropdown}>
              {/* <img className="h-8 w-8 rounded-full cursor-pointer" src={user?.photoURL} alt="Profile" /> */}
              <div className="ml-3">
                <div className="text-sm font-medium text-gray-800">{user?.displayName}</div>
                <div className="text-xs text-gray-500">{user?.email}</div>
              </div>
            </div>

            {/* Dropdown Menu */}
            {isDropdownOpen && (
              <div className="dropdown-menu">
                <button 
                  className={`text-sm font-medium ${isStripeCustomerId ? 'text-gray-800 hover:bg-blue-100' : 'text-gray-400  hover:bg-transparent'}`} 
                  onClick={() => handleButtonClick('manage-subscription')}
                  disabled={!isStripeCustomerId}
                >
                  Manage My Subscription
                </button>
                <button className="text-sm font-medium text-gray-800" onClick={() => handleButtonClick('privacy-policy')}>Privacy Policy</button>
                <button className="text-sm font-medium text-gray-800" onClick={() => handleButtonClick('log-out')}>Log Out</button>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}
