import React, { useEffect } from 'react';
import { Client as Styletron } from 'styletron-engine-atomic';
import { styled } from 'baseui';
import { FileUploader } from "baseui/file-uploader";
import { db, auth, pythonAPIEndpoint} from '../../config/firebaseConfig.js';
import styles from './Application2.module.css'; // wrong error message 
import { doc, setDoc, updateDoc, getDoc, deleteDoc, onSnapshot } from 'firebase/firestore'
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { collection, query, where, getDocs } from "firebase/firestore";
import {useStyletron} from 'baseui';
import { Link,useNavigate, useLocation } from "react-router-dom";
import { Avatar } from "baseui/avatar";
import logo from "../../assets/logo-blue.svg"

import {
  Modal,
  ModalHeader,
  ModalBody,
  ROLE,
  ModalFooter,
  ModalButton,
} from "baseui/modal";
import { Button, SIZE, KIND as ButtonKind } from 'baseui/button';
import { Upload, Delete, Menu } from 'baseui/icon';
import { Tabs, Tab } from "baseui/tabs-motion";
import { Input } from "baseui/input";

import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth, signOut } from "firebase/auth";
import { ProgressBar } from "baseui/progress-bar";

const engine = new Styletron();

const Centered = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
});

const tokenlimit = 50000

let urlEndpoint = "http://127.0.0.1:8000"
function Application2({ hasQandANode,hasCreatePDFNode, agentId}) {
  // const db = getFirestore(app);
  const storage = getStorage();

  const [tokenused, setTokenUsed] = React.useState(0);
  const [errorMessage, setErrorMessage] = React.useState(
    ""
  );

  const [isUploading, setIsUploading] = React.useState(false);
  const [getSummary, setSummary] = React.useState()
  const [selectedItemId,setSelectedItemId] = React.useState("nothing");
  const [userId, setUserId] = React.useState("")
  const [user, loading, error] = useAuthState(auth);

  //should change the name from data to something else ...
  const [data, setData] = React.useState([{
    fileName:"",
    id:"",
    raw_text:"",
    raw_result:"",
    chats:[],
    thumbnail:""
  }]);
  // const [chatData, setChatData] = React.useState([]);
  // for modal
  const [isOpen, setIsOpen] = React.useState(false);
  const [isPaid, setIsPaid] = React.useState(false);
  const [isAILoading, setIsAILoading] = React.useState(false);
  const [isCustomQuestionOpen, setIsCustomQuestionOpen] = React.useState(false);

  const [shouldPopupPaid, setShouldPopupPaid] = React.useState(false);

  //mock: we should load this from the server side
  const items = [    { id: 1, title: 'Pitch 1', content: 'Content for Pitch 1' },    { id: 2, title: 'Pitch 2', content: 'Content for Pitch 2' },    { id: 3, title: 'Pitch 3', content: 'Content for Pitch 3' },  ];

  const [css] = useStyletron();

  const [activeKey, setActiveKey] = React.useState("0");

  const [question, setQuestion] = React.useState("");

  const TextWithLineBreaks = (textWithLineBreaks) => {
    const textWithHTMLBreaks = textWithLineBreaks.split("\n").map((line, index) => {
      return <React.Fragment key={index}>{line}<br /></React.Fragment>
    });
    return <div>{textWithHTMLBreaks}</div>;
  };  

  const navigate = useNavigate();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
  function closeDeleteDialog() {
    setIsDeleteDialogOpen(false);
  }

  // This is for the init case where user doesnt have the question 
  const [questionnaire, setQuestionnaire] = React.useState([
    'What is subject of the text?',
    'Summarise the text.',
    'What pieces of information is the text lacking?',
    'What next steps do you recommend?'
  ]);

  // const [inputs, setInputs] = React.useState({});

  const handleInputChange = (event, index) => {
    setQuestionnaire(questionnaire.map((str, i) => i === index ? event.target.value : str));
  }
  
  const handleSave = async () => {
    console.log('Input values:', questionnaire);
    // console.log(user)
    console.log(userId)
    //here we remove empty string from Array
    await updateDoc(doc(db, "users", userId), {
      questionnaire: questionnaire.filter(str => str !== "")
    });

    setIsCustomQuestionOpen(false)

  }


  const handleDownloadPDF = async () => {
    // console.log("HERE")
    try {
      const response = await fetch(pythonAPIEndpoint + '/download_pdf');
      const pdfBlob = await response.blob();
      const url = URL.createObjectURL(pdfBlob);

      // Create an anchor element and trigger a click on it to download the PDF
      const link = document.createElement('a');
      link.href = url;
      link.download = 'output.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };


  useEffect(() => {

    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    
    const getData = async () =>  {
      console.log(user)
      const q = query(collection(db, "pitchdeck"), where("owner", "==", user?.uid));
      const querySnapshot = await getDocs(q);
      const dataArray = [];
      querySnapshot.forEach((doc) => {
        let tempObj = {}
        tempObj["fileName"] = doc.data().fileName
        tempObj["id"] = doc.id
        tempObj["raw_text"] = doc.data().raw_text
        tempObj["raw_result"] = doc.data().raw_result
        tempObj["chats"] = doc.data().chats
        if(doc.data().thumbnail){
          tempObj["thumbnail"] = doc.data().thumbnail._byteString.binaryString
        }
        dataArray.push(tempObj);
        if(doc.data().chats){}
      });

      setData(dataArray); 

      const q2 = query(collection(db, "users"), where("uid", "==", user?.uid));
      const querySnapshot2 = await getDocs(q2);
      querySnapshot2.forEach((doc) => {
        // console.log(doc.data())
        // console.log(user?.emailVerified, doc.data().enabled, doc.data().paid)

        // if(doc.data().paid){
        //    // do nothing
        // } else if (!user?.emailVerified && !doc.data().enabled) {
        //   const params = new URLSearchParams(window.location.search);
        //   const resultParam = params.get('status');
        //   if(resultParam === 'paid'){
        //     navigate("/invitecode?status=paid")
        //   }else{
        //     navigate("/invitecode")
        //   }
        // }
        
        // setTokenUsed(doc.data().token_used)
        setIsPaid(doc.data().paid)
      });

      const q3 = query(collection(db, "users"),  where("uid", "==", user?.uid));
      const unsubscribe = onSnapshot(q3, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // console.log("token used changed:" + doc.data().token_used)
          setTokenUsed(doc.data().token_used)
          setUserId(doc.id) // set current user od
          console.log(doc.data())
          console.log(doc.id)
          // if user has their own custom questionaire, then we should also fetch it and replace here ...
          if(doc.data().questionnaire){
            setQuestionnaire(doc.data().questionnaire)
          }
        });
      });

    }
    getData();

    // console.log(user)

    const params = new URLSearchParams(window.location.search);
    const resultParam = params.get('status');

    if(resultParam === 'paid'){
      setShouldPopupPaid(true)
      navigate("/home")
    }
    
  }, [user, loading]);


  // var shouldPopupPaid = resultParam === 'paid';
  function close() {
    setShouldPopupPaid(false);
  }
  return (
    <Centered className={styles.FloatingApp}>

      <Modal onClose={close} isOpen={shouldPopupPaid}>
        <ModalHeader>We are processing your payment</ModalHeader>
        <ModalBody>
        Thank you for purchasing SkimAI Pro. We will process your payment shortly, and you should soon be able to enjoy unlimited access to SkimAI.
        </ModalBody>
        <ModalFooter>
          <ModalButton kind="tertiary" onClick={close}>
            Cancel
          </ModalButton>
          <ModalButton onClick={close}>Done</ModalButton>
        </ModalFooter>
      </Modal>

        <div className={styles.CardView}>

          {/* LEFT NAV */}
          <div className={styles.LeftView}>
            <div className={styles.ButtonContainer}>
            

            <div className={styles.UploadButton}>
            
            {selectedItemId !== 'nothing' && 
              <button className={styles.playButton} 
                  onClick={() => {
                    console.log(selectedItemId)
                    setIsAILoading(true);
                    const requestOptions = {
                      method: 'POST',
                      headers: { 'Content-Type': 'application/json' },
                      body: JSON.stringify({ 
                        userid: user?.uid,
                        accessToken: user.accessToken,
                        pitchId: selectedItemId,
                        agentId: agentId
                      })
                    };
                    console.log(requestOptions)
                    // now do the shit
                    fetch(pythonAPIEndpoint + '/runworkflowV2/', requestOptions)
                    .then(async response => {
                      const reader = response.body?.getReader();
                      let buffer = '';

                      const docRef = doc(db, "pitchdeck",selectedItemId);

                      // // Get the document snapshot from Firestore
                      const docSnap = await getDoc(docRef);
                      

                      // Read the response data from the API call
                      reader?.read().then(function processResult(result) {
                        if (result.done) {
                          console.log(buffer);
                          setIsAILoading(false);

                          // now show the PDF
                          return;
                        }
                        
                        buffer += new TextDecoder().decode(result.value);

                        setData(prevData => {
                          const updatedData = [...prevData]; // Create a copy of the previous data array
                          const index = updatedData.findIndex(item => item.id === docSnap.id);
                          if (index !== -1) {
                            updatedData[index] = { ...updatedData[index], raw_result: buffer }; // Update the specific index with the new value
                          }
                          return updatedData; // Return the updated data array
                        });

                        return reader.read().then(processResult);
                      });
                    })
                    .then(async thedata => {
                      setIsUploading(false);
                      setIsOpen(false);
                    });
                  }}
              >
                {isAILoading ? "RUNNING ..." : "🔄 RERUN"} 
              </button>            
            }

              <Button 
                startEnhancer={() => <Upload size={24} />}
                onClick={() => {
                  setIsOpen(true);
                }}
                size={SIZE.mini}
                >
                Upload File(s)
              </Button>


            </div>            
            

              <div className={styles.DataItems}>
                {data.map((item) => (
                  
                  <div
                    key={item.id}
                    onClick={() => setSelectedItemId(item.id)}
                    className={styles.LeftViewItem}
                    style={{
                      backgroundColor: item.id === selectedItemId ? '#dedede' : 'white', 
                    }}
                  >
                    {/* {item.thumbnail} */}
                    {item.thumbnail && 
                      <div className={styles.imageThumbnailDiv}>
                        <img className={styles.imageThumbnail} src={`data:image/jpeg;base64,${item.thumbnail}`}/>               
                      </div>
                    }
                    <div className={styles.pitchFileName}>
                      {item.fileName}
                    </div>
                    {item.id === selectedItemId && 
                      <div className={styles.DeleteButton}>
                        <Delete 
                          color="gray"
                          size={22} 
                          onClick={() => {
                            setIsDeleteDialogOpen(true)
                          }}
                        />                
                      </div>
                    }
                  </div>
                ))}
              </div>

              <Modal onClose={closeDeleteDialog} isOpen={isDeleteDialogOpen}>
                <ModalHeader>Are you sure?</ModalHeader>
                <ModalBody>
                  Are you sure you want to delete this document and its content? This action can not be reverted. 
                </ModalBody>
                <ModalFooter>
                  <ModalButton kind="tertiary" onClick={closeDeleteDialog}>
                    Cancel
                  </ModalButton>
                  <ModalButton onClick={() => {
                    // console.log(selectedItemId)
                    //actually deleting it 
                    deleteDoc(doc(db, "pitchdeck", selectedItemId));
                    setData(
                      data.filter(a => a.id !== selectedItemId)
                    );
                    setIsDeleteDialogOpen(false)
                    setSelectedItemId("nothing")
                    // data.find(item => item.id === selectedItemId)
                  }}>Delete</ModalButton>
                </ModalFooter>
              </Modal>

            </div>
          </div>

          {/* RIGHT NAV */}
          {selectedItemId !== "nothing" && (
            <div>
              <Tabs
              
                activeKey={activeKey}
                onChange={({ activeKey }) => {
                  setActiveKey(activeKey);
                }}
                activateOnFocus
              >
                <Tab title="Output">
                  {selectedItemId && data.find(item => item.id === selectedItemId) && (
                    <div className={styles.SummaryText} style={{maxHeight:window.innerHeight - 210}}>

                      {/* Worst code ever */}
                      {
                      data.find(item => item.id === selectedItemId).raw_result && 
                      !data.find(item => item.id === selectedItemId).raw_result.includes("<h3>") && 
                      TextWithLineBreaks(data.find(item => item.id === selectedItemId).raw_result)
                      }

                      {
                      data.find(item => item.id === selectedItemId).raw_result && 
                      data.find(item => item.id === selectedItemId).raw_result.includes("<h3>") && 
                      <div dangerouslySetInnerHTML={{__html: data.find(item => item.id === selectedItemId).raw_result}}/>
                      }

                    {isAILoading &&
                        <div>
                        Thinking ... 
                      </div>
                    }

                    {/* {hasCreatePDFNode && !isAILoading &&
                      <div className={styles.downloadPDFButton} onClick={handleDownloadPDF}>
                        📄 Output.pdf
                      </div>                    
                    } */}

                    </div>
                  )}

                  {hasCreatePDFNode && !isAILoading &&
                    <div className={styles.downloadPDFButton} onClick={handleDownloadPDF}>
                      📄 Output.pdf
                    </div>                    
                  }

                </Tab>

                {/* WE could have Question Block later if we have time left */}
                {hasQandANode && 
                  <Tab title="Q&A">
                  {selectedItemId && (
                      <div style={{position: 'relative'}}>
                        <div style={{height:window.innerHeight - 430, overflow: 'scroll'}}>
                          
                          {data.find(item => item.id === selectedItemId) && 
                          data.find(item => item.id === selectedItemId).chats?.map((item, index) => (
                                <div key={index}>
                                  <div dangerouslySetInnerHTML={{__html: item}}/>
                                  <br></br>
                                </div>
                          ))}
                        </div>
                        
                        <div className={styles.inputChat}>

                          <div style={{width: "80%", marginRight:15, display:"inline-block"}}>
                            <Input
                                value={question}
                                onChange={e => setQuestion(e.target.value)}
                                placeholder="Ask me anything ... "
                              />
                          </div>


                          <Button onClick={async () => {
                              //TODO: re write this maybe, this whole thing is a monster
                              let tempData = data.find(item => item.id === selectedItemId)
                              let tempChatdata = []
                              if(tempData.chats){
                                tempChatdata = tempData.chats
                              }
        
                              tempChatdata = [...tempChatdata, "You: " + question] 

                              const newData = data.map(item => {
                                if(item.id === selectedItemId){
                                  return {
                                    ...item,
                                    chats: tempChatdata
                                  }
                                } else{
                                  return item
                                }
                              });
                              setData(newData)

                              const pitchdeckRef = doc(db, "pitchdeck", selectedItemId);
                              updateDoc(pitchdeckRef, {
                                chats: tempChatdata
                              });
                              // console.log(tempChatdata)
                              const requestOptions = {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json' },
                                body: JSON.stringify({ 
                                  userid: user?.uid,
                                  pitchId: selectedItemId,
                                  question: question,
                                  messages: tempChatdata,
                                  accessToken: user.accessToken
                                })
                              };
                              fetch(pythonAPIEndpoint + '/question/', requestOptions)
                                .then(response => response.json())
                                .then(respond => {
                                  // console.log(data.result)
                                  tempChatdata = [...tempChatdata, "AI: " + respond.result] // because react is not updating the setChat data rightaway

                                  const newData = data.map(item => {
                                    if(item.id === selectedItemId){
                                      return {
                                        ...item,
                                        chats: tempChatdata
                                      }
                                    } else{
                                      return item
                                    }
                                  });

                                  setData(newData)

                                  // this is saved too slow
                                  const pitchdeckRef = doc(db, "pitchdeck", selectedItemId);
                                  updateDoc(pitchdeckRef, {
                                    chats: tempChatdata
                                  });

                              });
                              setQuestion("")
                          }}>Send</Button>
                        </div>
                      </div>
                  )}
                </Tab>                
                }

                
              </Tabs>

            </div>
          )}
        </div>

      <Modal
        onClose={() => setIsOpen(false)}
        closeable
        isOpen={isOpen}
        animate
        autoFocus
        size={SIZE.default}
        role={ROLE.dialog}
      >
      <ModalHeader>Upload document</ModalHeader>
      <ModalBody>
      <FileUploader 
        onCancel={() => setIsUploading(false)}
        onDrop={(acceptedFiles, rejectedFiles) => {
          setIsUploading(true);

          // Iterate over each accepted file
          acceptedFiles.forEach((file) => {
            var fileName = file.name;
            var uuid = uuidv4();
            const storageRef = ref(storage, uuid + "/file.pdf");

            // Upload the file to storage
            uploadBytes(storageRef, file).then((snapshot) => {

              // Set document data in the "pitchdeck" collection
              setDoc(doc(db, "pitchdeck", uuid), {
                fileName: fileName,
                owner: user?.uid
              }).then(() => {

                // Prepare the request options for the API call
                const requestOptions = {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({ 
                    userid: user?.uid,
                    accessToken: user.accessToken,
                    pitchId: uuid,
                    agentId: agentId
                  })
                };

                setIsAILoading(true);

                // Make API call to the Python endpoint
                console.log(requestOptions)
                fetch(pythonAPIEndpoint + '/runworkflowV2/', requestOptions)
                  .then(async response => {
                    const reader = response.body?.getReader();
                    let buffer = '';

                    const docRef = doc(db, "pitchdeck",uuid);

                    // Get the document snapshot from Firestore
                    const docSnap = await getDoc(docRef);

                    if (docSnap.exists()) {
                      let tempData = docSnap.data()
                      let tempObj = {
                        fileName: tempData.fileName,
                        id: docSnap.id,
                        raw_text: tempData.raw_text,
                        raw_result: tempData.raw_result,
                        chats: tempData.chats
                      };

                      if (tempData.thumbnail) {
                        tempObj.thumbnail = tempData.thumbnail._byteString.binaryString;
                      }

                      setData(prevData => {
                        const updatedData = [tempObj, ...prevData]; // Add the new file data to the beginning of the array
                        return updatedData; // Return the updated data array
                      });
                      setSelectedItemId(docSnap.id)
                    } else {
                      console.log("No such document!");
                    }

                    // Read the response data from the API call
                    reader?.read().then(function processResult(result) {
                      if (result.done) {
                        console.log(buffer);
                        setIsAILoading(false);
                        return;
                      }
                      
                      buffer += new TextDecoder().decode(result.value);

                      setData(prevData => {
                        const updatedData = [...prevData]; // Create a copy of the previous data array
                        const index = updatedData.findIndex(item => item.id === docSnap.id);
                        if (index !== -1) {
                          updatedData[index] = { ...updatedData[index], raw_result: buffer }; // Update the specific index with the new value
                        }
                        return updatedData; // Return the updated data array
                      });

                      return reader.read().then(processResult);
                    });
                  })
                  .then(async thedata => {
                    setIsUploading(false);
                    setIsOpen(false);

                    if (thedata.error) {
                      alert(thedata.error.toString());
                      return;
                    }
                  });
              });
            });
          });
        }}
        progressMessage={isUploading ? `Processing... hang tight.` : ''}
        accept=".pdf"
        errorMessage={errorMessage}
      />

      </ModalBody>
    </Modal>
    </Centered>
  );

}

export default Application2;
