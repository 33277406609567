import React, { useState, useEffect, useRef } from 'react';
import { scaleBand, scaleLinear } from '@visx/scale';
import { Bar } from '@visx/shape';
import { Group } from '@visx/group';
import { Text } from '@visx/text';

const HorizontalBarChart = ({ data, firstLabel = "Sender", secondLabel = "Emails", type = ""}) => {
    const [expanded, setExpanded] = useState(false);
    const [containerWidth, setContainerWidth] = useState(0);
    const containerRef = useRef(null);

    useEffect(() => {
        const updateWidth = () => {
            if (containerRef.current) {
                setContainerWidth(containerRef.current.offsetWidth);
            }
        };
        updateWidth();
        window.addEventListener('resize', updateWidth);
        return () => window.removeEventListener('resize', updateWidth);
    }, []);

    if (!data) {
        return <div>No data available</div>;
    }

    const dataEntries = Object.entries(data);
    const emailSizeOrder = ["one sentence", "one paragraph", "three paragraphs", "this is a novel"];
    const sortedData = firstLabel === "Email Length"
        ? dataEntries.sort((a, b) => emailSizeOrder.indexOf(a[0]) - emailSizeOrder.indexOf(b[0]))
        : dataEntries.sort((a, b) => b[1] - a[1]);

    const displayedData = expanded ? sortedData : sortedData.slice(0, 5);
    const keys = displayedData.map(d => d[0]);
    const values = displayedData.map(d => d[1]);

    const barHeight = 30;
    const height = keys.length * barHeight + 60;
    const xMax = containerWidth - 80; // Updated xMax calculation
    const yMax = height - 35;

    const maxKeyLength = Math.floor(containerWidth / 10);

    const xScale = scaleLinear({
        domain: [0, Math.max(...values)],
        range: [0, xMax - 40],
    });

    const yScale = scaleBand({
        domain: keys,
        range: [0, yMax],
        padding: 0.15,
    });

    const barColor = type === "Recipient"
        ? "#F8B195" // Color for Received Emails
        : type === "Sender"
        ? "#94DAFF" // Color for Sent Emails
        : "#FDD95C";

    return (
        <div ref={containerRef} className="w-full"> {/* Added container ref */}
            <svg width="100%" height={height} viewBox={`0 0 ${containerWidth} ${height}`}> {/* Updated SVG */}
                <Group top={30} left={30}>
                    <Text x={0} y={-15} fontSize={'0.875rem'} fill="grey" textAnchor="start">
                        {firstLabel}
                    </Text>
                    <Text x={xMax + 10} y={-15} fontSize={'0.875rem'} fill="grey" textAnchor="end">
                        {secondLabel}
                    </Text>
                    {keys.map((key, index) => {
                        const barWidth = xScale(data[key]);
                        const barY = yScale(key);
                        const textColor = "grey";
                        const truncatedKey = key.length > maxKeyLength ? `${key.substring(0, maxKeyLength)}...` : key;
                        return (
                            <Group key={`bar-${key}`}>
                                <Bar
                                    x={0}
                                    y={barY}
                                    width={barWidth}
                                    height={yScale.bandwidth()}
                                    fill={barColor}
                                    rx={4}
                                    ry={4}
                                />
                                <Text
                                    x={xMax + 5}
                                    y={barY + yScale.bandwidth() / 2}
                                    verticalAnchor="middle"
                                    fontSize={13}
                                    fill="black"
                                    textAnchor="end"
                                >
                                    {data[key]}
                                </Text>
                                <Text
                                    x={7}
                                    y={barY + yScale.bandwidth() / 2}
                                    verticalAnchor="middle"
                                    fontSize={13}
                                    fill={textColor}
                                    textAnchor="start"
                                >
                                    {truncatedKey}
                                </Text>
                            </Group>
                        );
                    })}
                </Group>
            </svg>
            {sortedData.length > 5 && (
                <div className="flex justify-center mt-2">
                    <button
                        className="inline-flex px-2 py-2 text-xs font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200"
                        onClick={() => setExpanded(!expanded)}
                    >
                        {expanded ? "Show Less" : "Show More"}
                    </button>
                </div>
            )}
        </div>
    );
};

export default HorizontalBarChart;
