import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { db, auth } from '../../config/firebaseConfig.js';
import { collection, query, where, getDocs } from "firebase/firestore";
import { doc, updateDoc, getDoc } from 'firebase/firestore'

import './SmartLabelView.css';
import {
  Modal,
  ModalHeader,
  ModalFooter,
  SIZE,
  ROLE
} from "baseui/modal";
import { useAutoAnimate } from '@formkit/auto-animate/react'
import ContentEditable from 'react-contenteditable'
import { ChromePicker } from 'react-color'; // Import ChromePicker from react-color
import { TwitterPicker } from 'react-color';
import { TestEmailView } from './TestEmailView.js';

export const SmartLabelView = ({ userData, onUpdateLabels, isOpen, onClose, isSubscriptionActive}) => {

    const [labels, setLabels] = useState(userData.userObject?.labels ?? [{ name: '', description: '', color: '#ffffff' }]);
    const [showColorPickerIndex, setShowColorPickerIndex] = useState(null);
    const [isSyncToGmailChecked, setIsSyncToGmailChecked] = useState(false);
    const [isSyncToIMAPChecked, setIsSyncToIMAPChecked] = useState(false);
    const [isSyncToOutlookChecked, setIsSyncToOutlookChecked] = useState(false);
    const [isRemoveFromInboxEnabled, setIsRemoveFromInboxChecked] = useState(false);
    const [isSyncing, setIsSyncing] = useState(false);
    const [isDuplicateError, setIsDuplicateError] = useState(false)
    const [isTestEmailOpened, setIsTestEmailOpened] = useState(false)
    // const [hasUnsaved, setHasUnsaved] = useState(false); // this is only for gmail label stuff
    const colorPickerRef = useRef(null);
    const [hasGmailConnected, setHasGmailConnected] = useState(false)
    const [hasIMAPConnected, setHasIMAPConnected] = useState(false)
    const [hasOutlookConnected, setHasOutlookConnected] = useState(false)
    const [selectedLabel, setSelectedLabel] = useState({})
    //init
    useEffect(() => {
        if(userData.userObject && userData.userObject.configuration ){
            setIsSyncToGmailChecked(userData.userObject.configuration.isSyncLabelsToGmailEnabled)
            setIsRemoveFromInboxChecked(userData.userObject.configuration.isRemoveFromInboxEnabled)
            setIsSyncToIMAPChecked(userData.userObject.configuration.isSyncLabelsToIMAPEnabled)
            setIsSyncToOutlookChecked(userData.userObject.configuration.isSyncLabelsToOutlookEnabled)
        }

        const tokens = userData.userObject.tokens

        //check gmail 
        if(tokens && tokens.emailTokens && tokens.emailTokens.length > 0){
            for (let i = 0; i < tokens.emailTokens.length; i++) {
                if (tokens.emailTokens[i].token_uri === "https://oauth2.googleapis.com/token") {
                    setHasGmailConnected(true)
                    break; // Exit the loop since we found the token_uri
                }
              }
        }

        //check imap
        if(tokens && tokens.emailTokens && tokens.emailTokens.length > 0){
            for (let i = 0; i < tokens.emailTokens.length; i++) {
                if (tokens.emailTokens[i].imap_type && tokens.emailTokens[i].imap_type) {
                    setHasIMAPConnected(true)
                    break; // Exit the loop since we found the token_uri
                }
              }
        }

        //check outlook
        if (tokens && tokens.emailTokens && tokens.emailTokens.length > 0) {
            for (let i = 0; i < tokens.emailTokens.length; i++) {
                if (tokens.emailTokens[i].scopes && tokens.emailTokens[i].scopes.some(scope => scope.includes("microsoft"))) {
                    setHasOutlookConnected(true);
                    break;
                }
            }
        }

    }, []);

    const handleShouldRemoveFromInboxChecked = async (checked) => {
        setIsRemoveFromInboxChecked(checked);
        updateUserConfiguration("isRemoveFromInboxEnabled",checked)
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
                setShowColorPickerIndex(null);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [colorPickerRef]);

    const saveLabels = (labels) => {
        const docRef = doc(db, "users", userData.userId);
        updateDoc(docRef, {
            labels: labels
          }).then(() => {
            if (onUpdateLabels) {
              onUpdateLabels(labels);
            }
          });
    }

    const handleLabelChange = (index, type, value) => {
        const updatedLabels = [...labels];
        updatedLabels[index][type] = value;
        setLabels(updatedLabels);
        saveLabels(updatedLabels);
    };

    const handleColorChange = (index, color) => {
        const updatedLabels = [...labels];
        updatedLabels[index]['color'] = color.hex; // Save hex value of the selected color
        setLabels(updatedLabels);
        saveLabels(updatedLabels);
    };

    const handleAddLabel = () => {
        setLabels([...labels, { name: '', description: '', color: '#ffffff' }]);
    };

    const handleRemoveLabel = async (index) => {
        const updatedLabels = [...labels];
        updatedLabels.splice(index, 1);
        setLabels(updatedLabels);
        saveLabels(updatedLabels);

        // return
        if(isSyncToGmailChecked){
            const dataObject = {
                userid: userData.userId,
                accessToken: userData.accessToken,
                label:labels[index]
            };
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/delete_smart_label`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(dataObject)
                });
    
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
    
                const data = await response.json();
                console.log('Label delete successfully:', data);
            } catch (error) {
                console.error('Error delete label:', error);
            }
        }else {
            // setLabels(updatedLabels);
            // saveLabels(updatedLabels);
        }
    };

    const toggleColorPicker = (index) => {
        setShowColorPickerIndex(index === showColorPickerIndex ? null : index);
    };

    const updateUserConfiguration = (configurationName,value) => {
    
        if(!userData.userObject["configuration"]){
            userData.userObject["configuration"] = {}
        }
        userData.userObject["configuration"][configurationName] = value

        // userData.userObject["configuration"][configurationName] = value
        var keyName = "configuration." + configurationName
        const docRef = doc(db, "users", userData.userId);
        updateDoc(docRef, {
            [keyName]: value
          }).then(() => {
            // if (onUpdateLabels) {
            //   onUpdateLabels(labels);
            // }
          });
    }

    const syncSmartLabel = async () => {
        setIsDuplicateError(false)

        // here we check duplicates 
        const encounteredNames = {};
        const duplicates = [];
        for (const label of userData.userObject.labels) {
            const { name } = label;
            if (encounteredNames[name]) {
              duplicates.push(name);
            } else {
              encounteredNames[name] = true;
            }
        }
        if(duplicates.length > 0 ){
            setIsDuplicateError(true)
            return
        }
        
        setIsSyncing(true)
        const dataObject = {
            userid: userData.userId,
            accessToken: userData.accessToken,
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/sync_smart_label`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(dataObject)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();

            if(data.success && data.labels){
                setLabels(data.labels)
            }

            setIsSyncing(false)
        } catch (error) {
            console.error('Error creating label:', error);
        }
    }

    const handleSyncToGmailCheckboxChange = async (checked) => {
        setIsSyncToGmailChecked(checked);
        updateUserConfiguration("isSyncLabelsToGmailEnabled",checked)

        if(checked){
            syncSmartLabel()
        }

    };

    const handleSyncToIMAPCheckboxChange = async (checked) => {
        setIsSyncToIMAPChecked(checked);
        updateUserConfiguration("isSyncLabelsToIMAPEnabled",checked)

        if(checked){
            syncSmartLabel()
        }

    };

    const handleSyncToOutlookCheckboxChange = async (checked) => {
        setIsSyncToOutlookChecked(checked);
        updateUserConfiguration("isSyncLabelsToOutlookEnabled",checked)

        if(checked){
            syncSmartLabel()
        }

    };

    // console.log("Labels state: ", labels);
      
    return (
        <>
            {/* <div className={`bg-white rounded-2xl shadow-lg p-4 duration-300 transform hover:shadow-xl my-4 relative group`}> */}
            <div className={`relative group`}>

                <div className='text-xl font-semibold force-black'>🏷️ Smart Labels</div>

                <div className='mb-4 mt-2 font-light force-black'>
                Create or customize labels by defining their criteria in simple terms. SkimAI will then categorize your emails accordingly.
                </div>   
                {/* <div className='mb-4 mt-2 font-light force-black text-md'>
                    Create or customize labels by defining their criteria in simple terms. SkimAI will then categorize your emails accordingly.
                </div> */}
                <div className="">
                    <div className="grid grid-cols-1 gap-4">
                        {labels && labels.map((label, index) => (
                            <div key={index} className="flex relative">
                                <div
                                    className="w-12 h-10 rounded-lg mr-2 cursor-pointer border"
                                    style={{ backgroundColor: label.color }}
                                    onClick={() => toggleColorPicker(index)}
                                />
                                {showColorPickerIndex === index && (
                                    <div ref={colorPickerRef} className="absolute z-10 top-12">
                                        <TwitterPicker
                                            color={label.color}
                                            onChange={(color) => handleColorChange(index, color)}
                                            className="mr-2"
                                        />
                                    </div>
                                )}
                                <input
                                    type="text"
                                    placeholder="Label Name"
                                    className="w-1/3 mr-2 p-2 border rounded-lg"
                                    value={label.name}
                                    onChange={(e) => handleLabelChange(index, 'name', e.target.value)}
                                    onBlur={()=>{
                                       // this would be nice but i give up
                                        // console.log("on blur!")
                                        // if(isSyncToGmailChecked){
                                        //     syncSmartLabel()
                                        // }
                                    }}
                                />
                                <input
                                    type="text"
                                    placeholder="Description"
                                    className="w-2/3 p-2 border rounded-lg"
                                    value={label.description}
                                    onChange={(e) => handleLabelChange(index, 'description', e.target.value)}
                                />
                                <button
                                    className="px-2 py-2 text-xs font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 ml-2"
                                    onClick={() => handleRemoveLabel(index)}
                                >
                                    🗑️
                                </button>

                                {/* <button
                                    className="px-2 py-2 text-xs font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 ml-2"
                                    onClick={() => {
                                            setIsTestEmailOpened(true)
                                            setSelectedLabel(label)
                                        }
                                    }
                                >
                                    Test
                                </button> */}
                            </div>
                        ))}
                    </div>

                    {isDuplicateError && 
                        <div className='text-red-600 text-xs text-right mt-2'>
                            Cannot save changes and sync with Gmail due to duplicate label names.
                        </div>
                    }

                    <div className="flex justify-between">
                        <button
                            className={`px-2 py-2 text-xs font-medium text-center inline-flex items-center focus:outline-none rounded-lg border border-gray-300 mt-3 ${
                                isSubscriptionActive ? 'text-gray-900 bg-white hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:ring-4 focus:ring-gray-200' : 'text-gray-400 bg-white'
                            } ${!isSubscriptionActive ? 'opacity-50' : ''}`}
                            onClick={handleAddLabel}
                            disabled={!isSubscriptionActive}
                        >
                            ➕ Add Label
                        </button>

                        {((hasGmailConnected && isSyncToGmailChecked) || 
                        (hasIMAPConnected && isSyncToIMAPChecked) || 
                        (hasOutlookConnected && isSyncToOutlookChecked)) &&

                        <button
                            className={`inline-flex px-2 py-2 text-xs font-medium text-center inline-flex items-center focus:outline-none rounded-lg border border-gray-300 mt-3 ${
                                isSubscriptionActive ? 'text-gray-900 bg-white hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:ring-4 focus:ring-gray-200' : 'text-gray-400 bg-white'
                            } ${isSyncing || !isSubscriptionActive ? 'opacity-50' : ''}`}
                            onClick={() => {
                                if (!isSyncing && isSubscriptionActive) {
                                    syncSmartLabel();
                                }
                            }}
                            disabled={isSyncing || !isSubscriptionActive}
                        >
                            {isSyncing && 
                                <svg className={`w-4 h-4 me-2 ${isSyncing ? 'animate-spin' : ''}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.7 7.7A7.1 7.1 0 0 0 5 10.8M18 4v4h-4m-7.7 8.3A7.1 7.1 0 0 0 19 13.2M6 20v-4h4"/>
                                </svg>                    
                            }
                            {isSyncing ? 'Syncing Changes...' : '💾 Save & Sync Changes'}
                        </button>
                        }
                    </div>

                </div>

                {hasGmailConnected && 
                    <div className='grid'>
                    <div className='mt-6 text-lg font-bold'>Gmail Synchronization</div>
                    <div className='flex mt-3'>
                        <label className="inline-flex cursor-pointer">
                            <input
                                type="checkbox"
                                value=""
                                className="sr-only peer"
                                checked={isSyncToGmailChecked}
                                onChange={e => {
                                    handleSyncToGmailCheckboxChange(e.target.checked)
                                }}
                            />
                            <div className={`mt-1 relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer ${isSyncToGmailChecked ? 'peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white' : ''} after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all ${isSyncToGmailChecked ? 'peer-checked:bg-blue-600' : ''}`}>
                            </div>
                        </label>

                        <div>
                            <div className="ms-3 text-md font-bold text-gray-700">Sync with Gmail Labels</div>
                            <div className='ms-3 text-xs w-fit text-gray-500'>
                                Enable this to sync all smart labels with your Gmail account. Unread emails will automatically be marked with the appropriate labels.
                                {/* When enabled, all smart labels will sync with your Gmail account and apply appropriately to your unread emails. */}
                            </div>
                        </div>
                    </div>

                    {isSyncToGmailChecked && 
                        <div className='flex mt-4'>
                            <label class="inline-flex cursor-pointer">
                                <input
                                    type="checkbox"
                                    value=""
                                    class="sr-only peer"
                                    checked={isRemoveFromInboxEnabled}
                                    onChange={e => handleShouldRemoveFromInboxChecked(e.target.checked)}
                                ></input>
                                <div className={`mt-1 relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer ${isRemoveFromInboxEnabled ? 'peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white' : ''} after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all ${isRemoveFromInboxEnabled ? 'peer-checked:bg-blue-600' : ''}`}>
                                </div>
                            </label>     
                            <div>
                                <div className="ms-3 text-md font-bold text-gray-700">Remove Emails from the Inbox</div>
                                {/* <div className="ms-3 text-md font-bold text-gray-700">Move Emails out of the Inbox</div> */}
                                <div className='ms-3 text-xs w-fit text-gray-500'>
                                    When enabled, SkimAI will remove emails associated with specific labels from the inbox. These emails can still be accessed under their respective labels.
                                    {/* When enabled, SkimAI will move emails associated with specific labels from the inbox to their respective dedicated folders. */}
                                </div>
                            </div> 
                        </div>
                    }
                </div>                
                }

                {hasIMAPConnected && 
                    <>
                    <div className='grid'>
                        <div className='mt-6 text-lg font-bold'>iCloud Synchronization</div>
                        <div className='flex mt-3'>
                            <label className="inline-flex cursor-pointer">
                                <input
                                    type="checkbox"
                                    value=""
                                    className="sr-only peer"
                                    checked={isSyncToIMAPChecked}
                                    onChange={e => {
                                        handleSyncToIMAPCheckboxChange(e.target.checked)
                                    }}
                                />
                                <div className={`mt-1 relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer ${isSyncToIMAPChecked ? 'peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white' : ''} after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all ${isSyncToIMAPChecked ? 'peer-checked:bg-blue-600' : ''}`}>
                                </div>
                            </label>

                            <div>
                                <div className="ms-3 text-md font-bold text-gray-700">Sync with iCloud Folders</div>
                                <div className='ms-3 text-xs w-fit text-gray-500'>
                                    Enable this to sync all smart labels with your iCloud account, transforming them into folders. Unread emails will automatically be organized into the appropriate folders.
                                    {/* When enabled, all smart labels will be synced with your iCloud account and transformed into folders. Unread emails will be correctly sorted into the folder corresponding to their smart labels. */}
                                </div>
                            </div>
                        </div>
                    </div>
                    </>
                }

                {hasOutlookConnected && 
                    <>
                    <div className='grid'>
                        <div className='mt-6 text-lg font-bold'>Outlook Synchronization</div>
                        <div className='flex mt-3'>
                            <label className="inline-flex cursor-pointer">
                                <input
                                    type="checkbox"
                                    value=""
                                    className="sr-only peer"
                                    checked={isSyncToOutlookChecked}
                                    onChange={e => {
                                        handleSyncToOutlookCheckboxChange(e.target.checked)
                                    }}
                                />
                                <div className={`mt-1 relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer ${isSyncToOutlookChecked ? 'peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white' : ''} after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all ${isSyncToOutlookChecked ? 'peer-checked:bg-blue-600' : ''}`}>
                                </div>
                            </label>

                            <div>
                                <div className="ms-3 text-md font-bold text-gray-700">Sync with Outlook Folders</div>
                                <div className='ms-3 text-xs w-fit text-gray-500'>
                                    Enable this to sync all smart labels with your Outlook account, transforming them into folders. Unread emails will automatically be organized into the appropriate folders.
                                </div>
                            </div>
                        </div>

                    </div>
                    </>
                }
            </div>

            <TestEmailView
                isOpen={isTestEmailOpened} 
                onClose={() => setIsTestEmailOpened(false)} 
                userData={userData}
                labelData={selectedLabel}
            >
            </TestEmailView>
        </>
    )
}

// export default SmartLabelView;