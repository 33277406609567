import React, { useState, useEffect } from 'react';
import pung from "../../assets/pung.png"

export const ReferFriendBanner = ({ openReferModal }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const hasSeen = localStorage.getItem('hasSeenReferFriendBanner');
        if (!hasSeen) {
            setIsVisible(true);
        }
    }, []);

    const handleHide = () => {
        setIsVisible(false);
        localStorage.setItem('hasSeenReferFriendBanner', 'true');
    };

    if (!isVisible) {
        return null;
    }

    return (
        <div className="bg-white rounded-2xl shadow-lg p-4 duration-300 transform hover:shadow-xl my-4 flex items-start space-x-4">
            <div className="flex-shrink-0">
                <img className="h-16 w-16 rounded-full" src={pung} alt="Profile Picture"/>
            </div>
            <div className="flex-1 flex flex-col justify-between">
                <div>
                    <p className="text-gray-900 text-lg font-semibold">📢 Save 50% and Refer a Friend!</p>
                    <p className="text-gray-600">Invite your friends to SkimAI and receive 50% off your subscription for two months. Help them streamline their email management while you get twice the value!</p>
                </div>
                <div className="mt-4 flex space-x-2">
                    <button type="button" className="px-3 py-2 text-sm font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
                    onClick={() =>{
                        openReferModal();
                        handleHide();
                    }}>
                        <svg className="w-5 h-5 mr-2 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"/>
                        </svg>
                        Refer Now
                    </button>
                    <button onClick={handleHide} className="bg-gray-400 hover:bg-gray-500 text-sm text-gray-700 text-white font-bold py-2 px-4 rounded-lg shadow">
                        Maybe Later
                    </button>
                </div>
            </div>
        </div>
    );
};
