import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from "firebase/analytics";
import 'firebase/auth';
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from 'firebase/auth';
import 'firebase/firestore';
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from "firebase/firestore";

// Initialize Firebase
// Production 
const firebaseConfig = {
    apiKey: "AIzaSyBqnew1PQpDhJUO9QQCBrENDD5JrulcKlc",
    authDomain: "pitchtopick.firebaseapp.com",
    projectId: "pitchtopick",
    storageBucket: "pitchtopick.appspot.com",
    messagingSenderId: "383834544402",
    appId: "1:383834544402:web:cf6485fe509f0d2ee57bcb",
    measurementId: "G-08X42Q1BL2"
  };

// deciding url enpoint for python
let pythonAPIEndpoint = ""

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  pythonAPIEndpoint = "http://127.0.0.1:8000"
} else {
  pythonAPIEndpoint = "https://pitchtotext-dgx47zsmyq-ew.a.run.app" 
}

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const analytics = getAnalytics(app);

// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyBHMfARQIIy-6RztdBs_Syv2Sa6d-IYXuQ",
//   authDomain: "pitchtopick.firebaseapp.com",
//   projectId: "pitchtopick",
//   storageBucket: "pitchtopick.appspot.com",
//   messagingSenderId: "383834544402",
//   appId: "1:383834544402:web:52531bd3b427b587e57bcb",
//   measurementId: "G-N9RZEFT4B2"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);

// Define Google Authentication function
const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  logEvent(analytics, 'Signin With Google Clicked');

  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;

    // Check if the user exists in Firestore
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      // User doesn't exist in Firestore, add them
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
        subscription_active: true,
      });
    }
    return user;
  } catch (err) {
    console.error(err);
    alert(err.message);
    return null;
  }
};

// Define login function
const logInWithEmailAndPassword = async (email, password) => {
  logEvent(analytics, 'Login With Email Clicked');
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    // alert(err.message);
  }
};

// Define register function
const registerWithEmailAndPassword = async (name, email, password) => {
  logEvent(analytics, 'Signup With Email Clicked');
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
      subscription_active: true,
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

// Define password reset function
const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

// Define the logout function
const logout = () => {
  signOut(auth);
};

export {
  app,
  auth,
  db,
  pythonAPIEndpoint,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
};