import React, { useState, useRef, useCallback, useEffect } from 'react';
import styles from './Editor.module.css';
import './CardFloat.css';
import NavBar from '../NavBar/NavBar.tsx' // i dont fucking get reactjs why
import { db, auth, pythonAPIEndpoint} from '../../config/firebaseConfig.js';

import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  ReactFlowProvider,
} from 'reactflow';

import 'reactflow/dist/style.css';
import Sidebar from './Sidebar.tsx';
import Application1 from '../Application/Application1.tsx'
import Application2 from '../Application/Application2.tsx'
// import './Sidebar.css'

import QuestionsNode from './QuestionsNode/QuestionsNode.tsx';
import FileUploadNode from './FileUploadNode/FileUploadNode.tsx';
import SummaryNode from './SummaryNode/SummaryNode.tsx';
import PromptingNode from './PromptingNode/PromptingNode.tsx';
import ReadFileNode from './ReadFileNode/ReadFileNode.tsx';
import CreatePDFNode from './CreatePDFNode/CreatePDFNode.tsx';
import QAndANode from './QandANode/QandANode.tsx';
import BrowseWebNode from './BrowseWebNode/BrowseWebNode.tsx';

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { useAuthState } from "react-firebase-hooks/auth";

// import TimeAgo from 'javascript-time-ago'
// import en from 'javascript-time-ago/locale/en.json'
// TimeAgo.addDefaultLocale(en)

import ReactTimeAgo from 'react-time-ago'
import { Delete } from 'baseui/icon';

//This is the part where we should save it and fetch it from the firebase
//TODO: split this to another file 
const FlowName = () => {
  const [agentName, setAgentName] = useState("");
  const [lastUpdate, setLastUpdate] = useState(new Date())
  const [searchParams, setSearchParams] = useSearchParams();

  const handleNameChange = (event) => {
    setAgentName(event.target.value);

    const agentId = searchParams.get('agentId') 
    if(agentId){
      const nodeRef = doc(db, "agents", agentId);
      updateDoc(nodeRef, {
        name:event.target.value,
        updatedAt: new Date()
      });
    }

  };

  useEffect(()=>{
    const view = searchParams.get('view') 
    const fetchData = async () => {
      const agentId = searchParams.get('agentId') 
      if(agentId){
        const agentRef = doc(db, "agents", agentId);
        const docSnap = await getDoc(agentRef);
        if (docSnap.exists()) {
          var data = docSnap.data()
          setAgentName(data.name)
          // setLastUpdate(data.updatedAt.toDate())
          console.log("Document data:", docSnap.data());
        } else {
          // docSnap.data() will be undefined in this case
          console.log("No such document!");
        }
      }
    }

    fetchData()

  }, []) 

  return (
    <div className={styles.flowName}>
      <div className={styles.nameWrapper}>
        <input
          type="text"
          value={agentName}
          onChange={handleNameChange}
          className={styles.name}
        />
        <span className={styles.date}>last updated: <ReactTimeAgo date={lastUpdate} locale="en-US"/></span>
      </div>
    </div>
  );
};

//save both nodes and edges and load it here 
const initialNodes = [
  { id: '1', position: { x: 0, y: 0 }, deletable: false, data: { label: 
    (
      <>
        <strong>🏁 START</strong>
      </>
    )
   } },
  // { id: '2',  type: 'fileUploadNode', position: { x: -50, y: 100 }, data: { label: 'test' } },
];

const initialEdges = [];

const nodeTypes = { 
  questionsNode: QuestionsNode,
  fileUploadNode: FileUploadNode,
  summaryNode:SummaryNode,
  promptingNode:PromptingNode,
  readFileNode:ReadFileNode,
  createPDFNode:CreatePDFNode,
  qandaNode: QAndANode,
  browseWebNode:BrowseWebNode
};

const minimapStyle = {
  height: 120,
};

let id = 0;
const getId = () => `dndnode_${id++}`;
const proOptions = { hideAttribution: true };

const Editor = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [user, loading, error] = useAuthState(auth);
  const [isAppShown, setIsAppShown] = useState(false);
  const [isCardSlideIn, setIsCardSlideIn] = useState(false);

  const [hasQandANode, setHasQandANode] = useState(false);
  const [hasCreatePDFNode, setHasCreatePDFNode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCardVisible, setIsCardVisible] = useState(false);

  const handleBuildAndRunClick = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsAppShown(true);
      // setIsCardVisible(true)
      searchParams.set("view","app")
      setSearchParams(searchParams)
      setIsLoading(false);
      setTimeout(() => {
        setIsCardSlideIn(true)
        console.log("here")
      }, 100); 
    }, 1500); 
  };

  const handleEditClick = () => {
    setIsAppShown(false)
    setIsCardSlideIn(false)
    searchParams.set("view","edit")
    setSearchParams(searchParams)
  }

  useEffect(() => {
    // Fetch data from Firestore
    
    if(user){
      console.log(user?.uid)
      // setUserId(user?.uid)
    }

    const fetchData = async () => {
      const agentId = searchParams.get('agentId') 
      if(!user){return}
      const q = query(collection(db, "items"), 
                      where("agentId", "==", agentId),
                      where("owner", "==", user?.uid));
      const querySnapshot = await getDocs(q);
      const dataArray = [];
      querySnapshot.forEach((doc) => {
          const loadednodes = querySnapshot.docs
          .filter((doc) => doc.data().itemType === "node")
          .map((doc) => ({
              ...doc.data()
            }));

          if(loadednodes.some(obj => obj.hasOwnProperty("type") && obj["type"] === "qandaNode")){
            setHasQandANode(true)
          }

          if(loadednodes.some(obj => obj.hasOwnProperty("type") && obj["type"] === "createPDFNode")){
            setHasCreatePDFNode(true)
          }
          // obj.hasOwnProperty(key) && obj[key] === value;
          setNodes((nodes) => nodes.concat(loadednodes));

          // setNodes(loadednodes)

          const edges = querySnapshot.docs
          .filter((doc) => doc.data().itemType === "edge")
          .map((doc) => ({
              ...doc.data()
            }));

          setEdges(edges)
      });
    }
    fetchData()

  }, [user, loading]);

  useEffect(()=>{
    const view = searchParams.get('view') 

    if(view === "app"){
      setIsAppShown(true)
      setIsCardSlideIn(true)
    }else if(view === "edit"){
      setIsAppShown(false)
      setIsCardSlideIn(false)
    }

    // //load agent thing here name here
    // const agentId = searchParams.get('agentId') 
  }, []) 

  
  
  const onConnect = useCallback((params) => {
    setEdges((eds) => { 
      //eds = all edges before added 
      //params = new one to be added 
      var uuid = uuidv4();
      const agentId = searchParams.get('agentId') 
      params["animated"] = true
      params["id"] = uuid
      params["agentId"] = agentId
      params["itemType"] = 'edge'
      params["owner"] = user?.uid

      if(eds.some(obj => obj.source === params.source && obj.target === params.target)){
        console.log("the edge already exist, do nothing")
      } else {
        console.log("new edges!")
        if(agentId){
          setDoc(doc(db, "items", uuid), params).then(() => {})
        }
      }
      // console.log(params)
      // console.log(eds)
      return addEdge(params, eds);
    })

  }, [user, loading]);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData('application/reactflow');

      // check if the dropped element is valid
      if (typeof type === 'undefined' || !type) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });

      //TODO: use uuid instead of getId()
      const agentId = searchParams.get('agentId') 
      var uuid = uuidv4();
      console.log(user) // why is this null?
      const newNode = {
        id: uuid, 
        agentId: agentId,
        owner: user?.uid,
        itemType: 'node',
        type,
        position,
        data: { label: `${type} node` },
      };

      console.log("Node added:",newNode)
      setNodes((nds) => nds.concat(newNode));
      delete newNode["selected"]
      // delete newNode["dragging"]

      if(agentId){
        setDoc(doc(db, "items", uuid), newNode).then(() => {})
      }

      if (type === "qandaNode"){
        setHasQandANode(true)
      }

      if (type === "createPDFNode"){
        setHasCreatePDFNode(true)
      }

    },
    [reactFlowInstance,user]
  );

  useEffect(() => {
    console.log(searchParams.get('agentId'))
    // ...
   },[]);

   const onNodesDelete = (nodes) => {
    console.log('Node deleted:', nodes);
    for (const node of nodes) {
      deleteDoc(doc(db, "items", node.id));
      if(node.type === "qandaNode"){
        setHasQandANode(false)
      }

      if (node.type === "createPDFNode"){
        setHasCreatePDFNode(false)
      }
    }
  };

  const onEdgesDelete = (edges) => {
    console.log("Edges deleted:", edges)

    for (const edge of edges) {
      deleteDoc(doc(db, "items", edge.id));
    }
  }

   const onNodeDragStop = (event, node) => {
    // Perform your desired action here
    console.log('Node drag stop:', node);
    const nodeRef = doc(db, "items", node.id);
    delete node["selected"]
    delete node["dragging"]
    // console.log(node)
    updateDoc(nodeRef, node);

  };

  return (
    <div className={styles.Editor}>
      <NavBar></NavBar>

        {/* {isAppShown &&
         <div>
          <button className={styles.playButton} onClick={handleEditClick}> ✍🏻 EDIT</button>
          <Application1 hasQandANode={hasQandANode} agentId={searchParams.get('agentId')}></Application1>
         </div>
        } */}

        
        <div className="dndflow">
          <ReactFlowProvider>
            <Sidebar/>
            <div className="reactflow-wrapper" ref={reactFlowWrapper} style={{ width: '100vw', height:window.innerHeight - 60 }}>
            {!isAppShown &&
            <div>
              <button className={styles.playButton} onClick={handleBuildAndRunClick}>
              {isLoading ? 'BUILDING ...' : '▶️ BUILD'}
              </button>

              {/* DEPLOY BUTTON HERE ... */}
              {/* <button className={styles.playButton} onClick={handleBuildAndRunClick}>
              {isLoading ? 'BUILDING ...' : '▶️ DEPLOY'}
              </button> */}
            </div>              
            }
             {/* <Application2 hasQandANode={hasQandANode} agentId={searchParams.get('agentId')}></Application2> */}

              {isAppShown &&
                <div className={`CardFloat ${isCardSlideIn ? 'slide-in' : ''}`}>
                  <button className={styles.closeButton} onClick={handleEditClick}> 
                  <Delete 
                    color="gray"
                    size={22} 
                  />   
                  </button>
                  <Application2 hasQandANode={hasQandANode} hasCreatePDFNode={hasCreatePDFNode} agentId={searchParams.get('agentId')}></Application2>
                </div>
              }

              <ReactFlow
                  nodes={nodes}
                  edges={edges}
                  onNodesChange={onNodesChange}
                  onNodesDelete={onNodesDelete}
                  onNodeDragStop={onNodeDragStop}
                  onEdgesChange={onEdgesChange}
                  onEdgesDelete={onEdgesDelete}
                  onConnect={onConnect}
                  onInit={setReactFlowInstance}
                  onDrop={onDrop}
                  onDragOver={onDragOver}
                  nodeTypes={nodeTypes}
                  proOptions={proOptions}
                  fitView
                >

            {/* TODO Make this another component! */}
                  <FlowName />
                  <MiniMap style={minimapStyle} zoomable pannable />
                  <Controls />
                  <Background variant="dots" gap={12} size={1} />
              </ReactFlow>
            </div>
          </ReactFlowProvider>        
        </div>
        


    </div>
  )
}

export default Editor;
