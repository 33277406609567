import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import matt from "../../assets/matt.png"


export const PersonalOnboardView = ({ onHide }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const hasSeen = localStorage.getItem('hasSeenPersonalOnboardView');
        if (!hasSeen) {
            setIsVisible(true);
        }
    }, []);

    const handleHide = () => {
        setIsVisible(false);
        localStorage.setItem('hasSeenPersonalOnboardView', 'true');
        if (onHide) onHide();
    };

    if (!isVisible) {
        return null;
    }

    return (
        <div className="bg-white rounded-2xl shadow-lg p-4 duration-300 transform hover:shadow-xl my-4 flex items-start space-x-4">
            <div className="flex-shrink-0">
                <img className="h-16 w-16 rounded-full" src={matt} alt="Profile Picture"/>
            </div>
            <div className="flex-1 flex flex-col justify-between">
                <div>
                    <p className="text-gray-900 text-lg font-semibold">👋🏻 Need Assistance Setting Up Your Account?</p>
                    <p className="text-gray-600">I'm here to help you unlock the full potential of our service! Get personalized setup and onboarding support tailored to your needs.</p>
                </div>
                <div className="mt-4 flex space-x-2">
                    <button type="button" className="px-3 py-2 text-sm font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
                    onClick={() =>{
                        window.open('https://calendar.app.google/FP169GTA9Wtco7MN7', '_blank');
                        handleHide();
                    }}>
                        <svg className="w-5 h-5 mr-2 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 10h16m-8-3V4M7 7V4m10 3V4M5 20h14a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Zm3-7h.01v.01H8V13Zm4 0h.01v.01H12V13Zm4 0h.01v.01H16V13Zm-8 4h.01v.01H8V17Zm4 0h.01v.01H12V17Zm4 0h.01v.01H16V17Z"/>
                        </svg>
                        Book Your Free Call
                    </button>
                    <button onClick={handleHide} className="bg-gray-400 hover:bg-gray-500 text-sm text-gray-700 text-white font-bold py-2 px-4 rounded-lg shadow">
                        Maybe Later
                    </button>
                </div>
            </div>
        </div>
    );
};