import React, { useState, useEffect, useContext, useMemo } from 'react';
import './Email.css';
import NavBar from '../NavBar/NavBar.tsx'
import Footer from '../Footer/Footer.tsx' 
import { useAuthState } from 'react-firebase-hooks/auth';
// import { db, auth, pythonAPIEndpoint} from '../../config/firebaseConfig.js';
import { db, auth } from '../../config/firebaseConfig.js';
import { collection, query, where, getDocs, limitToLast } from "firebase/firestore";
import { doc, updateDoc, getDoc } from 'firebase/firestore'
import { useLocation } from 'react-router-dom';

import { UserContext } from '../../UserContext.js';
import { PersonalOnboardView } from './PersonalOnboardView.js'
import { ReferFriendBanner } from './ReferFriendBanner.js'
import { SingleEmailView } from './SingleEmailView.js'
import { ConnectEmailView } from './ConnectEmailView.js';
import { EmailAnalyticsView } from './EmailAnalyticsView.js'
import { SmartLabelView } from './SmartLabelView.js'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import { getAnalytics, logEvent } from '@firebase/analytics';
import email_hero1 from "../../assets/email-icon2.png"
import calendar_icon from "../../assets/calendar-icon2.png"
import automation_icon from "../../assets/automation-icon.png"
import label_icon from "../../assets/label-icon.png"
import analytics_icon from "../../assets/analytics-icon.png"
import todos_icon from "../../assets/todos-icon.png"

import onboard_1 from "../../assets/onboard-1.png"
import settings_icon from "../../assets/settings-icon3.png"
import { useNavigate } from 'react-router-dom';
import { oembed } from "@loomhq/loom-embed";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE,
  ROLE
} from "baseui/modal";
import { KIND as ButtonKind } from "baseui/button";
import {
  Checkbox,
  STYLE_TYPE,
  LABEL_PLACEMENT
} from "baseui/checkbox";
import { TrainingDataView } from './TrainingDataView.js';
import { OnboardView } from './OnboardView.js';
import { TutorialView } from './TutorialView.js';
import { ReferFriendView } from './ReferFriendView.js';
import { AutomationView } from './AutomationView.js';
import { TodosView } from './TodosView.js';

function Email() {
    const analytics = getAnalytics();

    const [isConnected, setIsConnected] = useState(false);
    const [selectedEmailData,setSelectedEmailData] = useState("");
    const [isDraftEmailChecked, setIsDraftEmailChecked] = useState(false);
    const [isCalendarAssistantChecked, setIsCalendarAssistantChecked] = useState(false);

    const [isEmailConnected, setIsEmailConnected] = useState(false);
    const [isCalendarConnected, setIsCalendarConnected] = useState(false);

    const [toneOfVoice, setToneOfVoice] = useState("");
    const [emailSignature, setEmailSignature] = useState("");
    const [preferredLanguage, setPreferredLanguage] = useState("")
    const [initialPreferredLanguage, setInitialPreferredLanguage] = useState(preferredLanguage);
    const [languageChanged, setLanguageChanged] = useState(false);
    const [schedulingToolLink, setSchedulingToolLink] = useState("");
    const [exclusionFilter, setExclusionFilter] = useState("");
    const [draftStyle, setDraftStyle] = useState("");
    const [isSubscriptionActive, setisSubscriptionActive] = useState(false)

    const [emailsSummary, setEmailsSummary] = useState([]);

    const [userObject, setUserObject] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isFirstLoading, setIsFirstLoading] = useState(true);
    const [isEmailOpen, setIsEmailOpen] = useState(false);
    const [isConnectEmailOpen, setIsConnectEmailOpen] = useState(false);
    const [isSmartLabelOpen, setIsSmartLabelOpen] = useState(false);
    const [isTrainingDataOpen, setIsTrainingDataOpen] = useState(false);

    const [user, loading, error] = useAuthState(auth);
    
    const { userId, setUserId } = useContext(UserContext);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');

    const [isConfirmationOpen, setIsConfirmationOpen] = React.useState(false);
    const [isWritingStyleOpen, setIsWritingStyleOpen] = React.useState(false);
    const [isEmailSignatureOpen, setIsEmailSignatureOpen] = React.useState(false);
    const [isPreferredLanguageOpen,setIsPreferredLanguageOpen] = React.useState(false);
    const [isFetched, setIsFetched] = React.useState(false);
    const [isInstructionsOpen, setIsInstructionsOpen] = useState(false);
    const [isOnBoardOpen, setIsOnBoardOpen] = useState(false);

    const [isMeetingSchedulerOpen, setIsMeetingSchedulerOpen] = useState(false);

    const [itemToDisconnect, setItemToDisconnect] = useState({ type: '', value: '' });
    const [tokenToRemove,setTokenToRemove] = useState({type:'',index:0, email: ''});

    // const [tokenToDisconnect, setTokenToDisconnect] = useState({ token: {}, index: 0 });

    const [isDisconnectLoading, setIsDisconnectLoading] = React.useState(false);

    const [isReauthRequired, setIsReauthRequired] = React.useState(false);
    const [emailToReauth, setEmailToReauth] = useState('');

    const [data, setData] = useState([]);

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; 

    const [streamingData, setStreamingData] = useState('');
    const textDecoder = useMemo(() => new TextDecoder(), []);

    const [parent, enableAnimations] = useAutoAnimate(/* optional config */)
    const [isNewBadgeVisible, setIsNewBadgeVisible] = useState(true);
    const [showReferFriendBanner, setShowReferFriendBanner] = useState(false);

    const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
    const [isReferModalOpen, setIsReferModalOpen] = useState(false);
    const [videoHTML, setVideoHTML] = useState("");
    const [selectedLabel, setSelectedLabel] = useState({label:{},index: null});

    const [trainingData, setTrainingData] = useState([])
    // const [hasSeenOnboardEmailConnected, setHasSeenOnboardEmailConnected] = useState(false)
    const navigate = useNavigate();

    // useEffect(() => {
    //   // const hasSeenVideo = localStorage.getItem('hasSeenDemoVideo');

    //   // should only do this when no email connected ...
    //   // embedLoomVideo();

    //   // if (!hasSeenVideo) {
    //   //   // embedLoomVideo();
    //   //   setIsVideoModalOpen(true);
    //   // }
    // }, []);


    //dont delete this, we need it so that the view redraw
    const handlePersonalOnboardHide = () => {
      setShowReferFriendBanner(true);
    };

    const openVideoModal = () => {
      // if (!videoHTML) {
      //   embedLoomVideo();
      // }
      setIsVideoModalOpen(true);
    };

    const closeVideoModal = () => {
      setIsVideoModalOpen(false);
      localStorage.setItem('hasSeenDemoVideo', 'true');
    };

    const openReferModal = () => {
      setIsReferModalOpen(true);
    };

    const closeReferModal = () => {
      setIsReferModalOpen(false);
    };

    async function embedLoomVideo() {
        const loomUrl = 'https://www.loom.com/share/900a4bd6b76f4a48babf35d0215ca105?sid=2170116f-7d2c-4242-b9ce-fb30b47c2196';
        const { html } = await oembed(loomUrl);
        setVideoHTML(html);
    }

    useEffect(() => {
        logEvent(analytics, 'screen_view', {
            firebase_screen: location.pathname,
            firebase_screen_class: 'firebase-routes-analytics', 
        });
    }, [location]);

    useEffect(() => {
      if (isPreferredLanguageOpen) {
        setInitialPreferredLanguage(preferredLanguage);
      }
    }, [isPreferredLanguageOpen, preferredLanguage]);

    useEffect(() => {
      // Check localStorage to determine whether to show the badge
      const isNewBadgeVisibleInLocalStorage = localStorage.getItem('isNewBadgeVisible');
      setIsNewBadgeVisible(isNewBadgeVisibleInLocalStorage === null ? true : JSON.parse(isNewBadgeVisibleInLocalStorage));
    }, []);

    const fetchData = async () => {
      var initialSummaries = JSON.parse(localStorage.getItem("arrayOfEmailSummary")) ?? [];
      // reset the show state on start
      initialSummaries = initialSummaries.map(email => ({
        ...email,
        show: true,
      }));
      setEmailsSummary(initialSummaries);

      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get_emails_summary`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                userid: userId,
                accessToken: user.accessToken,
                cacheResultIds: initialSummaries.map(element => element.id),
                userTimeZone: userTimeZone,
            })
        });

        const reader = response.body.getReader();
        let accumulatedData = '';
        let allUnreadIdsAccumulated = [];
        
        // Read the stream
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            setIsLoading(false)
            
            // The following lines of code are responsible for updating the cache with the BE list
            // of unread emails. Particularly relevant when an email is marked as read in the original inbox
            // and we want to propagate the state in Skim.
            setEmailsSummary(prevState => {
              const finalSummaries = prevState.filter(summary => allUnreadIdsAccumulated.includes(summary.id));
              console.log(finalSummaries) // why is this empty?
              // localStorage.setItem("arrayOfEmailSummary", JSON.stringify(finalSummaries));
              try {
                localStorage.setItem("arrayOfEmailSummary", JSON.stringify(finalSummaries));
              } catch (error) {
                // TODO: track this pls
                console.error("Error saving data to localStorage:", error);
              }
              return finalSummaries;
            });
            break
          };

          accumulatedData += textDecoder.decode(value);

          // Process the stream
          while (true) {
            // Here we extract the single summary item (single line) from the stream sent by the BE
            const newlineIndex = accumulatedData.indexOf('\n');
            if (newlineIndex === -1) break; // Check if a new line is availabe - Move on to the next chunk if it is not
            const line = accumulatedData.slice(0, newlineIndex); // Extract line
            accumulatedData = accumulatedData.slice(newlineIndex + 1); // Remove line from accumulatedData

            if (line) {
              try {                  
                const emailSummary = JSON.parse(line);
                // Extract only the line with the full list of unread emails
                if (emailSummary.id === "unread_ids") {
                  allUnreadIdsAccumulated = [...allUnreadIdsAccumulated, ...emailSummary.allUnreadIds];
                } else {
                  
                  setEmailsSummary(prevState => {
                    
                    // Check if the current email summary being processed does not already exist in the previous state.
                    // This prevents duplicates.
                    if (!prevState.some(obj => obj.id === emailSummary.id)) {
                      // If the email is new, create a copy of the previous state to maintain immutability.
                      const newState = [...prevState];
                      // Insert the new email summary into the newState array in sorted order.
                      insertInSortedOrder(newState, emailSummary);

                      // Update localStorage to ensures that the persistent cache is synchronized with the current state of the email summaries.
                      // The entire newState array is stringified and saved, replacing the previous data.
                      // localStorage.setItem("arrayOfEmailSummary", JSON.stringify(newState));
                      try {
                        localStorage.setItem("arrayOfEmailSummary", JSON.stringify(newState));
                      } catch (error) {
                        // Handle the error gracefully, you can log it or show a user-friendly message
                        console.error("Error saving data to localStorage:", error);
                      }
                      // Return the newState with newly added email summary to update the state of email summaries
                      return newState;
                    }
                    // If the email summary already exists in the previous state returns the previous state unchanged.
                    return prevState;
                  });
                }
              } catch (parseError) {
                console.error('Error parsing JSON:', parseError);
              }
            }
          }
        }
      } catch (error) {
        console.error('Error fetching streaming data:', error);
      }
    };
    useEffect(() => {
      if (userId && user && user.accessToken && !isFetched) {
        if (isSubscriptionActive) {
          setIsFetched(true);
          fetchData();
        } else {
          setIsLoading(false);
        }
      }

      // can  filter it here?
      // console.log(selectedLabel)
      // console.log(emailsSummary)

      // const updatedEmailsSummary = emailsSummary.map(email => ({
      //   ...email,
      //   show: email.label === selectedLabel.label.name ? true : false,
      // }));

      // // console.log(updatedEmailsSummary)
      // setEmailsSummary(updatedEmailsSummary)

      if(selectedLabel.index != null && emailsSummary.some(email => !email.hasOwnProperty('show'))){
        const updatedEmailsSummary = emailsSummary.map(email => ({
          ...email,
          show: email.label === selectedLabel.label.name ? true : false,
        }));

        // console.log(updatedEmailsSummary)
        setEmailsSummary(updatedEmailsSummary)
      }
      

    }, [userId, user, emailsSummary]);

    // Helper function to insert a summary in the correct chronological position
    const insertInSortedOrder = (summaries, newSummary) => {
      const index = summaries.findIndex(summary => new Date(summary.date) < new Date(newSummary.date));
      if (index === -1) {
        summaries.push(newSummary); // If it's the latest, add to the end
      } else {
        summaries.splice(index, 0, newSummary); // Else insert at the correct position
      }
    };

    // Function to dispatch a custom event signaling that localStorage has been updated
    // Required to allow the sync between handleFinishAction and stream of summaries
    const dispatchLocalStorageUpdatedEvent = () => {
      window.dispatchEvent(new CustomEvent('localStorageUpdated'));
    };

    useEffect(() => {
      // Defines a function to update email summaries from localStorage when an event is triggered.
      const handleLocalStorageUpdate = () => {
        // Retrieves email summaries from localStorage, defaulting to an empty array if not found.
        const updatedEmailSummaries = JSON.parse(localStorage.getItem("arrayOfEmailSummary")) ?? [];
        // Updates component state with the latest email summaries.
        setEmailsSummary(updatedEmailSummaries);
      };
    
      // Attaches the above function as a listener to custom 'localStorageUpdated' events.
      window.addEventListener('localStorageUpdated', handleLocalStorageUpdate);
    
      // Cleanup function: Removes the event listener when the component unmounts.
      return () => {
        window.removeEventListener('localStorageUpdated', handleLocalStorageUpdate);
      };
    }, []); // Effect dependency array is empty, so this runs once on mount and cleanup on unmount.
    
    const handleItemClick = (item) => {
      // setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
      setSelectedEmailData(item)
      setIsEmailOpen(true)
      logEvent(analytics, 'Email View: Email Opened');
    };

    const archiveItemClick = async (item) => {

      handleFinishAction(item)
      try {
        logEvent(analytics, 'Email View: Archive Clicked');
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/archive_email`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                userid: userId,
                accessToken: user?.accessToken,
                emailAddressId: item.toEmail,
                threadId: item.threadId,
                unreadMessageIds: item.unreadMessageIds,
            })
        });
        const data = await response.json();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    const trashItemClick = async (item) => {
      handleFinishAction(item)
      try {
        logEvent(analytics, 'Email View: Trash Clicked');
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/delete_email`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                userid: userId,
                accessToken: user?.accessToken,
                emailAddressId: item.toEmail,
                threadId: item.threadId,
                unreadMessageIds: item.unreadMessageIds,
            })
        });
        const data = await response.json();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const markAsReadItemClick = async (item) => {
      handleFinishAction(item)
      try {
        logEvent(analytics, 'Email View: Mark as Read Clicked');
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/mark_email_read`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                userid: userId,
                accessToken: user?.accessToken,
                emailAddressId: item.toEmail,
                threadId: item.threadId,
                unreadMessageIds: item.unreadMessageIds,
            })
        });
        const data = await response.json();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    
    const handleRequireReAuth = (email) =>{
      setEmailToReauth(email);
      setIsReauthRequired(true); 
    }

    const handleFinishAction = (emailData) => {
      const updatedEmailsSummary = emailsSummary.filter(email => email.id !== emailData.id);
      setEmailsSummary(updatedEmailsSummary)
      try {
        localStorage.setItem("arrayOfEmailSummary", JSON.stringify(updatedEmailsSummary));
      } catch (error) {
        // TODO: track this pls
        console.error("Error saving data to localStorage:", error);
      }
      // localStorage.setItem("arrayOfEmailSummary", JSON.stringify(updatedEmailsSummary));
      dispatchLocalStorageUpdatedEvent();
    }

    const handleEmailConnectionAction = (email_type) => {
      initiateConnection(email_type)
    }
    
    // this whole function is insane. we dont need any of these ...
    const updateUserConfiguration = async (key, value) => {
      setIsLoading(true); // Show loading state
  
      try {
        
        const userDocRef = doc(db, "users", userId);

        // I dont think we need this since we can check it from userObject anyway?
        const userDoc = await getDoc(userDocRef);
  
        if (userDoc.exists()) {
          const currentConfiguration = userDoc.data().configuration || {};
          const newConfiguration = { ...currentConfiguration, [key]: value };
          
          await updateDoc(userDocRef, { configuration: newConfiguration });
        }
      } catch (error) {
        console.error("Error updating user configuration:", error);
        // Handle the error appropriately
      } finally {
        setIsLoading(false); // Hide loading state
      }
    };

    const handleCheckboxChange = (feature, isChecked) => {
      switch (feature) {
        case 'calendarAssistant':
          setIsCalendarAssistantChecked(isChecked);
          updateUserConfiguration('isCalendarAssistantEnabled', isChecked);
          // update userObject here 
          var tempConfig = userObject.configuration
          tempConfig["isCalendarAssistantEnabled"] = isChecked
          setUserObject({
            ...userObject,
            configuration: tempConfig
          });

          break;
        case 'draftEmail':
          setIsDraftEmailChecked(isChecked);
          updateUserConfiguration('isDraftEmailEnabled', isChecked);
          break;
        default:
          console.error('Unsupported feature:', feature);
      }
    };
    
    useEffect(() => {
      const getData = async () =>  {
        if(user){
          const q = query(collection(db, "users"), where("uid", "==", user?.uid));
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((document) => {
            const userData = document.data();

            setisSubscriptionActive(userData.subscription_active);
            
            if(!isSubscriptionActive){
              localStorage.removeItem("arrayOfEmailSummary");
            }

            // if(!userData.subscription_active){
            //   navigate("/chooseplan")
            //   // navigate("/waitlist")
            //   return;
            // }
            
            // just for debugging
            // setIsAutoDraftOnBoardOpen(true)

            // bad code but works
            if(userData.tokens && userData.tokens.emailTokens && userData.tokens.emailTokens.length){
              
              const hasSeenOnboardEmailConnected = localStorage.getItem('hasSeenOnboardEmailConnected')
              if(!hasSeenOnboardEmailConnected){
                // This is basically after email connected
                setIsOnBoardOpen(true)
                localStorage.setItem('hasSeenOnboardEmailConnected', 'true');
              }
            }

            if (!userData.labels || (Array.isArray(userData.labels) && userData.labels.length === 0) || (userData.labels && typeof userData.labels === 'object' && Object.keys(userData.labels).length === 0)) {
            // if(!userData.labels){
              userData.labels = [
                {color: "#EB144C", name: "Urgent", description: "If the email requests an immediate action"},
                {color: "#0693E3", name: "Meeting", description: "If the email contains invitation to events, gatherings, meetings, or webinars, along with RSVP requests and event-related details"}, 
                {color: "#FCB900", name: "Customer Support", description: "If the email request a support or assistance"}, 
                {color: "#00D084", name: "Newsletter", description: "If the email is a newsletter, contains curated content, updates, announcements, or promotions"},
                {color: "#ABB8C3", name: "Promotions", description: "If the email is promotions, discounts, and sales offers"},
              ]

              const docRef = doc(db, "users", document.id);
              updateDoc(docRef, {
                  labels: userData.labels
              });

            }

            setUserObject(userData);
            setIsFirstLoading(false)
            setUserId(document.id);

            const defaultToneOfVoice = "Polite and constructive.";
            const defaultEmailSignature = "<br><div>Sent via <a href='http://www.skim.page?utm_source=SkimAI&utm_medium=email&utm_campaign=email_tool'>SkimAI⚡️</a></div>"
            const defaultPreferredLanguage = "English";

            setToneOfVoice(userData.tone_of_voice || defaultToneOfVoice);
            setEmailSignature(userData.email_signature ||defaultEmailSignature);
            setPreferredLanguage(userData.language || defaultPreferredLanguage);
            setSchedulingToolLink(userData.scheduling_tool_link);

            // const defaultExclusionFilter = "An email requires a reply if it asks a direct question, implies a needed action, is marked as urgent, or if not replying could be perceived as unprofessional or unhelpful. Exclude any emails that are from mailing lists or do not require a direct response.";

            const defaultExclusionFilter = "An email requires a reply.";
            setExclusionFilter(userData.exclusion_filter || defaultExclusionFilter);

            // const defaultDraftStyle = "Compose a reply to the sender, addressing any instructions or queries included in the email. Do not repeat the information already provided in the email and do not include the subject in the reply. Use the language used by the sender.";

            const defaultDraftStyle = "Compose a reply. Do not repeat the information already provided in the email and do not include the subject in the reply.";
            setDraftStyle(userData.draft_style || defaultDraftStyle);

            // Check if email tokens are connected
            const emailTokens = userData.tokens?.emailTokens || [];
            setIsEmailConnected(emailTokens.length > 0);
    
            // Check if calendar tokens are connected
            const calendarTokens = userData.tokens?.calendarTokens || [];
            setIsCalendarConnected(calendarTokens.length > 0);

            if(userData.configuration){
              setIsDraftEmailChecked(document.data().configuration.isDraftEmailEnabled)
              setIsCalendarAssistantChecked(document.data().configuration.isCalendarAssistantEnabled)
            } else {
              setIsDraftEmailChecked(false)
              setIsCalendarAssistantChecked(false)
            }
            
            // fetch the training data in the background
            const fetchTrainingData = async () => {
              const q = query(collection(db, "training_data"), where("owner", "==", userData.uid));
              const querySnapshot = await getDocs(q);
              var tempTrainingData = []
              querySnapshot.forEach((doc) => {
                  tempTrainingData.push(doc.data())
              });

              tempTrainingData.sort((a, b) => {
                // Assuming 'createdAt' is a date or timestamp field
                return b.createdAt - a.createdAt;
              });

              setTrainingData(tempTrainingData)
            };

            fetchTrainingData();

            // console.log(userData)
            if(!userData.token || !userData.token.emailToken ){
              embedLoomVideo();
            }

          });
        }
      }
      getData()

    }, [code, user]);

    const getLabelColor = (labelName) => {
      // console.log(userObject.labels)
      let labels = userObject.labels
      if(!labels){
        return "#000000"
      }
      const foundLabel = labels.find(label => label.name === labelName);
      if (foundLabel) {
        return foundLabel.color;
      } else {
        // Return a default color if the label with the given name is not found
        return "#000000"; // default to yellow
      }
    }

    const initiateAuth = async (type) => {
      try {
          // Construct the URL with the type parameter
          const requestUrl = `${process.env.REACT_APP_BACKEND_URL}/initiate_auth?type=${encodeURIComponent(type)}`;
  
          const authResponse = await fetch(requestUrl);
          const authData = await authResponse.json();
          const authUrl = authData.authUrl;
  
          // Redirect to the authUrl
          window.location.href = authUrl;
      } catch (error) {
          console.error(`Error initiating ${type} authentication:`, error);
      }
    };
  
    const initiateConnection = (connectionType) => {

      logEvent(analytics, `Email View: Connect ${connectionType} Clicked`);

      if (user && userId) {
          setUserId(userId);
          localStorage.setItem('userId', userId);
          localStorage.setItem('authType', connectionType);
          localStorage.setItem('fromPath', location.pathname);
          initiateAuth(connectionType);
      } else {
          console.error('User is not authenticated');
      }
    };

    const [selectedApp, setSelectedApp] = useState("email");

    const handleAppClick = (id) => {
      logEvent(analytics, 'Email View: Select app ' + id);
      setSelectedApp(id);
    };

    const handleContentChange = (e, key) => {
      const content = e.currentTarget.textContent;
      const docRef = doc(db, "users", userId);
      updateDoc(docRef, {
        [key]: content
      });
      if (key === 'exclusion_filter') {
        setExclusionFilter(content);
      } else if (key === 'draft_style') {
        setDraftStyle(content);
      }
    };

    const updateLabels = (newLabels) => {
      setUserObject((prevUserObject) => ({
        ...prevUserObject,
        labels: newLabels,
      }));
    };
    
    const [currentDate, setCurrentDate] = useState(new Date());
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
  
    const currentMonth = monthNames[currentDate.getMonth()];
    const currentDay = currentDate.getDate();

    const handleLabelClick = (label,index) => {
      setSelectedLabel({label:label,index:index});
      // console.log(label)
      // console.log(emailsSummary)

      const updatedEmailsSummary = emailsSummary.map(email => ({
        ...email,
        show: email.label === label.name ? true : false,
      }));

      // console.log(updatedEmailsSummary)
      setEmailsSummary(updatedEmailsSummary)
    };

    const handleAllLabelClick = () => {
      setSelectedLabel({label:{},index:null});
      const updatedEmailsSummary = emailsSummary.map(email => ({
        ...email,
        show: true,
      }));
      setEmailsSummary(updatedEmailsSummary);
    }

    return (
      <div className="integration-connector-view">
        <NavBar></NavBar>
        
        <div className="flex justify-center mb-12 mt-6 px-6">
          <div className="w-full lg:w-2/3 xl:w-1/2">
          
          <div className="flex justify-left items-center space-x-4 mb-6">

             
             <div 
                  id="email"
                  className={`rounded-2xl bg-white h-16 w-16 p-4 shadow-lg border-2 flex transform cursor-pointer bg-cover bg-center ${
                    selectedApp === 'email' ? 'opacity-100' : 'opacity-50'
                  }`}
                  style={{backgroundImage: `url(${email_hero1})`}}
                  onClick={() => {
                    handleAppClick('email')
                    // navigate('/email')
                  }}>

            </div>

            <div 
                  id="calendar"
                  className={`rounded-2xl bg-white h-16 w-16 p-4 shadow-lg border-2 flex transform cursor-pointer bg-cover bg-center ${
                    selectedApp === 'calendar' ? 'opacity-100' : 'opacity-50'
                  }`}
                  style={{backgroundImage: `url(${calendar_icon})`}}
                  onClick={() => {
                    handleAppClick('calendar')
                    // navigate('/email')
                  }}>
                    <div className='text-white absolute text-sm inset-x-0 top-0 text-center mt-0.5'> {currentMonth}</div>
                    <div className='flex items-center justify-center h-full w-full mt-2.5'>
                      <div className='text-3xl mt-1 force-black' >{currentDay}</div>
                    </div>

            </div>

            {(userObject.tokens && userObject.tokens.emailTokens && userObject.tokens.emailTokens.length != 0) && 
            <>
               <div 
                  id="automation"
                  className={`rounded-2xl bg-white h-16 w-16 p-4 shadow-lg border-2 flex transform cursor-pointer bg-cover bg-center ${
                    selectedApp === 'automation' ? 'opacity-100' : 'opacity-50'
                  }`}
                  style={{backgroundImage: `url(${automation_icon})`}}
                  onClick={() => {
                    handleAppClick('automation')
                    // navigate('/email')
                  }}>
              </div>      
              
              <div 
                  id="smartlabel"
                  className={`rounded-2xl bg-white h-16 w-16 p-4 shadow-lg border-2 flex transform cursor-pointer bg-cover bg-center ${
                    selectedApp === 'smartlabel' ? 'opacity-100' : 'opacity-50'
                  }`}
                  style={{backgroundImage: `url(${label_icon})`}}
                  onClick={() => {
                    handleAppClick('smartlabel')
                    // navigate('/email')
                  }}>
              </div>

              <div 
                  id="emailanalytics"
                  className={`rounded-2xl bg-white h-16 w-16 p-4 shadow-lg border-2 flex transform cursor-pointer bg-cover bg-center ${
                    selectedApp === 'emailanalytics' ? 'opacity-100' : 'opacity-50'
                  }`}
                  style={{backgroundImage: `url(${analytics_icon})`}}
                  onClick={() => {
                    handleAppClick('emailanalytics')
                    // navigate('/email')
                  }}>
              </div>  

              {/* <div 
                  id="todos"
                  className={`rounded-2xl bg-white h-16 w-16 p-4 shadow-lg border-2 flex transform cursor-pointer bg-cover bg-center ${
                    selectedApp === 'todos' ? 'opacity-100' : 'opacity-50'
                  }`}
                  style={{backgroundImage: `url(${todos_icon})`}}
                  onClick={() => {
                    handleAppClick('todos')
                    // navigate('/email')
                  }}>
              </div>   */}
            </>

            }


            <div 
                  id="settings"
                  className={`rounded-2xl bg-white h-16 w-16 p-4 shadow-lg border-2 flex transform cursor-pointer bg-cover bg-center ${
                    selectedApp === 'settings' ? 'opacity-100' : 'opacity-50'
                  }`}
                  style={{backgroundImage: `url(${settings_icon})`}}
                  onClick={() => {
                    handleAppClick('settings')
                  }}>
            </div>
          </div>

          {(userObject.tokens && userObject.tokens.emailTokens && userObject.tokens.emailTokens.length !== 0) &&
              !localStorage.getItem('hasSeenPersonalOnboardView') &&
              <PersonalOnboardView onHide={handlePersonalOnboardHide} /> 
          }

          {(userObject.tokens && userObject.tokens.emailTokens && userObject.tokens.emailTokens.length !== 0) &&
              !localStorage.getItem('hasSeenReferFriendBanner') && localStorage.getItem('hasSeenPersonalOnboardView') &&
              <ReferFriendBanner openReferModal={openReferModal}/>
          }

              {selectedApp === "email" && (userObject.tokens && userObject.tokens.emailTokens && userObject.tokens.emailTokens.length != 0) &&
                <div className="integrations-container">
                {/* Render legacy tokens if it's an array directly */}
                {Array.isArray(userObject.tokens) && userObject.tokens.map((token, index) => (
                  <div key={index} className="integration-tag mr-2">
                    <p className="text-xs force-black">{token.email}</p>
                    <span className="remove-integration" onClick={() => {

                      setTokenToRemove({type:'emailTokens', index:index, email:token.email})
                      setIsConfirmationOpen(true)
                    }}>
                      ✕
                    </span>
                  </div>
                ))}
                {/* Render emailTokens if tokens is an object with emailTokens key */}
                {userObject.tokens && !Array.isArray(userObject.tokens) && Array.isArray(userObject.tokens.emailTokens) && userObject.tokens.emailTokens.map((token, index) => (
                  <div key={index} className="integration-tag mr-2">
                    <p className="text-xs force-black">{token.email}</p>
                    <span className="remove-integration" onClick={() => {
                      setTokenToRemove({type:'emailTokens', index:index, email:token.email})
                      setIsConfirmationOpen(true)
                    }}>✕</span>
                  </div>
                ))}
                <div onClick={() => {
                  setIsConnectEmailOpen(true)
                }} className="add-integration-button">
                  <p className="text-xs text-center force-black">+ Connect Email ...</p>
                </div>
              </div>              
              }


              {userObject && (!userObject.tokens || !userObject.tokens.emailTokens || userObject.tokens.emailTokens.length === 0) &&
                selectedApp === "email" &&
                <div style={{width: "90%", maxWidth:"630px"}} className="items-center text-center justify-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">

                  <div className="prose mt-6 mb-6 rounded-lg overflow-hidden" dangerouslySetInnerHTML={{ __html: videoHTML }} />

                  <button onClick={() => {
                    setIsConnectEmailOpen(true)
                  }} className="connect-btn connect-btn-gradient-border connect-btn-glow">Connect Your Email</button>
                </div>
              }

              {isFirstLoading && 
              <>
              {/* <div className='items-center text-center justify-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"'>
                Loading ...
              </div> */}
                <div className="items-center text-center justify-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <div className="text-center">
                        <div className="animate-bounce inline-block mr-2">⚡️</div>
                        <p className="mt-2">Loading ...</p>
                    </div>
                </div>
              </>
              }

            {selectedApp === "calendar" && 
              <div className="integrations-container">
                {userObject.tokens && !Array.isArray(userObject.tokens) && Array.isArray(userObject.tokens.calendarTokens) && userObject.tokens.calendarTokens.map((token, index) => (
                  <div key={index} className="integration-tag mr-2">
                    <p className="text-xs force-black">{token.email}</p>
                    <span className="remove-integration" onClick={() => {
                        setTokenToRemove({type:'calendarTokens',index:index, email:token.email})
                        setIsConfirmationOpen(true)
                      }}>✕</span>
                  </div>
                ))}
                <div 
                  onClick={() => initiateConnection('google_calendar')}
                  className="add-integration-button">
                    <p className="text-xs text-center force-black">+ Connect Calendar</p>
                </div>
              </div>
            }

            {selectedApp === "automation" && (
              <AutomationView
                userData={{
                  userId: userId,
                  userObject: userObject,
                  user_uid: user.uid,
                  accessToken: user?.accessToken,
                }}
              > 
              </AutomationView>
            )}

            {selectedApp === "smartlabel" && (
              <>
                <div className='bg-white rounded-2xl shadow-lg p-4'>
                  <SmartLabelView 
                    userData={{
                      userId: userId,
                      accessToken: user?.accessToken,
                      userObject: userObject
                    }}
                    onUpdateLabels={updateLabels}
                    isSubscriptionActive={isSubscriptionActive}
                  ></SmartLabelView>    
                </div>
              </>
            )}

            {selectedApp === "emailanalytics" && (
              <>
                <EmailAnalyticsView 
                    userData={{
                      userId: userId,
                      accessToken: user?.accessToken,
                      userObject: userObject
                    }}
                    onUpdateLabels={updateLabels}
                ></EmailAnalyticsView>    
              </>
            )}

            {/* {selectedApp === "todos" && (
              <>
              <TodosView
                userData={{
                  userId: userId,
                  accessToken: user?.accessToken,
                  userObject: userObject
                }}
              >
              </TodosView>
              </>
            )} */}

            {selectedApp === "settings" && 
              <>
                <div className="grid md:grid-cols-2 gap-3">
                    <div className="cursor-pointer bg-white rounded-2xl shadow-lg p-4 duration-300 transform hover:shadow-xl h-full"
                      onClick={() => {
                        // setIsNewBadgeVisible(false);
                        // localStorage.setItem('isNewBadgeVisible', JSON.stringify(false));
                        setIsEmailSignatureOpen(true)
                      }}
                    >
                      <div className="flex items-center">
                        <p className="text-3xl">🖋️</p>
                        <div className="ml-3">
                          <h2 className="text-xl font-semibold force-black">Email Signature</h2>
                          <p className="text-xs force-black">Set up your personalized email signature.</p>
                        </div>

                      </div>

                      {/* {isNewBadgeVisible && <div id="new-badge">NEW</div>} */}

                    </div>

                    <div className="cursor-pointer bg-white rounded-2xl shadow-lg p-4 duration-300 transform hover:shadow-xl h-full"
                      onClick={() => {
                        // setIsNewBadgeVisible(false);
                        // localStorage.setItem('isNewBadgeVisible', JSON.stringify(false));
                        setIsWritingStyleOpen(true)
                      }}
                    >
                      <div className="flex items-center">
                        <p className="text-3xl">📝</p>
                        <div className="ml-3">
                          <h2 className="text-xl font-semibold force-black">Your Writing Style</h2>
                          <p className="text-xs force-black">Mimics your writing style when creating a new draft.</p>
                        </div>

                      </div>

                      {/* {isNewBadgeVisible && <div id="new-badge">NEW</div>} */}

                    </div>

                    <div className="cursor-pointer bg-white rounded-2xl shadow-lg p-4 duration-300 transform hover:shadow-xl flex-grow h-full flex justify-between"
                      onClick={(e) => {
                        // setIsInstructionsOpen(true)
                        setIsPreferredLanguageOpen(true)
                      }}
                    >
                      <div className="flex items-center">
                        <p className="text-3xl">🌍</p>
                        <div className="ml-3">
                          <h2 className="text-xl font-semibold force-black">Preferred Language</h2>
                          <p className="text-xs force-black">Choose the language you want SkimAI to summarize.</p>
                        </div>

                      </div>
                      <div className={`absolute right-4 text-xs`}>
                        {preferredLanguage ?? "English"}
                        {/* {isDraftEmailChecked ? 'Enabled' : 'Disabled'} */}
                      </div>
                    </div>

                    <div className="cursor-pointer bg-white rounded-2xl shadow-lg p-4 duration-300 transform hover:shadow-xl flex-grow h-full flex justify-between"
                      onClick={(e) => {
                        setIsTrainingDataOpen(true)
                      }}
                    >
                      <div className="flex items-center">
                        <p className="text-3xl">🧠</p>
                        <div className="ml-3">
                          <h2 className="text-xl font-semibold force-black">Train SkimAI</h2>
                          <p className="text-xs force-black">Make SkimAI smarter by adding your data.</p>
                        </div>

                      </div>
                      {/* <div className={`absolute right-4 text-xs`}>
                        Coming soon
                      </div> */}
                    </div>

                    <div className="cursor-pointer bg-white rounded-2xl shadow-lg p-4 duration-300 transform hover:shadow-xl flex-grow h-full flex justify-between"
                      onClick={(e) => {
                        // setIsTrainingDataOpen(true)
                        const email = 'support@skim.page';
                        const subject = 'Support Request';
                        const body = 'Hello, I need some assistance.';
                        const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
                        window.location.href = mailtoLink;
                      }}
                    >
                      <div className="flex items-center">
                        <p className="text-3xl">🙋🏻‍♂️</p>
                        <div className="ml-3">
                          <h2 className="text-xl font-semibold force-black">Contact Support</h2>
                          <p className="text-xs force-black">Questions? We're here to help!</p>
                        </div>

                      </div>
                    </div>

                </div>   
              </>
            }

           {selectedApp === "email" && (userObject.tokens && userObject.tokens.emailTokens && userObject.tokens.emailTokens.length != 0) && (
            <>

              <h1 className="text-xl mt-8 text-black">📬 Your Unread Mails ({emailsSummary.length})</h1>

              {/*  show all label here */}
              <div className="flex flex-wrap md:items-center md:justify-between">
                <div
                  className={`mt-4 cursor-pointer text-sm transition-all ${selectedLabel.index !== null ? 'opacity-40' : 'opacity-100 hover:opacity-100'}`}
                  onClick={() => {
                    // setSelectedLabel(null)
                    handleAllLabelClick()
                  }}
                >
                  <span
                    className={`text-xs font-medium px-2 py-1 rounded-md text-white mr-2 mb-2`}
                    style={{ backgroundColor: "#000000" }}
                  >
                    All
                  </span>
                </div>

                {userObject.labels.map((label, index) => (
                  <div
                    key={index}
                    className={`mt-4 cursor-pointer text-sm transition-all ${selectedLabel.index === index ? 'opacity-100' : 'opacity-40 hover:opacity-100'}`}
                    onClick={() => handleLabelClick(label,index)}
                  >
                    <span
                      className={`text-xs font-medium px-2 py-1 rounded-md text-white mr-2 mb-2`}
                      style={{ backgroundColor: label.color }}
                    >
                      {label.name}
                    </span>
                  </div>
                ))}


                <button type="button" 
                  className={`px-2 py-1 text-xs font-medium text-center inline-flex items-center text-gray-900 bg-white border border-gray-300 focus:outline-none ${isSubscriptionActive ? 'hover:bg-gray-100' : ''} focus:ring-4 focus:ring-gray-100 font-medium rounded-full ml-auto ${isLoading || !isSubscriptionActive ? 'opacity-50' : ''} hidden md:inline-flex mt-4`}
                  disabled={isLoading || !isSubscriptionActive}
                  onClick={() => {
                    if (isSubscriptionActive) {
                      setIsLoading(true);
                      fetchData();
                    }
                  }}
                >
                  <svg className={`w-3 h-3 me-2 ${isLoading ? 'animate-spin' : ''}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.7 7.7A7.1 7.1 0 0 0 5 10.8M18 4v4h-4m-7.7 8.3A7.1 7.1 0 0 0 19 13.2M6 20v-4h4"/>
                  </svg>
                  {isLoading ? "Loading ..." : "Refresh"}
                </button>

              </div>



              {!isLoading && emailsSummary.length === 0 ? (
                isSubscriptionActive ? (
                  <div className="congrats-message">
                    <p className="text-center text-6xl mt-20">🎉</p>
                    <p className="text-center text-xl mt-8">Congrats! You are up to date and have reached Inbox Zero.</p>
                  </div>
                ) : (
                  <div className="subscription-message">
                    <p className="text-center text-6xl mt-20">⏳</p>
                    <p className="text-center text-xl mt-8">Your free trial has ended. Please upgrade to continue using SkimAI.</p>
                  </div>
                )
              ) : (
                <div ref={parent}>
                  {emailsSummary.map((item, index) => (
                    (item.show == true || item.show == undefined) && (
                      <div
                        key={index}
                        style={{ display: (item.show == true || item.show == undefined) ? 'block' : 'none' }}
                        className={`cursor-pointer bg-white rounded-2xl shadow-lg p-4 duration-300 transform hover:shadow-xl my-4 relative group`}
                      >
                        <div className='absolute right-0 text-right pr-2 -mt-2 text-sm flex opacity-0 group-hover:opacity-100 transition-all z-10'>
                          <button onClick={() => markAsReadItemClick(item)} type="button" class="px-2 py-2 text-xs font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200"> ✅ Read</button>

                          <button onClick={() => archiveItemClick(item)} type="button" class="px-2 py-2 text-xs font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 ml-2"> 🗂️ Archive</button>

                          <button onClick={() => trashItemClick(item)} type="button" class="px-2 py-2 text-xs font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 ml-2"> 🗑️ Trash</button>

                        </div>

                        {item && item.label && item.label.toLowerCase() != "none" && 
                          <>
                            <div className='absolute right-0 text-right pr-3 -mt-1 text-sm flex opacity-100 group-hover:opacity-0 transition-all z-0'>
                            {/* <span class="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2 py-1 rounded-md">{item.toEmail}</span> */}
                              <span className="text-xs font-medium px-2 py-1 rounded-md text-white" style={{ backgroundColor: getLabelColor(item.label) }}>
                                {item.label}
                                </span>

                            </div>   
                          </>
                   
                        }
                        
                        <div onClick={() => handleItemClick(item)}>
                          <p className="text-sm font-bold text-gray-700 leading-relaxed line-clamp-3">
                            {item.fromDisplayName} - {item.toEmail}
                            {/* <span className='font-light'> {item.toEmail}</span> */}
                          </p>
                          {/* <p className="text-xs text-gray-500 leading-relaxed line-clamp-3 mt-1">
                          {item.toEmail}
                          </p> */}
                          <p className="text-xs text-gray-500 leading-relaxed line-clamp-3 mt-1">
                          {item.date} - {item.fromEmail}
                          </p>
                          <p className="text-gray-700 leading-relaxed line-clamp-3 pt-2">
                            {item.summary}
                          </p>
                        </div>
                      </div>
                    )
                  ))}
                </div>

              )
              }

              {isLoading && (
                <div className="flex justify-center items-center mt-8">
                  Loading ...
                </div>
              )}

            </>
           )}
          </div>
        </div>

        <Footer openVideoModal={openVideoModal} openReferModal={openReferModal}/>

        {isEmailOpen && 
          <SingleEmailView 
          isOpen={isEmailOpen} 
          onClose={() => setIsEmailOpen(false)} 
          onRequireReAuth={handleRequireReAuth}
          onFinishAction={handleFinishAction}
          emailData={selectedEmailData}
          allEmails={emailsSummary}
          userData={{
            userId: userId,
            accessToken: user?.accessToken,
            userObject: userObject,
            userTimeZone: userTimeZone,
          }}
          theTrainingData={trainingData}
          ></SingleEmailView>        
        }

        {isConnectEmailOpen && 
          <ConnectEmailView 
          isOpen={isConnectEmailOpen} 
          onEmailConnectionAction={handleEmailConnectionAction}
          onClose={() => setIsConnectEmailOpen(false)} 
          userData={{
            userId: userId,
            accessToken: user?.accessToken,
          }}
          ></ConnectEmailView>        
        }


        {isTrainingDataOpen && 
          <TrainingDataView 
          isOpen={isTrainingDataOpen} 
          onClose={() => setIsTrainingDataOpen(false)} 
          userData={{
            userId: userId,
            user_uid: user.uid,
            accessToken: user?.accessToken,
          }}
          theTrainingData={trainingData}
          onUpdateTrainingData={(newData) =>{
              setTrainingData(newData)
          }}
          isSubscriptionActive={isSubscriptionActive}
          ></TrainingDataView>        
        }

        {/* !!!!!!!!!!!!! */}
        {/* We should move all of these below here to another file like single email view */}

        <Modal
          onClose={() => setIsPreferredLanguageOpen(false)}
          closeable
          isOpen={isPreferredLanguageOpen}
          animate
          autoFocus
          size={SIZE.default}
          role={ROLE.dialog}
        >
          <ModalBody>
            <div className='h-70'>
              <div className='text-2xl text-black mt-2 font-semibold'>🌍 Preferred Language</div>

              <div className='text-md mt-6'>
                Tell SkimAI which language you prefer to use for email summarization. Please refrain from using non-existing language. 
              </div>

              <div className="mt-2 whitespace-pre-wrap text-lg text-black bg-slate-100 p-4 rounded-lg"
                contentEditable="true"
                onBlur={(e) => {
                  const newLanguage = e.currentTarget.textContent.trim();
                  if (newLanguage !== initialPreferredLanguage) {
                    const docRef = doc(db, "users", userId);
                    updateDoc(docRef, {
                      language: newLanguage
                    });
                    setPreferredLanguage(newLanguage);
                    setLanguageChanged(true);
                  }
                }}>
                  {preferredLanguage}
              </div>

            </div>
          </ModalBody>
          <ModalFooter>
            {/* <ModalButton kind={ButtonKind.tertiary} onClick={() => setIsWritingStyleOpen(false)}>
              Cancel
            </ModalButton> */}
            <ModalButton
              onClick={() => {
                if (languageChanged) {
                  console.log("here")
                  localStorage.removeItem("arrayOfEmailSummary");
                  setLanguageChanged(false);
                }
                setIsPreferredLanguageOpen(false)
              }}
            >
              Done
            </ModalButton>
          </ModalFooter>
        </Modal>
        <Modal
          onClose={() => setIsEmailSignatureOpen(false)}
          closeable
          isOpen={isEmailSignatureOpen}
          animate
          autoFocus
          size={SIZE.default}
          role={ROLE.dialog}
        >
          <ModalBody>
            <div className='h-70'>
              <div className='text-2xl text-black mt-2 font-semibold'>🖋️ Email Signature</div>

              <div className='text-md mt-6'>
                Customize your email signature to enhance your professional identity. Edit and preview your signature directly to ensure it meets your standards.
              </div>

              <div className="mt-2 whitespace-pre-wrap text-lg text-black bg-slate-100 p-4 rounded-lg"
                contentEditable="true"
                onBlur={(e) => {
                  const docRef = doc(db, "users",userId);
                  updateDoc(docRef, {
                    // email_signature: e.currentTarget.textContent
                    email_signature: e.currentTarget.innerHTML
                  });
                  setEmailSignature(e.currentTarget.innerHTML);
                  console.log(e.currentTarget.innerHTML);
                  // setEmailSignature(e.currentTarget.textContent)
                  // console.log(e.currentTarget.textContent)
                  setUserObject({
                    ...userObject,
                    email_signature: e.currentTarget.innerHTML
                    // email_signature: e.currentTarget.textContent
                  });
                }}
                dangerouslySetInnerHTML={{ __html: emailSignature }}
                >
                  {/* {emailSignature} */}
              </div>

            </div>
          </ModalBody>
          <ModalFooter>
            <ModalButton
              onClick={() => {
                setIsEmailSignatureOpen(false)
              }}
            >
              Done
            </ModalButton>
          </ModalFooter>
        </Modal>

        <Modal
          onClose={() => setIsWritingStyleOpen(false)}
          closeable
          isOpen={isWritingStyleOpen}
          animate
          autoFocus
          size={SIZE.default}
          role={ROLE.dialog}
        >
          <ModalBody>
            <div className='h-70'>
              <div className='text-2xl text-black mt-2 font-semibold'>📝 Your Writing Style</div>

              <div className='text-md mt-6'>
                Define your writing style to tailor and enhance SkimAI's responses.
              </div>

              <div className="mt-2 whitespace-pre-wrap text-lg text-black bg-slate-100 p-4 rounded-lg"
                contentEditable="true"
                onBlur={(e) => {
                  const docRef = doc(db, "users",userId);
                  updateDoc(docRef, {
                    tone_of_voice: e.currentTarget.textContent
                  });
                  // toneOfVoice = e.currentTarget.textContent
                  setToneOfVoice(e.currentTarget.textContent)
                  console.log(e.currentTarget.textContent)
                  setUserObject({
                    ...userObject,
                    tone_of_voice: e.currentTarget.textContent
                  });
                }}>
                  {toneOfVoice}
              </div>

            </div>
          </ModalBody>
          <ModalFooter>
            <ModalButton
              onClick={() => {
                setIsWritingStyleOpen(false)
              }}
            >
              Done
            </ModalButton>
          </ModalFooter>
        </Modal>

        {/* pops this up when user first connect their email */}
        {/* TODO: move this to onboard */}

        {isOnBoardOpen && 
          <OnboardView 
            isOpen={isOnBoardOpen} 
            onClose={() => setIsOnBoardOpen(false)} 
            onUpdateAutomaticDraft={ (enabled) => {
              handleCheckboxChange('draftEmail', enabled)
            }}
            onEnableSmartLabel={() => {
              setIsSmartLabelOpen(true)            
            }}
            onEnableAutomation={() => {
              setSelectedApp("automation")
            }}
            onUpdateLabels={updateLabels}
            userData={{
              userId: userId,
              user_uid: user.uid,
              accessToken: user?.accessToken,
              userObject: userObject
            }}
          ></OnboardView>        
        }

        {/* Auto draft settings */}
        <Modal
          onClose={() => {
            setIsInstructionsOpen(false)
          }}
          closeable
          isOpen={isInstructionsOpen}
          animate
          autoFocus
          size={SIZE.default}
          role={ROLE.dialog}
        >
          <ModalBody>
            <div className=''>
              <div className='text-2xl mt-2 text-black font-semibold'>🤖 Automatic Draft</div>
              <div className='mt-2 text-lg'>
                SkimAI automatically scans your inbox, identifies the crucial emails, composes responses, and saves them to your Drafts folder. <b>No email is sent.</b> You can change this anytime in the settings.
                {/* SkimAI can automatically scan your inbox, draft a reply automatically, and save it to your draft folder. <b>No email will be sent.</b>  You can always change this anytime.  */}
              </div>

              {!isDraftEmailChecked && 
              <div className='text-center'>
                <button type="button" class="px-5 py-2.5 text-sm font-medium text-white inline-flex items-center focus:outline-none bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 rounded-lg text-center mt-3"
                onClick={() => {
                  handleCheckboxChange('draftEmail', true)
                }}
                >

                <svg class="w-4 h-4 text-white me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                  <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm13.7-1.3a1 1 0 0 0-1.4-1.4L11 12.6l-1.8-1.8a1 1 0 0 0-1.4 1.4l2.5 2.5c.4.4 1 .4 1.4 0l4-4Z" clip-rule="evenodd"/>
                </svg>

                Enable Automatic Draft
              </button> 
              </div>
              
              }

              {isDraftEmailChecked &&
                <>
                  <div className='mt-5 flex'>
                    <span class="bg-blue-100 text-blue-800 text-2xl font-bold me-2 mr-3 px-2.5 py-0.5 rounded border border-blue-400 w-24 h-11">If ..</span>
                        <div className="whitespace-pre-wrap text-xl text-black bg-slate-100 p-2 rounded-md w-full"
                        contentEditable="true"
                        onBlur={(e) => handleContentChange(e, 'exclusion_filter')}>
                        {exclusionFilter}
                      </div>
                  </div>   
    
                  <div className='mt-4 flex'>
                    <span class="bg-blue-100 text-blue-800 text-2xl font-bold me-2 mr-3 px-2.5 py-0.5 rounded border border-blue-400 w-24 h-11">Then:</span>
                    <div className="whitespace-pre-wrap text-xl text-black bg-slate-100 p-2 rounded-md w-full"
                      contentEditable="true"
                      onBlur={(e) => handleContentChange(e, 'draft_style')}>
                      {draftStyle}
                    </div>
                  </div>
                </>
              }

              {!isDraftEmailChecked && 
              <>
               <img class="h-auto max-w-full bg-white bg-cover mt-4" src={onboard_1} alt="image description"></img>
              </>
              }

              {isDraftEmailChecked && 

                <div className='mt-4 text-rose-600 absolute bottom-5 cursor-pointer' 
                  onClick={() => {
                    handleCheckboxChange('draftEmail', false)
                  }}
                >Disable Automatic Draft</div>     
              }
            </div>
          </ModalBody>
          <ModalFooter>
            <ModalButton
              onClick={() => {
                setIsInstructionsOpen(false);
              }}
            >
              Done
            </ModalButton>
          </ModalFooter>
        </Modal>

        <Modal
          onClose={() => setIsConfirmationOpen(false)}
          closeable
          isOpen={isConfirmationOpen}
          animate
          autoFocus
          size={SIZE.default}
          role={ROLE.dialog}
        >
          <ModalHeader>Remove connection</ModalHeader>
          <ModalBody>
            Are you sure you want to remove the connection to {tokenToRemove.email}? 
          </ModalBody>
          <ModalFooter>
            <ModalButton kind={ButtonKind.tertiary} onClick={() => setIsConfirmationOpen(false)}>
              Cancel
            </ModalButton>
            <ModalButton
              isLoading={isDisconnectLoading}
               onClick={ async () => {
                userObject.tokens[tokenToRemove.type].splice(tokenToRemove.index,1)
                if(tokenToRemove.type === "calendarTokens" && userObject.tokens[tokenToRemove.type].length === 0 && userObject.configuration){
                  userObject.configuration.isCalendarAssistantEnabled = false
                } 
                setUserObject(userObject)
                setIsConfirmationOpen(false)
                // tokenToRemove is {type: "calendarTokens", index: 0}
                // return 
                try {
                  const userDocRef = doc(db, "users", userId);
                  var updateObject = {};
                  updateObject["tokens." + tokenToRemove.type] = userObject.tokens[tokenToRemove.type];
                  if(userObject.configuration){
                    updateObject["configuration"] = userObject.configuration;
                  }
                  // console.log(updateObject)
                  await updateDoc(userDocRef, updateObject);
                  
                } catch (error) {
                  console.error("Error updating user tokens:", error);
                } finally {

                }
               }}
            >
              Remove
            </ModalButton>
          </ModalFooter>
        </Modal>

        {/* TODO: move to another file */}
        {isVideoModalOpen && (
          <TutorialView
            isOpen={isVideoModalOpen} 
            onClose={() => setIsVideoModalOpen(false)} 
          >
          </TutorialView>
        )}
        {isReferModalOpen && (
          <ReferFriendView
            isOpen={isReferModalOpen}
            onClose={() => setIsReferModalOpen(false)}
            userId={userId}
            userEmail={user?.email}
            userName={userObject?.name}
            accessToken={user?.accessToken}
          />
        )}
      </div>
    );
}

export default Email;